<template>
  <div class="tariffs">
    <h1 class="tariffs-h1">Тарифы</h1>
    <div class="tariff-group">
      <TariffCard
        v-for="(tariff, index) in fullTariffs"
        :key="index"
        :title="tariff.title"
        :description="tariff.description"
        :price="tariff.price"
        :installments="tariff.installments"
        :services="tariff.services"
        :isFull="tariff.isFull"
      />
    </div>

    <div class="tariff-group">
      <TariffCardBlue
        v-for="(tariff, index) in liteTariffs"
        :key="index"
        :title="tariff.title"
        :description="tariff.description"
        :price="tariff.price"
        :installments="tariff.installments"
        :services="tariff.services"
        :isFull="tariff.isFull"
      />
    </div>
  </div>
</template>

<script>
import TariffCard from './TariffCard.vue';
import TariffCardBlue from './TariffCardBlue.vue';

export default {
  name: 'TariffAsap',
  components: {
    TariffCard,
    TariffCardBlue,
  },
  data() {
    return {
      fullTariffs: [
        {
          title: "Тариф Full | 5 месяцев",
          description:
            "Подойдёт, если хочешь освоить профессию на практике и поработать в команде над реальным проектом",
          price: "160 000",
          installments: "5 200",
          services: [
            "Онлайн занятия-вебинары от менторов",
            "Домашние задания (ДЗ)",
            "От 10 тренингов на развитие soft skills",
            "3 месяца работы над реальным проектом в команде",
            "Сертификат об обучении и карта компетенций по скиллам",
          ],
          isFull: true,
        },
      ],
      liteTariffs: [
        {
          title: "Тариф Lite | 2 месяца",
          description:
            "Подойдёт, если хочешь освоить профессию на практике, но не хочешь заниматься реальным проектом",
          price: "65 000",
          installments: "3 250",
          services: [
            "Онлайн занятия-вебинары от менторов",
            "Домашние задания (ДЗ)",
            "От 10 тренингов на развитие soft skills",
          ],
          isFull: false,
        },
      ],
    };
  },
};
</script>

<style>
.tariffs-h1{
  margin: 0;
  padding-bottom: 32px;
  font-weight: 700;
  font-size: 80px;
}

.tariffs {
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  gap: 32px;
}

.tariff-group {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}


@media (max-width: 480px) {
  .tariffs{
    padding-top: 80px;
    gap: 16px;
  }

.tariffs-h1{
  font-size: 32px;
  padding-bottom: 16px;
}



}
</style>
