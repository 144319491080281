<template>
  <div>
    <div class="main-content">
      <aside class="sidebar">
        <MailingList />
      </aside>
      <section class="content">
        <CoverBlock />
        <AuthorBlock />
        <BigArticle />
      </section>
    </div>
    <ItsInteresting />
    <ProfessionCard />
    <PageNotFound />
  </div>
</template>

<script>
import CoverBlock from "@/components/ArticleAsap/CoverBlock/CoverBlock.vue";
import AuthorBlock from "@/components/ArticleAsap/AuthorBlock/AuthorBlock.vue";
import BigArticle from "@/components/ArticleAsap/BigArticle/BigArticle.vue";
import ItsInteresting from "@/components/ArticleAsap/ItsInteresting/ItsInteresting.vue";
import ProfessionCard from "@/components/ProfessionCard/ProfessionCard.vue";
import MailingList from "@/components/ArticleAsap/CoverBlock/MailingList.vue";
import PageNotFound from "@/components/PageNoFound/PageNotFound.vue";

export default {
  name: "App",
  components: {
    CoverBlock,
    AuthorBlock,
    BigArticle,
    ItsInteresting,
    ProfessionCard,
    MailingList,
    PageNotFound,
  },
  data() {
    return {
      pageTitle: "Asap Education - Статья",
      pageDescription: "Эта страница предназначена для статьи про IT индустрию, как войти в нее",
      pageKeywords: "Vue.js, SEO, vue-meta, Article Page, Frontend Development",
      ogTitle: "Тайтл для соц. сетей",
      ogDescription: "описание сайта для соц. сетей",
      canonicalUrl: "https://example.com/article-page",
    };
  },
  metaInfo() {
    return {
      title: this.pageTitle, // Динамический заголовок страницы
      meta: [
        {
          name: "description",
          content: this.pageDescription, // Динамическое описание
        },
        {
          name: "keywords",
          content: this.pageKeywords, // Динамические ключевые слова
        },
        {
          property: "og:title",
          content: this.ogTitle, // Динамический OG тайтл
        },
        {
          property: "og:description",
          content: this.ogDescription, // Динамическое описание для OG
        },
        {
          property: "og:type",
          content: "article", // Указание типа контента
        },
      ],
      link: [
        {
          rel: "canonical",
          href: this.canonicalUrl, // Динамический канонический URL
        },
      ],
    };
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  background-color: rgba(240, 244, 249, 1); /* Цвет заливки */
}

#app {
  background-color: rgba(240, 244, 249, 1); /* Цвет заливки для корневого элемента */
  min-height: 100vh; /* Чтобы фон занимал всю высоту экрана */
  font-family: "Montserrat", sans-serif;
}

/* Контейнер страницы */


/* Основной контент */
.main-content {
  display: grid;
  grid-template-columns: 1fr 3fr; /* Сайдбар (1 часть) и контент (3 части) */
  gap: 20px;
  margin-top: 20px;
}

/* Сайдбар */
.sidebar {
  position: relative; /* Родитель для Sticky-блока */
}

.sidebar > * {
  position: sticky;
  top: 20px; /* Расстояние от верхнего края */
}

/* Контент */
.content {
  display: flex;
  flex-direction: column;
  padding-top: 158px;
}
</style>
