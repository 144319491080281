<template>
  <div class="article-card" :class="{ 'vertical-layout': isVertical }">
    <img
      :src="article.image"
      alt="article image"
      class="article-image"
      :style="{
        '--image-width': imageWidth,
        '--image-height': imageHeight
      }"
    />
    <div class="article-content">
      <h3>{{ article.title }}</h3>
      <p class="article-date">{{ article.date }}</p>
      <p class="article-description">{{ article.description }}</p>
      <slot name="extra-content"></slot>
      <div class="btnpos" :style="{ paddingTop: buttonPaddingTop }">
        <button class="btn secondary" :style="{ width: buttonWidth }"><router-link to="/article">Читать далее</router-link></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticleCard",
  props: {
    article: {
      type: Object,
      required: true,
    },
    isVertical: {
      type: Boolean,
      default: false,
    },
    imageWidth: {
      type: String,
      default: "335px", // Значение по умолчанию
    },
    imageHeight: {
      type: String,
      default: "335px", // Значение по умолчанию
    },
    // Пропс для padding-top кнопки
    buttonPaddingTop: {
      type: String,
      default: "16px", // Значение по умолчанию для отступа сверху
    },
    // Пропс для ширины кнопки
    buttonWidth: {
      type: String,
      default: "335px", // Значение по умолчанию для ширины
    },
  },
};
</script>

<style scoped>
.article-card {
  display: flex;
  flex-direction: row;
  gap: 32px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 32px;
  padding: 32px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.vertical-layout {
  flex-direction: column;
  align-items: center;
}

.article-image {
  width: var(--image-width); /* Ширина через CSS переменную */
  height: var(--image-height); /* Высота через CSS переменную */
  border-radius: 16px;
  object-fit: cover;
}

.article-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.article-content h3 {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
  text-transform: uppercase;
}

.article-date {
  font-size: 20px;
  font-weight: 400;
  color: rgba(154, 154, 154, 1);
  margin: 4px 0;
}

.article-description {
  max-height: 100px;
  font-weight: 400;
  line-height: 26px;
  font-size: 20px;
  color: rgba(106, 106, 106, 1);
  margin: 0;
  padding-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.btnpos {
  display: flex;
  justify-content: flex-end;
  padding-top: var(--button-padding-top, 16px); /* Устанавливаем padding-top через CSS переменную */
  margin-top: auto;
}

.btn {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 20px 30px;
  border-radius: 16px; /* Округление углов */
  cursor: pointer;
  transition: all 0.3s ease;
}





@media (max-width: 480px) {

.article-card{
  flex-direction: column;
  border-radius: 24px;
  padding: 12px;
  gap: 12px;
}


.article-content h3 {
font-size: 16px;
line-height: 21px;
}


.article-date {
display: none;
}

.article-description{
font-size: 16px;
line-height: 21px;
padding-top: 8px;
}


.btn{
  font-size: 14px;
  line-height: 32px;
  padding: 12px;
  width: 100% !important;
}

.btnpos{
  padding-top: 12px !important;
  justify-content: center;
}


.article-image{
  width: 100%;
  height: 100%;
}

}
</style>
