import Vue from "vue";
import Router from "vue-router";
import HomeAsap from "@/views/HomeAsap.vue";
import ProfessionsAsap from "@/views/ProfessionsAsap.vue";
import BlogAsap from "@/views/BlogAsap.vue";
import ArticleAsap from "@/views/ArticleAsap.vue";


Vue.use(Router);

export default new Router({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
      {
          path: "/",
          name: "HomeAsap",
          component: HomeAsap
      },
      {
          path: "/professions",
          name: "ProfessionsAsap",
          component: ProfessionsAsap,
      },
      {
          path: "/blog",
          name: "BlogAsap",
          component: BlogAsap
      },
    { path: "/", name: "HomeAsap", component: HomeAsap },
    {
      path: "/professions",
      name: "ProfessionsAsap",
      component: ProfessionsAsap,
    },
    { path: "/blog", name: "BlogAsap", component: BlogAsap },
     {path: "/article", name: "ArticleAsap", component: ArticleAsap},
  ],
});
