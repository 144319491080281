<template>
  <header :class="{ hidden: isHidden, top: isAtTop, 'no-zindex': isFeedbackVisible }" class="headercontainer">
    <div class="logoicon">
      <router-link to="/" @click.native="closeBurgerMenu">
        <img src="@/assets/HeaderPng/black.svg" alt="Logo Icon" />
      </router-link>
    </div>
    
    <nav class="headernav">
      <div class="burger-container">
        <button class="conteiner-burger-menu" @click="toggleBurgerMenu">
          <div class="burger-menu">
            <span class="burger-line"></span>
            <span class="burger-line"></span>
            <span class="burger-line"></span>
          </div>
        </button>
        <div
          class="mobile-nav"
          :class="{ show: isBurgerMenuOpen }"
          @click.self="closeBurgerMenu"
        >
          <ul class="mobil-navlist">
            <div>
              <li
                class="mobil-navitem mobil-navprofessions"
                @click.stop="toggleDropdown"
                :class="{ 'nohover': isDropdownVisible }"
                ref="dropdownContainer"
              >
                Профессия
                <ul class="mobil-dropdown">
                  <li class="mobil-dropdownitem">
                    <router-link to="/profession-1" @click.native="closeBurgerMenu"
                      >Менеджер IT-проектов</router-link
                    >
                  </li>
                  <li class="mobil-dropdownitem">
                    <router-link to="/professions" @click.native="closeBurgerMenu"
                      >UX/UI дизайнер</router-link
                    >
                  </li>
                  <li class="mobil-dropdownitem">
                    <router-link to="/profession-3" @click.native="closeBurgerMenu"
                      >Frontend разработчик</router-link
                    >
                  </li>
                  <li class="mobil-dropdownitem">
                    <router-link to="/profession-4" @click.native="closeBurgerMenu"
                      >Backend разработчик</router-link
                    >
                  </li>
                  <li class="mobil-dropdownitem">
                    <router-link to="/profession-5" @click.native="closeBurgerMenu"
                      >Аналитик</router-link
                    >
                  </li>
                  <li class="mobil-dropdownitem">
                    <router-link to="/profession-6" @click.native="closeBurgerMenu"
                      >Тестировщик</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="mobil-navitem mobil-navblog">
                <router-link to="/blog" @click.native="closeBurgerMenu">Блог</router-link>
              </li>
            </div>
            <button class="btn primary" @click="openFeedback">
              <span>Получить консультацию</span>
            </button>
          </ul>
        </div>
      </div>
      <ul class="navlist">
        <li
          class="navitem navprofessions"
          @click.stop="toggleDropdown"
          :class="{ 'nohover': isDropdownVisible }"
          ref="dropdownContainer"
        >
          Профессия
          <img
            class="righticon"
            src="@/assets/HeaderPng/Right_Icon.svg"
            alt="right icon"
          />
          <ul
            class="dropdown"
            v-show="isDropdownVisible"
            @mouseleave="closeDropdown"
          >
            <li class="dropdownitem">
              <router-link to="/profession-1" @click.native="closeBurgerMenu"
                >Менеджер IT-проектов</router-link
              >
            </li>
            <li class="dropdownitem">
              <router-link to="/professions" @click.native="closeBurgerMenu"
                >UX/UI дизайнер</router-link
              >
            </li>
            <li class="dropdownitem">
              <router-link to="/profession-3" @click.native="closeBurgerMenu"
                >Frontend разработчик</router-link
              >
            </li>
            <li class="dropdownitem">
              <router-link to="/profession-4" @click.native="closeBurgerMenu"
                >Backend разработчик</router-link
              >
            </li>
            <li class="dropdownitem">
              <router-link to="/profession-5" @click.native="closeBurgerMenu"
                >Аналитик</router-link
              >
            </li>
            <li class="dropdownitem">
              <router-link to="/profession-6" @click.native="closeBurgerMenu"
                >Тестировщик</router-link
              >
            </li>
          </ul>
        </li>
        <li class="navitem navblog">
          <router-link to="/blog" @click.native="closeBurgerMenu">Блог</router-link>
        </li>
        <button class="consultationbutton" @click="openFeedback">
          <span>Получить консультацию</span>
        </button>
      </ul>
    </nav>

    <!-- Модальное окно -->
    <FeedbackForm
      v-if="isFeedbackVisible"
      :isVisible="isFeedbackVisible"
      @close="closeFeedback"
    />
  </header>
</template>

<script>
import FeedbackForm from "@/components/PopUp/FeedbackForm.vue";


export default {
  components: {
    FeedbackForm,

  },
  data() {
    return {
      isDropdownVisible: false,
      lastScrollPosition: 0,
      isHidden: false,
      isAtTop: true,
      isBurgerMenuOpen: false,
      isFeedbackVisible: false,
    };
  },
  methods: {
    toggleBurgerMenu() {
      this.isBurgerMenuOpen = !this.isBurgerMenuOpen;

      // Блокируем прокрутку при открытии меню
      if (this.isBurgerMenuOpen) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
    closeBurgerMenu() {
      this.isBurgerMenuOpen = false;
      document.body.classList.remove("no-scroll");
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    closeDropdown() {
      this.isDropdownVisible = false;
    },

    handleScroll() {
      const currentScrollPosition = window.pageYOffset;

      if (currentScrollPosition === 0) {
        this.isAtTop = true;
        this.isHidden = false;
      } else if (currentScrollPosition > this.lastScrollPosition + 10) {
        this.isHidden = true;
        this.isAtTop = false;
      } else if (currentScrollPosition < this.lastScrollPosition - 10) {
        this.isHidden = false;
        this.isAtTop = false;
      }

      this.lastScrollPosition = currentScrollPosition;
    },
    openFeedback() {
      this.isFeedbackVisible = true;
      document.body.style.overflow = "hidden"; // Блокируем прокрутку
    },
    closeFeedback() {
      this.isFeedbackVisible = false;
      document.body.style.overflow = ""; // Восстанавливаем прокрутку
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
$primary-color: #e03a7b;
$black-color: #18080e;

.no-scroll {
  overflow: hidden;
  height: 100%;
}


.mobile-nav{
  display: none;
}

/* Убираем прокрутку, если включено модальное окно */
.no-scroll {
  overflow: hidden;
  height: 100%;
}

/* Убираем z-index у хедера при открытии модального окна */
.headercontainer.no-zindex {
  z-index: 1;
}


.headercontainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 102px;
  max-width: calc(100% - 50px);
  margin: 0 50px; /* Паддинги слева и справа */
  box-sizing: border-box; 
  border-radius: 32px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 3px 3px 20px 2px rgba(188, 35, 190, 0.15);
  position: fixed;
  top: 0;
  left: 0; 
  right: 0;
  z-index: 3;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
  margin-top: 16px;
  box-sizing: border-box;

  &.hidden {
    transform: translateY(-116%);
  }

  &.top {
    position: absolute;
    box-shadow: none;
    box-shadow: 3px 3px 20px 2px rgba(188, 35, 190, 0.15);
    
  }

  .logoicon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 122px;
    height: 63px;
    margin-left: 25px;

    img {
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .burger-container {
  display: none; /* по умолчанию не показываем на больших экранах */
  cursor: pointer;
}

  .headernav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navlist {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    padding: 0px;

    .navlist.burger-active {
    display: block;
  }
    .navprofessions {
      display: flex;
      justify-content: center;
      align-items: center;

      &.nohover {
        pointer-events: auto;

        &:hover {
          color: $black-color;

          .righticon {
            filter: none;
            
          }
        }
      }
    }

    .navitem {
      display: flex;
      position: relative;
      cursor: pointer;
      font-weight: 600;
      font-size: 20px;
      padding: 16px;
      color: $black-color;
      transition: color 0.3s;

      &:hover {
        color: $primary-color;

        .righticon {
          filter: brightness(0) saturate(100%) invert(35%) sepia(60%)
            saturate(2228%) hue-rotate(314deg) brightness(94%) contrast(105%);
        }
      }

      .dropdown {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: -90%;
        top: 130%;
        width: 340px;
        background-color: white;
        box-shadow: 0px 0px 20px 2px rgba(224, 58, 123, 0.08);
        border-radius: 16px;
        z-index: 2;
        list-style-type: none;
        padding: 0;
        overflow: hidden;
      }

      .dropdownitem {
        padding: 20px 32px;
        font-weight: 600;
        font-size: 20px;
        cursor: pointer;
        text-align: left;
        transition: background-color 0.3s, color 0.3s;
        border-bottom: 2px solid rgba(228, 228, 228, 1);
        border-radius: 16px 16px 0px 0px;
        color: rgba(24, 8, 14, 1);
        gap: 8px;

        &:hover {
          background-color: white;
          color: $primary-color;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .consultationbutton {
    width: 326px;
    height: 70px;
    background: none;
    border: 2px solid $primary-color;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.3s;
    margin-right: 16px;

    &:hover {
      color: rgba(202, 24, 93, 1);
      border: 2px solid rgba(202, 24, 93, 1);
    }

    span {
      font-size: 20px;
      line-height: 30px;
      color: $primary-color;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      &:hover {
        color: rgba(202, 24, 93, 1);
      }
    }
  }
}






@media (max-width: 480px) {
.headercontainer{
    height: 72px;
    box-shadow: 0px 0px 0px 0px  !important;
    background-color: rgba(240, 244, 249, 1);
  max-width: 120vw;
  margin: 0 !important;
  border-radius: 0 !important;
 
  padding-right: 20px;
}

.top{
  box-shadow: 0px !important;
  padding-top: 16px;
}

.logoicon{
  width: 90px !important;
  height: 45px !important;
  margin-left: 16px !important;

}



.conteiner-burger-menu{
  display: flex;
    width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  margin-right: -10px;

}




.burger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 23px;
  height: 18px;
  background: transparent;
  align-items: center;
  
  
  
}

.burger-line {
  width: 19px;
  height: 2px;
  background-color: black;
}





  .burger-container {
    display: block !important; /* Показываем бургер */
      margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 10px;
  }

  .navlist {
    display: none!important; /* Скрываем основной список меню */
  }

  .mobile-nav {
    display: none!important; /* По умолчанию скрыто */
    position: fixed;
    top: 72px;
    left: 0px !important;
    width: 100vw;
    background-color: rgba(240, 244, 249, 1);
    z-index: 4;
    padding: 16px;

  }

  .mobile-nav.show {
    display: block !important; /* Показываем меню, если класс active */
  }

  .mobil-navlist {
    list-style-type: none;
    padding: 0;
    background-color: rgba(240, 244, 249, 1);
    width: 100%;
    height: calc(100vh - 56px);
    justify-content: space-between;
    display: flex;
    flex-direction: column;


    .mobil-navitem {
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: bold;
      color: $black-color;
      cursor: pointer;

      &:hover {
        color: $primary-color;
      }
    }

    .mobil-dropdown {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    .mobil-dropdownitem {
      padding: 12px 24px;
      font-size: 16px;
      font-weight: normal;

      &:hover {
        color: $primary-color;
      }
    }

    .btn {
      display: block;
      height: 56px;
      background: none;
      border-radius: 16px;
      cursor: pointer;
      text-align: center;
      margin-top: 0;
      margin-right: 26px;
      margin-bottom: 70px;

      span {
        font-size: 14px;
        font-weight: 600;

        &:hover {
          
        }
      }
    }
  }
}



</style>
