<template>
  <div class="employments">
    <h1 class="employments-h1">Сопровождаем до трудоустройства</h1>
    <div class="employment-group">
      <EmploymentCard
        v-for="(employment, index) in employments"
        :key="index"
        :services="employment.services"
        :image="employment.image"
      />
      <EmploymentCardBlue
      v-for="(employmentstwo, index) in employmentstwos"
        :key="index"
        :services="employmentstwo.services"
        :image="employmentstwo.image"/>
    </div>
  </div>
</template>

<script>
import EmploymentCard from './EmploymentCard.vue';
import EmploymentCardBlue from './EmploymentCardBlue.vue';

export default {
  name: 'EmploymentsAsap',
  components: {
    EmploymentCard,
    EmploymentCardBlue
  },
  data() {
    return {
      employments: [
        {
          services: [
            "Погрузишься в рынок труда IT. Поймешь принципы и алгоритмы найма в компании",
            "Составишь резюме, сопроводительное и протестируешь отклики на него ",
            "Упакуешь и оформишь портфолио с проектом",
            "Пройдешь тестовое собеседование и получишь обратную связь  ",
            "Представишь себя на демо дне перед IT-компаниями   ",
          ],
          image: require('@/assets/EmploymentAsapPng/EmploymentAsap1.png'),
        },
      ],
      employmentstwos: [
        {
          services: [
            "Собеседования от компаний-партнеров",
            "Еженедельные разборы трека трудоустройства ",
            "Гайд по трудоустройству с 100+ каналами с вакансиями  ",
            "Обмен опытом в комьюнити",
          ],
          image: require('@/assets/EmploymentAsapPng/EmploymentAsap2.png'),
        },
      ],
    };
  },
};
</script>

<style>
.employments-h1 {
  margin: 0;
  padding-bottom: 32px;
  font-weight: 700;
  font-size: 80px;
}

.employments {
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  gap: 32px;
}

.employment-group {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}




@media (max-width: 480px) {
  .employments{
    padding-top: 80px;
  }
  .employments-h1{
    font-size: 32px;
    width: 100% !important;
  }

  .employments{
    gap: 0px !important;
  }


  .employment-group {
    gap: 10px !important;
  }
}
</style>
