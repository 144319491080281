<template>
  <div class="slideshow">
    <div class="slidewrapper" :style="slideWrapperStyle">
      <!-- Рендеринг слайдов -->
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="slide"
        :style="{ backgroundImage: `url(${slide.background})` }"
      >
        <div class="content">
          <div v-if="slide.title && slide.description">
            <!-- Details -->
            <div class="details">
              <span
                v-for="(detail, i) in slide.details"
                :key="i"
                class="detail-item"
              >
                {{ detail }}
              </span>
            </div>
            <h2>{{ slide.title }}</h2>
            <p>{{ slide.description }}</p>
          </div>
          <button class="btn primary">
            Участвовать
          </button>
        </div>
      </div>
    </div>

    <!-- Индикаторы -->
    <div class="indicators">
      <span
        v-for="(_, index) in slides.length"
        :key="index"
        @click="goToSlide(index)"
        :class="['indicator', { Active: currentIndex === index }]"
      >
        {{ index + 1 }}
        <!-- Прогресс-бар -->
        <div v-if="currentIndex === index" class="progressbar">
          <div
            class="progressline"
            :style="{ width: `${progress}%` }"
          ></div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slides: [
        {
          background: require('@/assets/SlideBlogsPng/Frame415.png'),
          title: 'Welcome to Slide 1',
          description: 'This is the description for the first slide.',
          details: ['С нуля', '6 мест', '5 месяцев'],
        },
        {
          background: require('@/assets/SlideBlogsPng/image105.png'),
          title: 'Коммуникация в команде: практические рекомендации',
          description: 'Поговорим о том, как строить и поддерживать эффективную коммуникацию в команде. Обсудим, как выбирать инструменты коммуникации, учитывая специфику команд, разберём правила рабочего этикета и лучшие практики эффективных встреч.',
          details: ['16 декабря, 12:00', 'Бесплатно', 'MTS Link'],
        },
        {
          background: require('@/assets/SlideBlogsPng/Frame415.png'),
          title: 'Welcome to Slide 3',
          description: 'фыв',
          details: ['С нуля', '6 мест', '5 месяцев'],
        },
      ],
      currentIndex: 0, 
      progress: 0, 
      slideInterval: null, 
      duration: 3000, 
    };
  },
  methods: {
    nextSlide() {
      if (this.slides.length > 0) {
        this.currentIndex = (this.currentIndex + 1) % this.slides.length;
        this.resetProgress();
      }
    },
    goToSlide(index) {
      if (index >= 0 && index < this.slides.length) {
        this.currentIndex = index;
        this.resetProgress();
      }
    },
    startSlideshow() {
      if (this.slides.length > 0) {
        this.slideInterval = setInterval(() => {
          this.progress += 100 / (this.duration / 30);
          if (this.progress >= 100) {
            this.nextSlide();
          }
        }, 30);
      }
    },
    stopSlideshow() {
      if (this.slideInterval) {
        clearInterval(this.slideInterval);
      }
    },
    resetProgress() {
      this.progress = 0;
    },
  },
  computed: {
    slideWrapperStyle() {
      return {
        transform: `translateX(-${this.currentIndex * 100}%)`,
      };
    },
  },
  mounted() {
    if (this.slides.length > 0) {
      this.startSlideshow();
    }
  },
  beforeDestroy() {
    this.stopSlideshow();
  },
};
</script>

<style scoped>
/* Остальные стили остаются без изменений */
.slideshow {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 200px;
}

.slidewrapper {
  display: flex;
  
  width: 100%;
  height: 700px;
  transition: transform 0.5s ease-in-out;
}

.slide {
  border-radius: 32px;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  
}

.content {
border-radius: 32px;

  gap: 66px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  padding: 64px;

  color: white;
  box-sizing: border-box;
}



.content h2{
  padding: 66px 0;
  font-weight: 700;
  font-size: 80px;
  margin: 0;
}

.content p{
  font-weight: 400;
  font-size: 24px;
  margin: 0;
}

.details {
  
  border-radius: 80px;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.detail-item {
  border-radius: 60px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(106, 106, 106, 1);
  font-size: 18px;
  padding: 8px 24px;
}

.indicators {
  display: flex;
  align-items: center;
  margin-top: 64px;
}

.indicator {
  display: flex;
  align-items: center;
  font-size: 28px;
  line-height: 36.4px;
  cursor: pointer;
  color: rgba(154, 154, 154, 1);
  margin-right: 30px;
  position: relative;
}

.indicator.Active {
  color: rgba(224, 58, 123, 1);
}

.progressbar {
  height: 4px;
  width: 80px;
  background-color: rgba(252, 201, 221, 1);
  margin-left: 10px;
  position: relative;
  border-radius: 4px;
}

.progressline {
  height: 100%;
  background-color: rgba(224, 58, 123, 1);
  border-radius: 4px;
  width: 0;
  transition: width 0.5s ease;
}

.btn {
  
  max-width: 200px;
  padding: 20px 32px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 16px;
  transition: background-color 0.3s;
}
</style>
