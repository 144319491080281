<template>
  <section class="Course-program-section">
    <div class="upper-text">
      <h1 class="course-title">Программа курса</h1>
      <p class="course-description">
        Программа рассчитана на 2 месяца, учебе нужно посвящать<br />
        около 20 часов в неделю.
      </p>
    </div>
    <div class="content-wrapper">
      <!-- Список модулей -->
      <div class="modules">
        <div
          v-for="(module, moduleIndex) in modules"
          :key="moduleIndex"
          class="module"
        >
          <div class="module-container" :class="{ open: openModules[moduleIndex] }">
            <div class="module-header" @click="toggleDropdown(moduleIndex)">
              <p class="module-title">{{ module.title }}</p>
              <img
                class="toggle-icon"
                :src="openModules[moduleIndex] ? require('@/assets/courseimg/minusCirlce/minuscirlce.svg') : require('@/assets/courseimg/plusCirlce/pluscircle.svg')"
                alt="toggle icon"
              />
            </div>
            <ul class="module-content" v-show="openModules[moduleIndex]" :aria-expanded="openModules[moduleIndex]">
              <li
                v-for="(item, index) in module.items"
                :key="index"
                class="module-item"
              >
                <div class="item-left">
                  <span class="item-number">{{ index + 1 }}</span>
                  <div class="item-line"></div>
                </div>
                <div class="item-right">
                  <div class="item-right-title">
                    {{ item.title || item }}
                  </div>
                  <ul v-if="item.subItems" class="sub-items">
                    <li
                      v-for="(subItem, subIndex) in item.subItems"
                      :key="subIndex"
                      class="sub-item"
                    >
                      <img
                        src="@/assets/courseimg/subitemicon/Star.png"
                        alt="icon"
                        class="sub-item-icon"
                      />
                      {{ subItem }}
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Новый блок с формой -->
      <div class="right-block" ref="rightblock">
        <FormSection />
      </div>
    </div>
    <div class="block-container">
      <div class="block1">
        
        <h3 class="block-header">Тренинги</h3>
        <p class="block-p">
          Параллельно с обучением основной профессиии еженедельно будут проводиться трениги по soft-скиллам, на которых ты узнаешь как эффективнее работать с командой, как правильно распределять свое время и многое другое.
        </p>
        <div class="block1-mobil"></div>
      </div>
      <div class="block2">
        <div class="block2-mobil"></div>
        <h3 class="block-header">Проект в портфолио</h3>
        <p class="block-p">
          Вместе с командой ты получишь задачу от заказчика и создашь проект, которым будут пользоваться клиенты
        </p>
      </div>
      <div class="block3">
        <div class="block3-mobil"></div>
        <h3 class="block-header">Демо проекта</h3>
        <p class="block-p">
          Мы позовём рекрутеров из других компаний на защиту проектов, где ты поделишься своими достижениями. Это отличный шанс показать себя и получить оффер
        </p>
      </div>
    </div>
          <div class="right-block-mobil" ref="rightblock">
        <FormSection />
      </div>
  </section>
</template>

<script>

import FormSection from "../advantages/FormSection.vue";

export default {
  name: "CourseProgram",
  components:{

    FormSection
  },
  data() {
    return {
      modules: [
      {
          title: "МОДУЛЬ 1: ДИЗАЙН-ПРОЦЕСС",
          items: [
            "Дизайнер, как модель поведения в реальной жизни",
            "Что такое интерфейс",
            "Чем занимается дизайнер, его скиллсет",
            "Этапы работы дизайнера",
            "Figma: интерфейс, основные инструменты",
            "Подходы в дизайне интерфейсов – data-driven, user-centered",
            "Фреймворки: базовая информация",
            "SCRUM и другие гибкие методологии",
          ],
        },
        {
          title: "МОДУЛЬ 2: ЭЛЕМЕНТЫ ВИЗУАЛЬНОГО ДИЗАЙНА",
          items: [
            "Сетки",
            "Цвет",
            "Типографика",
            "Композиция",
            "Графика и иконографика",
          ],
        },
        {
          title: "МОДУЛЬ 3: ЮЗАБИЛИТИ",
          items: [
            "Понятие и содержание юзабилити",
            "Когнитивные искажения, «Как работает память», гештальт-принципы, Ментальные модели, Аффорданс",
            "Принципы восприятия цвета, «Как мы видим», «Как мы фокусируемся», Иерархия",
            "Эвристики",
            "Аксессибилити",
          ],
        },
        {
          title: "МОДУЛЬ 4: ПРИНЯТИЕ ЗАДАЧ И АНАЛИТИКА",
          items: [
            "Знакомство с бизнес-процессами, брифинг, выявление ЦА",
            "Выявление и анализ конкурентов (в т.ч. бенчмаркинг)",
            "Продуктовые метрики",
            "Гипотезы",
          ],
        },
        {
          title: "МОДУЛЬ 5: ИССЛЕДОВАНИЕ",
          items: [
            "Зачем нужны исследования",
            "Исследования — качественные (глубинное интервью, UX-тест, полевые методы, фокус-группы и др.)",
            "Исследования — количественные (опросы, метрики как набор данных)",
            "Дополнительные типы исследований, которые дизайнер может использовать в работе",
          ],
        },
        {
          title: "МОДУЛЬ 6: АРТЕФАКТЫ И ФРЕЙМВОРКИ",
          items: [
            "User persona",
            "User story и job story",
            "User story mapping, sitemap, user flow, customer journey map, flowmaps",
            "Jobs to be done",
            "Value proposition canvas",
          ],
        },
        {
          title: "МОДУЛЬ 7: РАЗРАБОТКА ДИЗАЙН-КОНЦЕПЦИИ",
          items: [
            "Вайрфрейминг и сценарии",
            "Работа с референсами",
            {
              title: "Создание концепции",
              subItems: [
                "Гайды, брендбуки",
                "Сборка мудборд",
                "Визуальный язык",
                "Текст в интерфейсе и tone of voice",
                "Метафора и визуальная концепция",
              ],
            },
          ],
        },
        {
          title: "МОДУЛЬ 8: РАЗРАБОТКА ЦИФРОВОГО ПРОДУКТА",
          items: [
            {
              title: "Дизайн-система",
              subItems: [
                "Компоненты в Figma — варианты, библиотека",
                "Стили — текст, цвета, эффекты",
                "Атомарный дизайн",
              ],
            },
            {
              title: "Пользовательский интерфейс",
              subItems: [
                "Анатомия макета, UI-элементы",
                "Принципы адаптирования и mobile first",
                "Краевые сценарии и empty states в макетах",
              ],
            },
            {
              title: "Взаимодействие с разработчиками",
              subItems: [
                "Подготовка к передаче в разработку",
                "Фронт-ревью дизайна",
                "Базовые понятия HTML, CSS",
              ],
            },
            {
              title: "Прототипирование и микроанимация",
              subItems: [
                "Создание прототипов в Figma и Protopie",
                "Принципы микроанимации в интерфейс",
              ],
            },
            "Дизайн мобильных приложений. Гайдлайны",
          ],
        },
        {
          title: "МОДУЛЬ 9: КАРЬЕРА ДИЗАЙНЕРА",
          items: [
            {
              title: "Инструменты создания портфолио",
              subItems: [
                "Портфолио",
                "Резюме",
                "Дизайн-комьюнити (кого смотреть, что читать)",
                "Отличия продукта от агентства и стартапа",
              ],
            },
            {
              title: "Создание кейса на Behance",
              subItems: [
                "Принципы создания кейса и сторителлинг",
                "Особенности площадки Behance",
                "Упаковка работы: фотошоп для мокапов, современные плагины",
              ],
            },
          ],
        },
        {
          title: "МОДУЛЬ 10: ДОПОЛНИТЕЛЬНЫЕ ИНСТРУМЕНТЫ В РАБОТЕ ДИЗАЙНЕРА ",
          items: [
            "Спецэффекты",
            "3D",
            "Шоурил-шот на Dribbble",
          ],
        },
      ],
      openModules: [],
      formData: {
        name: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    toggleDropdown(index) {
      this.$set(this.openModules, index, !this.openModules[index]);
    },
  },
  mounted() {
    this.openModules = this.modules.map((_, idx) => idx === 0);
  },
};
</script>

<style lang="scss" scoped>

$grey-color: rgba(106, 106, 106, 1);

.Course-program-section {
  padding-top: 200px;

  .upper-text {
    height: 170px;
    width: 768px;
    color: #18080e;
    padding-bottom: 54px;

    .course-title {
      font-size: 80px;
      font-weight: 600;
      line-height: 88px;
      margin-bottom: 0;
    }

    .course-description {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      margin: 0;
    }
  }

  .content-wrapper {
    display: flex;
    gap: 16px;
    overflow: visible;
    position: relative;
  }

  .modules {
    flex: 1;
    margin-top: 0;
   
    
  }
  .right-block {
    position: sticky;
    top: 0px; 
    z-index: 1; 
    height: 100%;
    padding-bottom: 64px;
    max-width: 40%;
  }

  .right-block-mobil{
    display: none;
  }

  .module {
  color: #18080e;
  padding-bottom: 16px;
  
  &.module:last-child{
    padding-bottom: 64px;
  }

  .module-container {
    background-color: #fff;
    border-radius: 32px; 
    overflow: hidden;
    transition: max-height 0.3s ease; 
    max-height: 140px;
  }

  .module-container.open {
    max-height: none; 
  }

  .module-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    min-height: 104px;
    user-select: none;
    padding: 0 32px;

    .module-title {
      white-space: normal;
      overflow-wrap: break-word; 
      font-size: 28px;
      font-weight: 600;
      line-height: 36px;
      width: auto;
    }

    .toggle-icon {
      width: 40px;
      height: 40px;
    }

    .toggle-icon img {
      object-fit: contain;
    }
  }

  .module-content {
    list-style: none;
    margin: 0;
    padding: 0px 32px;
    overflow: hidden;
    max-height: 0; 
    transition: max-height 0.3s ease;
  }

  .module-container.open .module-content {
    max-height: 1000px;
  }

  .module-item {
  display: flex;
  position: relative;
  padding: 12px 32px;
  max-width: 875px;
  &:last-child{
    padding-bottom: 32px;
  }
  &:first-child{
    padding-top: 0px;
  }

    /* Вертикальная линия */
    &::after {
    content: '';
    position: absolute;
    top: 52px; 
    height: 100%; 
    left: 58px;
    transform: translateX(-50%);
    width: 2px;
    background-color: rgba(224, 58, 123, 1);
    z-index: 0;
  }
  &:last-child::after{
    display: none;
  }

  .item-right {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;

    .item-right-title{
        display: flex;
        align-items: center;
        padding-top: 10px;
        width: 100%;
        max-width: 875px;
        color: $grey-color;
    }
    .sub-items {
      padding-left: 23px; /* Отступ слева для подпунктов */
      margin-top: 8px;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;

      .sub-item {
        color: #666;
        margin-top: 4px;
        display: flex;
        align-items: center;

        .sub-item-icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }
  }

  .item-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 12px;
    position: relative;

  .item-number {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e03a7b;
    color: white;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    font-size: 28px;
    font-weight: 600;
    position: relative;
    z-index: 1;
  }
}
}
}
}
.block-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
  gap: 16px;
}

.block1,
.block2,
.block3 {
  width: 596px;
  height: 352px;
  border-radius: 32px;
  background-color: rgba(255, 255, 255, 1);
}

.block1-mobil{
  display: none;
}
.block2-mobil{
  display: none;
}
.block3-mobil{
  display: none;
}


.block1 {
  background-image: url(@/assets/courseimg/block1img/Vector1.svg);
}
.block2 {
  background-image: url(@/assets/courseimg/block2img/Graphics2.svg);
}
.block3 {
  background-image: url(@/assets/courseimg/block3img/Graphics3.svg);
}

.block-header {
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  color: rgba(24, 8, 14, 1);
  padding-left: 32px;
  margin-bottom: 16px;
  margin-top: 32px;
}

.block-p {
  font-size: 20px;
  line-height: 26px;
  color: $grey-color;
  padding-left: 32px;
}

@media (max-width: 480px) {
  .Course-program-section {
    padding-top: 80px; 
  }

  .upper-text {
    
    width: 100%!important;
    padding-bottom: 20px; 
  }

  .course-title {
    font-size: 32px !important; 
    line-height: 41px; 
    margin-top: 0;
    
  }

  .course-description {
    font-size: 14px !important;
    line-height: 21px!important;
  }

  .content-wrapper {
    flex-direction: column; 
    gap: 10px;
  }

  .modules {
    flex: none;
    margin-top: 0;

  }

 

  .right-block {
    display: none;
  }

  .right-block-mobil{
    display: flex !important;
        position: relative; 
    max-width: 100% !important;
    padding-top: 16px;
    padding-bottom: 0px !important;
    z-index: 0 ; 
  }

  .module {
    padding-bottom: 8px; 

  }

  .module:last-child{
    padding-bottom: 0px !important;
  }

  .module-header {
    font-size: 20px;
    padding: 0 24px!important; 
  }

  .module-title {
    margin: 0;
    padding: 24px 16px 24px 24px;
    font-size: 18px!important; 
    line-height: 23px!important;
    font-weight: 600;
  }

  .toggle-icon {
    width: 32px!important; 
    height: 32px!important;
  }

  .module-content {
    padding: 0 16px;

    width: 100%;
    
  }

  .module-item {
    padding: 8px 16px;
  }

  .module-item::after {
    top: 20px!important; 
    left: 44px!important; 

  }
  

  .item-right {
      font-size: 14px!important; 
      line-height: 21px!important;
  }

  .item-right-title{
    padding-top: 0!important;
  }

  .item-number {
    font-size: 14px!important; 
    width: 24px!important; 
    height: 24px!important;
  }

  .sub-items {
    font-size: 14px; 
  }

  .block-container {
    flex-direction: column; 
    gap: 16px;
    padding-bottom: 0;
    padding-top: 16px;
  }

  .block1,
  .block2,
  .block3 {
    width: 100%; 
    height: auto; 
    padding: 20px; 
    display: inline-block !important;
    min-height: 230px!important;
    bottom: 0!important;
    box-sizing: border-box;
    display: none;
    position: relative;
    
  }



  .block1 {
    background-image: none;
}
.block2 {
    background-image: none;
}
.block3 {
    background-image: none;
}

.block1-mobil{
  background-image: url(@/assets/courseimg/block1img/Vector-mobil1.png);
  background-repeat: no-repeat;
  width: 300px;
  height: 240px;
  display: block;
  position: absolute;
  background-position: bottom;
bottom: 0;
}
.block2-mobil{
  background-image: url(@/assets/courseimg/block2img/Vector-mobil2.png);
  background-repeat: no-repeat;
    width: 350px;
  height: 240px;
  display: block;
  position: absolute;
    background-position: bottom;
bottom: 0;
right: 0;

}
.block3-mobil{
    background-image: url(@/assets/courseimg/block3img/Vector-mobil3.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
    width: 100%;
  height: 240px;
  display: block;
  position: absolute;
  background-position: bottom;
  margin-bottom: -24px;
bottom: 0;
left: 0;
}




  .block-header {
    position: relative;
    font-size: 24px;
    padding-left: 24px; 
    margin: 0;
    z-index: 2;
  }

  .block-p {
    position: relative;
    font-size: 12px; 
    line-height: 16px;
    padding-left: 24px; 
    margin: 0;
    padding-top: 16px;
    z-index: 2;
  }


  .sub-items{


  }

  .sub-item{
    font-size: 12px;
    line-height: 16px;

  }

.sub-item-icon{

}






}


</style>