<template>
  <div class="career">
    <div class="head">
      <h1>Карьера UX/UI дизайнера</h1>
      <p>
        За 5 месяцев регулярной практики, качественного выполнения заданий и
        активности на проекте ты сможешь получить грейд <span>Junior</span> и работу в компании
      </p>
    </div>

    <div class="stats">
      <div class="stat">
        <!-- Передаем число динамически -->
        
        <p class="outlined-text">704</p>
        <p class="stat-p1">Вакансии по данным hh.ru</p>
      </div>
      <div class="stat">
        
        <p class="outlined-text">1 203</p>
        <p class="stat-p2">Вакансии по данным Хабр</p>
      </div>
      <div class="stat">
        
        <p class="outlined-text">10 000</p>
        <p class="stat-p3">Заказов на фрилансе</p>
      </div>
    </div>

    <div class="salary">
      <div class="level1">
        <div class="level1-block"><h3>На старте</h3>
        <p>40 000₽</p></div>
        <div class="level1-bg"></div>
      </div>
      <div class="level2">
        <div class="level2-block"><h3>После 1 года работы</h3>
        <p>110 000₽</p></div>
        <div class="level2-bg"></div>
        
      </div>
      <div class="level3">
        <div class="level3-block"><h3>После 3-х лет работы</h3>
        <p>180 000₽</p></div>
        
        <div class="level3-bg"></div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "CareerAsap",

};
</script>

<style scoped>
.head {
  padding-bottom: 64px;
  padding-top: 200px;
  display: flex;
  justify-content: space-between;
}

.head span {
  color: rgba(224, 58, 123, 1);
}

.head h1 {
  margin: 0;
  width: 749px;
  font-size: 80px;
  font-weight: 700;
  line-height: 88px;
}

.head p {
  font-size: 24px;
  font-weight: 400;
  width: 596px;
  line-height: 36px;
}

.stats {
  display: flex;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 32px;
  padding: 32px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.stat {
  padding: 0 32px;
  display: flex;
  gap: 16px;
  align-items: center;
}

.stat:not(:first-child) {
  border-left: 2px solid rgba(255, 232, 246, 1);
}

.stat p {
  font-weight: 400;
  font-size: 24px;
  color: rgba(24, 8, 14, 1);
}

.outlined-text {
  font-size: 74px !important;
  font-weight: 500 !important;
  position: relative;
  color: rgba(245, 162, 194, 1)!important;
  margin: 0;
  -webkit-text-stroke: 2px rgb(245, 162, 194)!important;
   display: inline-block;
   line-height: 1;
    z-index: 1;
}




.salary {
  padding-top: 34px;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.level1 {
  position: relative;
  width: 443px;
  background: #fff;
  padding-left: 36px;
  border-radius: 32px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.level2 {
  position: relative;
  width: 596px;
  background: #fff;
  padding-left: 36px;
  border-radius: 32px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.level3 {
  position: relative;
  padding-left: 36px;
  display: flex;
  justify-content: space-between;
  width: 749px;
  background: #fff;
  border-radius: 32px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.level1-block {
  z-index: 2;
  gap: 16px;
  padding: 56px 0;
  flex-direction: column;
}

.level2-block {
   z-index: 2;
  gap: 16px;
  padding: 48px 0;
  flex-direction: column;
}

.level3-block {
   z-index: 2;
  gap: 16px;
  padding: 20px 0;
  flex-direction: column;
}

.level1-bg {
  background-image: url(@/assets/careerPng/1block.png);
  width:100%;
  height: 100%;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  background-position: right;
  background-size: contain;
}

.level2-bg {
  background-image: url(@/assets/careerPng/2block.png);
  width:100%;
  height: 100%;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  background-position: right;
  background-size: contain;
}

.level3-bg {
  position: absolute;
  background-image: url(@/assets/careerPng/3block.png);
  width:100%;
  height: 100%;
  background-repeat: no-repeat;
  right: 0;
  bottom: 0;
  background-position: right;
  background-size: contain;
}

.level1 p {
  padding-top: 18px;
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  color: rgba(224, 58, 123, 1);
}

.level2 p {
  padding-top: 18px;
  font-size: 40px;
  font-weight: 600;
  margin: 0;
  color: rgba(224, 58, 123, 1);
}

.level3 p {
  padding-top: 18px;
  font-size: 80px;
  font-weight: 700;
  margin: 0;
  color: rgba(224, 58, 123, 1);
}

.level3 img {
  width: 229px;
  height: 200px;
}

.salary h3 {
  text-transform: uppercase;
  margin: 0;
  font-size: 28px;
  font-weight: 600;
}





@media (max-width: 480px) {
  .head {
    flex-direction: column;
    padding-top: 0px; /* Уменьшаем отступ сверху */
    padding-bottom: 32px;
    text-align: left; /* Выравниваем текст по центру */
  }

  .head h1 {
    font-size: 32px; /* Уменьшаем размер заголовка */
    width: auto; /* Ширина текста будет автоматической */
    line-height: 42px;
    margin: 0;
  }

  .head p {
    font-size: 14px;
    width: auto;
    line-height: 21px;
    padding: 32px 0;
    margin: 0;
  }

  .stats {
    flex-direction: column; /* Выстраиваем блоки вертикально */
    padding: 0 16px;
    border-radius: 24px;
  }

  .stat {
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
  }

   .stat p {
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    max-width: 110px;
    text-align: left;
   }

  .stat:not(:first-child) {
    border-left: none;
    border-top: 2px solid rgba(255, 213, 238, 1); /* Меняем границу для вертикальной ориентации */
  }

  .outlined-text {
    max-width: 150px !important;
    font-size: 44px!important; /* Уменьшаем размер цифр */
    -webkit-text-stroke: 1px rgb(245, 162, 194)!important;
  }

  .salary {
    flex-direction: column; /* Меняем расположение на колонку */
    gap: 8px;
    padding: 16px 0;
  }

  .level1, .level2, .level3 {
    width: auto;
    border-radius: 24px;
    
  }

  .level1-block, .level2-block, .level3-block {
    padding:  0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    
  }

  .level1 p, .level2 p, .level3 p {
    font-size: 24px;
    padding: 4px 0 0 0;
    font-weight: 600;
    line-height: 31px;
    color: rgba(224, 58, 123, 1);
  }
  .level1, .level2, .level3 {
    padding-left: 24px;
    position: relative;
    min-height: 140px;
  }
  

  .level1-bg, .level2-bg, .level3-bg {
    width: 160px;
    height: 140px;
    right: 0;
    
    background-position: center;
    background-size: cover;
    position: absolute;
  }

  .salary h3 {
    font-size: 16px; /* Уменьшаем размер для заголовков */
    padding:0 0 4px 0;
    max-width: 135px;
  }

  /* Уменьшаем отступы внутри каждого блока */
  .stat-p1, .stat-p2, .stat-p3 {
    width: auto;
  }
}



</style>
