<template>
  <div class="commentsgraduates-container">
    <h1 class="commentsgraduates-text">Отзывы выпускников</h1>
    <carousel
      ref="carousel"
      :per-page="isMobile ? 1 : 2"
      :loop="true"
      :autoplay="false"
      :pagination-enabled="false"
      @pageChange="onSlideChange"
    >
      <slide v-for="(item, index) in slides" :key="index">
        <!-- Одна карточка -->
        <div class="commentsgraduates-slide">
          <div class="upperblock">
            <h2 class="name-graduates">
              {{ item.name }}
              <div class="profile-photo">
                <img :src="item.profile" alt="profile" />

                <!-- Кнопка "Play", если есть videoLink -->
                <div
                  v-if="item.videoLink"
                  class="play-button"
                  @click="openVideo(item.videoLink)"
                >
                  <img src="@/assets/CommentsOfGraduatesSVG/playIcon.svg" alt="Play" />
                </div>
              </div>
            </h2>

            <span class="tags-row">
              <!-- Первый (белый) тег со стрелкой -->
              <span class="tag-first-tag" v-if="item.tags[0]">
                <p>{{ item.tags[0] }}</p>
                <div class="arrow">
                  <img :src="item.arrow" alt="arrow"  class="arrow-mobil1"/>
                  <img src="../../../assets/CommentsOfGraduatesSVG/Arrow-mobil.svg" alt="arrow" class="arrow-mobil"/>
                </div>
              </span>
              <br />

              <!-- Второй (розовый) тег -->
              <span class="tag" v-if="item.tags[1]">
                <p>{{ item.tags[1] }}</p>
              </span>

              <!-- Линия (separator) между вторым и третьим тегом -->
              <span class="separator" v-if="item.tags[2]"></span>

              <!-- Третий (розовый) тег -->
              <span class="tag" v-if="item.tags[2]">
                <p>{{ item.tags[2] }}</p>
              </span>
            </span>
          </div>

          <!-- Текст отзыва -->
          <p class="description-text">
            {{ item.description }}
          </p>
        </div>
      </slide>
    </carousel>

    <!-- Кастомная пагинация -->
    <div class="custom-pagination">
      <span
        v-for="(slide, index) in paginationCount"
        :key="index"
        class="custom-indicator"
        :class="{ active: currentPage === index }"
        @click="goToPage(index)"
      >
        {{ index + 1 }}
        <div class="progress-bar" v-if="currentPage === index">
          <div
            class="progress-line"
            :style="{ width: `${progress}%` }"
          ></div>
        </div>
      </span>
    </div>

    <!-- Модальное окно для видео -->
    <div
      class="video-overlay"
      v-if="showVideo"
      @click.self="closeVideo"
    >
      <div class="video-container">
        <button class="video-close" @click="closeVideo">×</button>
        <!-- iframe с видео -->
        <iframe
          v-if="currentVideoLink"
          width="731"
          height="731"
          :src="currentVideoLink"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "CommentsGraduatesSlider",
  components: { Carousel, Slide },
  data() {
    return {
      slides: [
        {
          name: "Настя Дуля",
          profile: require("@/assets/CommentsOfGraduatesSVG/dula.svg"),
          tags: [
            "Лаборант хим. анализа",
            "Системный аналитик",
            "Центр финансовых технологий"
          ],
          arrow: require("@/assets/CommentsOfGraduatesSVG/Arrow.svg"),
          description:
            "Обучение превзошло ожидание, не думала, что мы будем столько времени уделять ему — это огромный плюс. Также большим плюсом является то, что мы прокачивали не только харды, но и софты. Погрузиться в digital получилось, определённо. Менторы, да и в принципе, все делились своим опытом в работе, рассказывали о каких-то своих ситуациях, из которых можно было что-либо извлечь для себя",
            // Есть видео (пример)
          videoLink: "https://vk.com/video_ext.php?oid=-212185334&id=456239245&hd=2&autoplay=1",
        },
        {
          name: "Александр Петров",
          profile: require("@/assets/CommentsOfGraduatesSVG/dula.svg"),
          tags: ["Лаборант хим. анализа", "Freelance", "Центр финансовых технологий"],
          arrow: require("@/assets/CommentsOfGraduatesSVG/Arrow.svg"),
          description: "Всем рекомендую, очень понравилось! Отличная программа, много практики.",
          // Нет видео
          videoLink: null,
        },
        {
          name: "Мария Кузнецова",
          profile: require("@/assets/CommentsOfGraduatesSVG/dula.svg"),
          tags: ["Лаборант хим. анализа", "ВКонтакте", "Центр финансовых технологий"],
          arrow: require("@/assets/CommentsOfGraduatesSVG/Arrow.svg"),
          description: "Очень крутые преподаватели, дают реальный опыт!",
          // Есть видео (пример)
          videoLink: "https://vk.com/video_ext.php?oid=9999&id=8888&hash=xyz",
        },
        {
          name: "Дмитрий Иванов",
          profile: require("@/assets/CommentsOfGraduatesSVG/dula.svg"),
          tags: ["Лаборант хим. анализа", "Freelance", "Центр финансовых технологий"],
          arrow: require("@/assets/CommentsOfGraduatesSVG/Arrow.svg"),
          description: "Нашёл классных заказчиков, всем советую!",
          // Нет видео
          videoLink: null,
        },
      ],

      // Логика прогресс-бара
      progress: 0,
      intervalId: null,
      currentPage: 0,
      isMobile: false,

      // Для модалки с видео
      showVideo: false,
      currentVideoLink: null,
    };
  },
  computed: {
    paginationCount() {
      return Math.ceil(this.slides.length / (this.isMobile ? 1 : 2));
    },
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    // Автоплей через progress
    startProgress() {
      this.stopProgress();
      this.progress = 0;
      this.intervalId = setInterval(() => {
        this.progress += 100 / 30; // ~3s
        if (this.progress >= 100) {
          this.progress = 0;
          const nextPage = (this.currentPage + 1) % this.paginationCount;
          this.goToPage(nextPage);
        }
      }, 100);
    },
    stopProgress() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    goToPage(index) {
      this.$refs.carousel.goToPage(index);
      this.currentPage = index;
      this.startProgress();
    },
    onSlideChange(newPage) {
      this.currentPage = newPage;
      this.startProgress();
    },

    // Открыть видео (если videoLink есть)
    openVideo(link) {
      this.showVideo = true;
      this.currentVideoLink = link;
      // Остановим автопрокрутку, чтобы не переключался слайд
      this.stopProgress();
    },
    // Закрыть видео
    closeVideo() {
      this.showVideo = false;
      this.currentVideoLink = null;
      // Восстановим прогресс
      this.startProgress();
    },
  },
  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
    this.startProgress();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile);
    this.stopProgress();
  },
};
</script>

<style lang="scss" scoped>

::v-deep .VueCarousel-wrapper{
  padding-top: 75px;
}
.commentsgraduates-container {
  padding-top: 200px;

  .commentsgraduates-text {
    font-size: 80px;
    font-weight: 700;
    line-height: 88px;
    margin: 0;
    padding-bottom: 64px;
  }
}

/* Оформление карточки */
.commentsgraduates-slide {
  position: relative; 
  background-color: #fff;
  border-radius: 32px;
  padding: 32px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  min-height: 550px;

  .upperblock {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    width: 100%;
    background-color: rgba(240, 244, 249, 1);

    .name-graduates {
      display: flex;
      justify-content: space-between;
      font-size: 40px;
      font-weight: 600;
      line-height: 52px;
      margin-left: 32px;
      height: 52px;

      .profile-photo {
        width: 209px;
        height: 209px;
        bottom: 140px;
        left: 32px;
        position: relative;

        img {
          
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        /* Кнопка play поверх фото */
        .play-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.5);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .tags-row {
      display: block;
      width: 100%;
      padding-left: 32px;
      margin-bottom: 16px;

      .tag-first-tag,
      .tag {
        display: inline-block;
        vertical-align: middle;
        height: 47px;
        border-radius: 50px;
        font-size: 18px;
        font-weight: 400;
        line-height: 23.4px;
        margin: 0 0 16px 0;
      }

      .separator {
        display: inline-block;
        width: 12px;
        height: 2px;
        margin-bottom: 15px;
        background-color: rgba(224, 58, 123, 1);
        vertical-align: middle;
      }

      .tag-first-tag {
        background-color: #fff;
        color: rgba(106, 106, 106, 1);
        position: relative;

        p {
          margin: 0 20px;
          line-height: 47px;
        }

        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 240px;
        }
        .arrow-mobil{
          display: none;
        }
      }

      .tag {
        background-color: rgba(224, 58, 123, 1);
        color: #fff;
        p {
          margin: 12px 20px;
        }
      }
    }
  }

  .description-text {
    width: 96%;
    color: rgba(106, 106, 106, 1);
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    margin-top: 24px;
  }
}

/* Пагинация */
.custom-pagination {
  display: flex;
  margin-top: 64px;
  gap: 32px;

  .custom-indicator {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 600;
    line-height: 36.4px;
    color: rgba(154, 154, 154, 1);
    cursor: pointer;

    &.active {
      color: rgba(224, 58, 123, 1);
    }

    .progress-bar {
      width: 80px;
      height: 4px;
      background-color: rgba(252, 201, 221, 1);
      margin-top: 4px;
      border-radius: 4px;
      overflow: hidden;
      margin-left: 10px;

      .progress-line {
        height: 100%;
        background-color: rgba(224, 58, 123, 1);
        transition: width 0.1s linear;
      }
    }
  }
}

/* Модалка под видео */
.video-overlay {
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0,0,0,0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .video-container {
    position: relative;
    background: #00000080;
    border-radius: 32px;

    .video-close {
      position: absolute;
      top: 8px;
      right: 16px;
      font-size: 64px;
      line-height: 64px;
      background: none;
      border: none;
      cursor: pointer;
      color: white;
    }

    iframe {
      display: block;
      border-radius: 8px;
    }
  }
}





@media (max-width: 480px) {

.commentsgraduates-container{
  padding-top: 80px;
}
  
.commentsgraduates-text{
  font-size: 32px !important;
  line-height: 31px !important;
  padding-bottom: 0 !important;
}

.name-graduates{
  font-size: 24px !important;
  line-height: 31px !important;
  margin-left: 0 !important;
  margin: 0 0 !important;
  padding-left: 16px !important;
  padding-top: 16px !important;
  padding-bottom: 24px !important;
}
.tag-first-tag{
  height: 28px !important;
  text-align: center !important;
display: inline-block !important;
margin: 0 !important;
margin-left: 16px !important;
}


.tag-first-tag p{
  font-size: 12px !important;
  line-height: 15px !important;
  margin: 6px 8px !important;
display: inline-block !important;
  
}

.tag{
  height: 28px !important;
  margin-bottom: 0 !important;
  display: inline-block !important;
  margin-left: 16px !important;
}

.tag p{
  font-size: 12px !important;
  line-height: 15px !important;
margin: 6px 8px !important;
}

.separator{
  width: 2px !important;
  height: 10px !important;
  margin: 0 0 0 50px !important;
}

.tags-row{
 display: flex !important;
 flex-direction: column;
 align-items: flex-start;
 padding-left: 0 !important;
}

.profile-photo{
  border-radius: 50% !important;
  bottom: 110px !important;
  width: 150px !important;
  height: 150px !important;
  
}

.description-text{
  font-size: 14px !important;
  line-height: 21px !important;
  margin: 0 !important;
  padding-top: 32px;
}

.arrow-mobil{
  display: block !important;
  width:55px;
  height: 55px;
  margin-left: -73px;
  margin-top: 50px;
}

.arrow-mobil1{
  display: none;
}

.commentsgraduates-slide{
  min-height: 460px !important;
  margin-right: 20px !important;
  padding: 24px !important;
}

.carousel-slide{
  padding: 12px !important;
}

.custom-pagination{
  gap: 8px !important;
  justify-content: start !important;
  margin-top: 32px !important;
}

.custom-indicator{
  font-size: 18px !important;
}

}


@media (max-width: 420px) {

.commentsgraduates-container{
  padding-top: 80px;
}
  
.commentsgraduates-text{
  font-size: 32px !important;
  line-height: 31px !important;
  padding-bottom: 0 !important;
}

.name-graduates{
  font-size: 20px !important;
  line-height: 31px !important;
  margin-left: 0 !important;
  margin: 0 0 !important;
  padding-left: 16px !important;
  padding-top: 16px !important;
  padding-bottom: 24px !important;
}

}



@media (max-width: 380px) {

.commentsgraduates-container{
  padding-top: 80px;
}
  
.commentsgraduates-text{
  font-size: 32px !important;
  line-height: 31px !important;
  padding-bottom: 0 !important;
}

.name-graduates{
  font-size: 17px !important;
  line-height: 21px !important;
  margin-left: 0 !important;
  margin: 0 0 !important;
  padding-left: 16px !important;
  padding-top: 16px !important;
  padding-bottom: 24px !important;
}
.tag-first-tag{
  height: 28px !important;
  text-align: center !important;
display: inline-block !important;
margin: 0 !important;
margin-left: 16px !important;
}


.tag-first-tag p{
  font-size: 12px !important;
  line-height: 15px !important;
  margin: 6px 8px !important;
display: inline-block !important;
  
}

.tag{
  height: 28px !important;
  margin-bottom: 0 !important;
  display: inline-block !important;
  margin-left: 16px !important;
}

.tag p{
  font-size: 12px !important;
  line-height: 15px !important;
margin: 6px 8px !important;
}

.separator{
  width: 2px !important;
  height: 10px !important;
  margin: 0 0 0 50px !important;
}

.tags-row{
 display: flex !important;
 flex-direction: column;
 align-items: flex-start;
 padding-left: 0 !important;
}

.profile-photo{
  border-radius: 50% !important;
  bottom: 110px !important;
  width: 150px !important;
  height: 150px !important;
  
}

.description-text{
  font-size: 14px !important;
  line-height: 21px !important;
  margin: 0 !important;
  padding-top: 32px;
}

.arrow-mobil{
  display: block !important;
  width:55px;
  height: 55px;
  margin-left: -73px;
  margin-top: 50px;
}

.arrow-mobil1{
  display: none;
}

.commentsgraduates-slide{
  min-height: 460px !important;
  margin-right: 20px !important;
  padding: 24px !important;
}

.carousel-slide{
  padding: 12px !important;
}

.custom-pagination{
  gap: 8px !important;
  justify-content: start !important;
  margin-top: 32px !important;
}

.custom-indicator{
  font-size: 18px !important;
}

}



</style>
