<template>
    <div class="author-block__container">
      <div class="name-photo__author">
        <img :src="authorImage" alt="Author photo">
        <p>{{ authorName }}</p>
      </div>
      <div class="main-description">
        <p>{{ authorDescription }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "AuthorBlock",
    data() {
      return {
        authorName: "Кирилл Дзержинский",
        authorDescription:
          "Бизнес-аналитик, специализирующийся на оптимизации бизнес-процессов компании 'Инсайт Консалтинг' на должности старшего аналитика.",
        authorImage: require("@/assets/Articlesvg/AuthorBlock/Image-60.svg"),
      };
    },
  };
  </script>
  
  <style lang="scss">
  .author-block__container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  
    background-color: rgba(255, 255, 255, 1);
    border-radius: 32px;
  
    max-width: 902px;
  
    .name-photo__author {
      display: flex;
      padding: 32px 0 26px 32px;
  
      img {
        object-fit: contain;
      }
      p {
        padding-left: 16px;
        color: rgba(40, 49, 239, 1);
        font-size: 20px;
        line-height: 26px;
      }
    }
    .main-description {
      padding: 0 32px 32px 32px;
  
      p {
        margin: 0;
        font-size: 20px;
        line-height: 26px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
      }
    }
  }
  </style>
  