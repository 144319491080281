<template>
  <div class="teleport" :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AsapTeleport',
  props: {
    to: {
      type: String,
      required: true,
    },
    before: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nodes: [],
      waiting: false,
      observer: null,
      childObserver: null,
      parent: null,
    };
  },
  watch: {
    to: 'teleport',
    before: 'teleport',
    disabled: {
      /**
       * getFragment
       * @param {boolean} value
       */
      handler(value) {
        if (value) {
          this.unTeleport();
          // Ensure all event done.
          this.$nextTick(() => {
            this.teardownObservers();
          });
        } else {
          this.initObservers();
          this.teleport();
        }
      },
    },
  },
  mounted() {
    // Store a reference to the nodes
    this.nodes = Array.from(this.$el.childNodes);

    if (!this.disabled) {
      this.initObservers();
    }

    // Teleport slot content to target
    this.teleport();
  },
  beforeDestroy() {
    // Fix nodes reference
    this.nodes = this.getComponentChildrenNode();

    // Teleport back
    this.unTeleport();

    // Stop observing
    this.teardownObservers();
  },
  computed: {
    classes() {
      return { hidden: !this.disabled };
    },
  },
  methods: {
    initObservers() {
      this.initDocumentObserver();
      this.initChildObserver();
    },
    teleport() {
      if (this.disabled) return;
      this.waiting = false;

      const parent = document.querySelector(this.to);

      if (!parent) {
        this.unTeleport();

        this.waiting = true;

        return;
      }

      this.parent = parent;

      if (this.before) {
        this.parent.prepend(this.getFragment());
      } else {
        this.parent.appendChild(this.getFragment());
      }
    },
    unTeleport() {
      if (this.parent) {
        this.$el.appendChild(this.getFragment());
        this.parent = null;
      }
    },
    // Using a fragment is faster because it'll trigger only a single reflow
    // See https://developer.mozilla.org/en-US/docs/Web/API/DocumentFragment
    /**
     * getFragment
     * @returns {DocumentFragment}
     */
    getFragment() {
      const fragment = document.createDocumentFragment();

      this.nodes.forEach(node => fragment.appendChild(node));

      return fragment;
    },
    /**
     * onMutationsHandler
     * @param {MutationRecord[]} mutations
     */
    onMutationsHandler(mutations) {
      // Makes sure the teleport operation is only done once
      let shouldTeleport = false;

      for (let i = 0; i < mutations.length; i++) {
        const mutation = mutations[i];
        const filteredAddedNodes = Array.from(mutation.addedNodes).filter(
          node => !this.nodes.includes(node),
        );

        if (Array.from(mutation.removedNodes).includes(this.parent)) {
          this.unTeleport();
          this.waiting = !this.disabled;
        } else if (this.waiting && filteredAddedNodes.length > 0) {
          shouldTeleport = true;
        }
      }

      if (shouldTeleport) {
        this.teleport();
      }
    },
    /**
     * onChildMutationHandler
     * @param {MutationRecord[]} mutations
     */
    onChildMutationHandler(mutations) {
      const childChangeRecord = mutations.find(i => i.target === this.$el);

      if (childChangeRecord) {
        // Remove old nodes before update position.
        this.nodes.forEach(node => node.parentNode && node.parentNode.removeChild(node));
        this.nodes = this.getComponentChildrenNode();
        this.teleport();
      }
    },

    initDocumentObserver() {
      if (this.observer) {
        return;
      }
      this.observer = new MutationObserver(mutations => this.onMutationsHandler(mutations));

      this.observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false,
      });
    },
    initChildObserver() {
      if (this.childObserver) {
        return;
      }
      // watch childNodes change
      this.childObserver = new MutationObserver(mutations =>
        this.onChildMutationHandler(mutations),
      );

      this.childObserver.observe(this.$el, {
        childList: true,
        subtree: false,
        attributes: false,
        characterData: false,
      });
    },

    teardownObservers() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
      if (this.childObserver) {
        this.childObserver.disconnect();
        this.childObserver = null;
      }
    },
    getComponentChildrenNode() {
      return this.$vnode.componentOptions.children.map(i => i.elm).filter(i => i);
    },
  },
};
</script>

<style scoped lang="scss">
.hidden {
  display: none;
}
</style>
