<template>
  <div class="articles-list">
    <ArticleCard
      v-for="article in filteredArticles"
      :key="article.id"
      :article="article"
    />
  </div>
</template>

<script>
import ArticleCard from "./ArticleCard.vue";

export default {
  name: "ArticlesList",
  components: { ArticleCard },
  props: {
    articles: {
      type: Array,
      required: true,
    },
    currentProfession: {
      type: String,
      required: true,
    },
  },
  computed: {
    filteredArticles() {
      return this.articles
        .filter((article) => article.profession === this.currentProfession)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 2);
    },
  },
};
</script>

<style scoped>
.articles-list {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
