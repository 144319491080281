<template>
    <div>
      <!-- Иконка в правом нижнем углу -->
      <div
        class="popup-icon"
        @click="togglePopup"
        :class="{ active: isPopupVisible }"
      >
        <img src="@/assets/PopUp's/FeedbackFrom/Tanya.svg" alt="Tanya Icon" />
      </div>
  
      <!-- Всплывающее окно -->
      <div v-if="isPopupVisible" class="popup-overlay" @click.self="togglePopup">
        <div class="popup-content">
          <h4>Привет!<br> Меня зовут Таня,<br> я эдвайзер ASAP Education.</h4>
          <p>Напиши мне в tg или whatsapp — проконсультирую, помогу с выбором!</p>
          <div class="popup-buttons">
            <a href="https://t.me/your_telegram" target="_blank" class="btn telegram">
              Telegram <img src="@/assets/PopUp's/PopUpMain/telegram.svg" alt="Telegram Icon" />
            </a>
            <a href="https://wa.me/your_whatsapp" target="_blank" class="btn whatsapp">
              Whatsapp <img src="@/assets/PopUp's/PopUpMain/whatsapp.svg" alt="Whatsapp Icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isPopupVisible: false, // Отображение окна
      };
    },
    methods: {
      togglePopup() {
        this.isPopupVisible = !this.isPopupVisible;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  /* Стили для иконки */
  .popup-icon {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 105px;
    height: 105px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 3px 7px 20px 2px rgba(188, 35, 190, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 9998;
  
    img {
      width: 102px;
      height: 102px;
    }
  
    &:hover {
      box-shadow: 0 0 0 4px #e03a7b;
    }
  
    &.active {
      box-shadow: 0 0 0 4px #e03a7b;
    }
  }
  
  /* Затенение экрана */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
  }
  
  /* Всплывающее окно */
  .popup-content {
    position: fixed;
    bottom: 170px; /* Расстояние над иконкой */
    right: 50px; /* Совпадает с позицией иконки */
    background: #fff;
    padding: 24px;
    border-radius: 16px;
    width: 90%;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: popup-slide-in 0.4s ease-out;
  
    h4 {
      font-size: 28px;
      font-weight: 600;
      line-height: 36.4px;
      text-align: left;
      color: #e03a7b;
      margin: 0;
      margin-bottom: 8px;
    }
  
    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      color: #6a6a6a;
    }
  
    .popup-buttons {
      display: flex;
      justify-content: space-between;
      gap: 8px;
  
      .btn {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 22px 37px;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        color: #fff;
        text-decoration: none;
        transition: background-color 0.3s ease;
  
        img {
          margin-left: 10px;
          width: 25px;
          height: 25px;
        }
      }
  
      .telegram {
        background-color: #e03a7b;
  
        &:hover {
          background-color: #ca185d;
        }
      }
  
      .whatsapp {
        background-color: #e03a7b;
  
        &:hover {
          background-color: #ca185d;
        }
      }
    }
  }
  
  /* Анимация появления окна */
  @keyframes popup-slide-in {
    from {
      transform: translate(50%, 50%);
      opacity: 0;
    }
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  
  /* Адаптивность */
  @media (max-width: 480px) {
    .popup-content {
      bottom: 140px;
      right: 16px;
      padding: 16px;
  
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
      .popup-buttons {
        flex-direction: column;
        .btn {
          margin-bottom: 8px;
        }
      }
    }
  }
  </style>
  