<template>
 <footer class="footer-container">
    <div class="footer-left">
        <div class="footer-left-logo">
            <img src="@/assets/Footer/black.svg" alt="logo" class="logo-black"/>

        </div>
        <div class="professions">
            <h3>IT профессии</h3>
            <ul>
                <li>Менеджер IT-проектов</li>
                <li>UX/UI дизайнер</li>
                <li>Frontend-разработчик</li>
                <li>Backend-разработчик</li>
                <li>Тестировщик</li>
                <li>Системный аналитик</li>
            </ul>
        </div>
        <div class="navbar">
            <h3>Разделы</h3>
            <ul>
                <li>Блог</li>
                <li>Главная</li>
                <li>Об образовательной организации</li>
            </ul>
        </div>
        <div class="company-info">
            <img src="@/assets/Footer/black-mobil.svg" alt="logo" class="logo-black-mobil"/>
            <p>ООО «АСАП Эдьюкейшн»</p>
            <p>ИНН: 7017491350</p>
            <p>ОГРН 1227000002151</p>
            <p>634021, г. Томск</p>
            <p>пр. Комсомольский 12, пом. 15</p>
        </div>
        <div class="footer-left-social">
            <p>
                Образовательные услуги оказываются в соответствии с Федеральным законом от 04.05.2011 № 99-ФЗ «О лицензировании отдельных видов деятельности».
            </p>
        </div>
    </div>
    <div class="footer-right">
        <div class="our-social">
            <h3>Наши соцсети</h3>
            <div class="social-buttons">

                <a class="vkontakte"
                    href="https://vk.com/asapeducation"
                    target="_blank"
                    rel="noopener noreferrer">
                    <p>Вконтакте</p>
                    <img src="@/assets/Footer/Vkontakte.svg" alt="Vkontakte" />
                </a>

                <a class="telegram"
                    href="https://t.me/t_arr_asap"
                    target="_blank"
                    rel="noopener noreferrer">
                    <p>Телеграм</p>
                    <img src="@/assets/Footer/Telegram.svg" alt="Telegram" />
                </a>

                <a class="dzen"
                    href="https://dzen.ru/asap_education"
                    target="_blank"
                    rel="noopener noreferrer">
                    <p>Дзен</p>
                    <img src="@/assets/Footer/Dzen.svg" alt="Dzen" />
                </a>

                <a class="youtube"
                    href="https://www.youtube.com/@ASAP_Education"
                    target="_blank"
                    rel="noopener noreferrer">
                    <p>Youtube</p>
                    <img src="@/assets/Footer/Youtube.svg" alt="Youtube" />
                </a>

            </div>
        </div>
    </div>
 </footer>    
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 200px;

    .footer-left {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 32px;
        width: 75.7%;
        height: 100%;

        .footer-left-logo {
            padding-top: 32px;
            padding-left: 32px;
            height: 40%;
        }
        .logo-black-mobil{
            display: none;
        }
        .professions {
            display: flex;
            align-items: left;
            flex-direction: column;
            width: 21.3%;
            height: 60.1%;
            margin-top: 31px;
            margin-left: 90px;
            padding-bottom: 25px;

            h3 {
                font-size: 28px;
                font-weight: 600;
                line-height: 36.4px;
                color: rgba(40, 49, 239, 1);
                margin-top: 0;
                margin-bottom: 32px;
                cursor: context-menu;
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    color: rgba(106, 106, 106, 1);
                    margin-bottom: 16px;
                    cursor: pointer;
                }
            }
        }
        .navbar {
            display: flex;
            flex-direction: column;
            width: 30.3%;
            padding-top: 31px;
            padding-right: 92px;

            h3 {
                margin-top: 0;
                margin-bottom: 32px;
                font-size: 28px;
                font-weight: 600;
                line-height: 36.4px;
                color: rgba(40, 49, 239, 1);
                cursor: context-menu;
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    color: rgba(106, 106, 106, 1);
                    margin-bottom: 16px;
                    cursor: pointer;
                }
            }
        }
        .company-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 30.3%;
            height: auto;
            margin-left: 32px;
            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: rgba(24, 8, 14, 1);
                margin-bottom: 16px;
                padding: 0;
                margin: 0;
            }
        }

        .footer-left-social {
            margin: 0;
            margin-left: 32px;

            p {
                color: rgba(106, 106, 106, 1);
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }
        }
    }
    .footer-right {
        display: flex;
        justify-content: center;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 32px;
        width: 27.2%;
        height: auto;
        margin-left: 16px;

        .our-social {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 64.3%;

            h3 {
                font-size: 32px;
                font-weight: 600;
                line-height: 41.6px;
                text-align: center;
                color: rgba(0, 0, 0, 1);
                margin-bottom: 64px;
                cursor: context-menu;
            }
            .social-buttons {
                display: flex;
                flex-direction: column;
                width: 100%;

                p {
                    font-size: 20px;
                    line-height: 30px;
                    color: rgba(240, 244, 249, 1);
                    margin-left: 32px;
                }

                button,
                .vkontakte,
                .telegram,
                .youtube,
                .dzen {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: rgba(40, 49, 239, 1);
                    border-color: rgba(40, 49, 239, 1);
                    border-radius: 16px;
                    margin-bottom: 16px;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover{
                        background-color: rgba(10, 19, 213, 1); 
                    }

                    img {
                        margin-right: 32px;
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
}






@media (max-width: 480px) {
 
.footer-container{
    flex-direction: column;
    padding-top: 80px;
}


.footer-right{
order: 1;
width: 100% !important;
border-radius: 24px !important;
margin-bottom: 16px !important;
margin-left: 0 !important;
}

.footer-left {
    order: 2;
    width: 100% !important;
    border-radius: 24px !important;
    flex-direction: column !important;
}

.our-social h3 {
    display: none;
}

.our-social{
    width: 100% !important;
    padding: 24px !important;
}

.social-buttons {
    gap: 16px;
}

.social-buttons p {
    font-size: 14px !important;
    line-height: 32px !important;
}


.vkontakte,.telegram,.youtube,.dzen{
    margin-bottom: 0 !important;
}
.footer-left-logo{
    display: none;
}
.logo-black-mobil{
    display: block !important;
    width: 95px;
    height: 50px;
    padding-bottom: 16px;
}


.professions{
                display: flex;
            align-items: center !important;
            flex-direction: column;
            width: 100% !important;
            height: 100% !important;
            margin-top: 0px !important;
            margin-left: 0px !important;
            padding-bottom: 44px !important;
}

.professions h3{
    margin-bottom: 0 !important;
    font-size: 28px !important;
    line-height: 36px !important;
    padding: 24px 0 ;
}
.professions ul{
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.professions ul li{
    margin-bottom: 0 !important;
        font-size: 12px !important;
    line-height: 16px !important;
}





.navbar{
    width: 100%  !important;
    padding-top: 0px  !important;
    padding-right: 0px  !important;
    align-items: center !important;
                padding-bottom: 44px !important;
}

.navbar h3 {
        margin-bottom: 0 !important;
    font-size: 28px !important;
    line-height: 36px !important;
    padding-bottom: 24px;
}

.navbar ul {
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navbar ul li{
    margin-bottom: 0 !important;
        font-size: 12px !important;
    line-height: 16px !important;
}

.company-info{
            width: 100% !important;
            height: 100% !important;
            margin-left: 0px !important;
                align-items: center !important;
                padding-bottom: 44px !important;
}

.company-info p {
    font-size: 12px !important;
    line-height: 16px !important;
}

.footer-left-social{
            margin: 0;
            margin-left: 0px !important;
    padding-bottom: 44px !important;
}

.footer-left-social p {
        font-size: 12px !important;
    line-height: 16px !important;
    margin: 0 !important;

            text-align: center;
}

}

</style>