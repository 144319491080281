<template>
  <div class="youre-mentor-container">
    <h1 class="youre-mentor-text">Доводим до результата</h1>
    <carousel
      ref="carousel"
      :per-page="1"
      :loop="true"
      :autoplay="false"
      :pagination-enabled="false"
      @pageChange="onSlideChange"
    >
      <!-- Слайды -->
      <slide v-for="(imagePair, index) in images" :key="index">
        <div class="carousel-slide">
          <!-- Левая часть -->
          <div class="slide-left">
            <div class="slide-image-container">
              <img :src="imagePair.left" alt="Первый результат" class="slide-image" />
              <p class="slide-caption slide-caption-left">Первый результат</p>
            </div>
          </div>
          <!-- Правая часть -->
          <div class="slide-right">
            <div class="slide-image-container">
              <img :src="imagePair.right" alt="После комментариев ментора" class="slide-image" />
              <p class="slide-caption slide-caption-right">После комментариев ментора</p>
            </div>
          </div>
        </div>
      </slide>
    </carousel>

    <!-- Кастомный pagination -->
    <div class="custom-pagination">
      <span
        v-for="(imagePair, index) in images"
        :key="index"
        class="custom-indicator"
        :class="{ active: currentPage === index }"
        @click="goToPage(index)"
      >
        {{ index + 1 }}
        <div class="progress-bar" v-if="currentPage === index">
          <div
            class="progress-line"
            :style="{ width: `${progress}%` }"
          ></div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      images: [
        { left: require("@/assets/block-10/sliderMentorsvg(1).svg"), right: require("@/assets/block-10/sliderMentorsvg(2).svg") },
        { left: require("@/assets/block-10/sliderMentorsvg(3).svg"), right: require("@/assets/block-10/sliderMentorsvg(4).svg") },
        { left: require("@/assets/block-10/sliderMentorsvg(5).svg"), right: require("@/assets/block-10/sliderMentorsvg(6).svg") },
        { left: require("@/assets/block-10/sliderMentorsvg(7).svg"), right: require("@/assets/block-10/sliderMentorsvg(8).svg") },
        { left: require("@/assets/block-10/sliderMentorsvg(10).svg"), right: require("@/assets/block-10/sliderMentorsvg(9).svg") },
      ],
      progress: 0, // Прогресс текущего слайда
      intervalId: null, // Таймер прогресса
      currentPage: 0, // Текущий индекс слайда
    };
  },
  methods: {
    startProgress() {
      this.stopProgress(); // Очищаем предыдущий таймер
      this.progress = 0;

      this.intervalId = setInterval(() => {
        this.progress += 100 / 30; // Прогресс заполняется за 3 секунды
        if (this.progress >= 100) {
          this.progress = 0;
          const nextPage = (this.currentPage + 1) % this.images.length; // Следующий слайд
          this.goToPage(nextPage);
        }
      }, 100); // Обновляем каждые 100 мс
    },
    stopProgress() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    goToPage(index) {
      this.$refs.carousel.goToPage(index); // Переход к слайду
      this.currentPage = index; // Обновляем текущий слайд
      this.startProgress(); // Перезапускаем прогресс
    },
    onSlideChange(newPage) {
      this.currentPage = newPage;
      this.startProgress();
    },
  },
  mounted() {
    this.startProgress(); // Запускаем прогресс при загрузке
  },
  beforeDestroy() {
    this.stopProgress(); // Очищаем таймер перед удалением компонента
  },
};
</script>

<style lang="scss" scoped>
.youre-mentor-container {
  padding-top: 200px;

  .youre-mentor-text {
    font-size: 80px;
    font-weight: 700;
    line-height: 88px;
    color: rgba(24, 8, 14, 1);
    margin-bottom: 64px;

  }

  .carousel-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 32px;
    padding: 32px;
    gap: 16px;

    .slide-left,
    .slide-right {
      flex: 1;

      .slide-image-container {
        position: relative;
        width: 100%;
      }

      .slide-image {
        width: 100%;
        object-fit: cover;
        border-radius: 32px;
      }

      .slide-caption {
        position: absolute;
        bottom: -16px;
        left: 0;
        padding: 8px 12px;
        border-radius: 80px;
        font-size: 20px;
        line-height: 26px;
        font-weight: 400;
      }

      .slide-caption-left {
        background-color: rgba(240, 244, 249, 1);
      }

      .slide-caption-right {
        background-color: rgba(40, 49, 239, 1);
        color: rgba(240, 244, 249, 1);
      }
    }
  }

  .custom-pagination {
    display: flex;
    margin-top: 64px;
    gap: 32px;

    .custom-indicator {
      display: flex;
      align-items: center;

      font-size: 28px;
      font-weight: 600;
      line-height: 36.4px;

      color: rgba(154, 154, 154, 1);
      cursor: pointer;

      &.active {
        color: rgba(224, 58, 123, 1);
      }

      .progress-bar {
        width: 80px;
        height: 4px;
        background-color: rgba(252, 201, 221, 1);
        margin-top: 4px;
        border-radius: 4px;
        overflow: hidden;
        margin-left: 10px;

        .progress-line {
          height: 100%;
          background-color: rgba(224, 58, 123, 1);
          transition: width 0.1s linear;
        }
      }
    }
  }

  // Адаптивность для мобильных устройств
  @media (max-width: 768px) {
    .youre-mentor-text {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 32px;
      padding-top: 80px;
    }

    .carousel-slide {
      flex-direction: column;
      gap: 16px;

      .slide-left,
      .slide-right {
        width: 100%;
      }

      .slide-image {
        height: auto;
      }

      .slide-caption {
        position: static;
        text-align: center;
        padding: 6px 8px; 
        font-size: 12px; 
        line-height: 15px; 
      }
    }

    .custom-pagination {
      display: flex;
      justify-content: space-evenly;

      .custom-indicator {
        font-size: 20px;
        line-height: 28px;
        .progress-line{
          width: 80px;
        
          .progress-bar {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {


  }
}


@media (max-width: 480px) {
.youre-mentor-container{
  padding-top: 80px !important;
}

    .youre-mentor-text {
      font-size: 32px !important;
      line-height: 40px;
      margin-bottom: 24px;
      padding-top: 0 !important;
    }

    .carousel-slide {
      padding: 16px;
      border-radius: 24px;

      .slide-caption {
        font-size: 12px; 
        line-height: 18px;
        padding: 2px 6px; 
      }
      
    }

.carousel-slide{
  padding: 12px !important;
}

.custom-pagination{
  gap: 8px !important;
  justify-content: start !important;
  margin-top: 32px !important;
}


.custom-indicator{

  font-size: 18px !important;


}
  .youre-mentor-text{
  font-size: 32px !important;
  line-height: 41px !important;
  margin: 0 !important;
  padding-bottom: 32px !important;
  }
  .slide-image{
    border-radius: 12px !important;
  }
  .slide-caption-left{
    font-size: 12px !important;
    line-height: 16px !important;
  }
    .slide-caption-right{
    font-size: 12px !important;
    line-height: 16px !important;
  }
}
</style>
