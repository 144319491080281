<template>
  <section class="methods">
    <h2 class="methods-title">Работа с ментором</h2>
    <div class="methods-flex">
      <!-- Левая колонка -->
      <div class="methods-left">
        <!-- Персональная обратная связь -->
        <div class="methods-item">
          <div class="methods-text">
            <h3>Персональная обратная связь</h3>
            <p>
              Менторы работают индивидуально с каждым участником, поэтому ты
              будешь получать максимум фидбэка и быстро расти
            </p>
            
          </div>
          <div class="graphics__cont">
            <div class="graphics"></div>
            
        </div>
        <div class="graphics__cont1"><div class="graphics1"></div></div>


        </div>

        <!-- Удобные форматы -->
        <div class="methods-formats">
          <p>Удобные форматы</p>
          <div class="formats-list">
            <div class="format-item">
                <img src="@/assets/methodsPng/Play.png" alt="" />
                 <div class="item-txt">Видеообзоры</div>
                </div>
            <div class="format-item">
                <img src="@/assets/methodsPng/User_Speak.png" alt="" />
                 <div class="item-txt">Созвоны</div>
                </div>
            <div class="format-item">
                <img src="@/assets/methodsPng/Answer.png" alt="" />
                 <div class="item-txt">Ответы</div>
                </div>
            <div class="format-item">
                <img src="@/assets/methodsPng/People.png" alt="" />
                 <div class="item-txt">Обсуждения</div>
                </div>
            <div class="format-item">
                <img src="@/assets/methodsPng/Comment.png" alt="" />
                 <div class="item-txt">Комментарии</div>
                </div>
                
          </div>
          <div class="methods-formats-bg1"></div>
          
          
        </div>
      </div>

      <!-- Правая колонка -->
      <div class="methods-right">
        <div class="methods-item-right">
          <div class="methods-text-right">
            <h3>Отзывчивые менторы</h3>
            <p>
              Менторы дают развёрнутые комментарии, помогают разобраться со
              сложностями и отвечают на твои вопросы
            </p>
            <div class="methods-stats">
              <div class="stat-box">
                <span class="stat-number highlight">4 - 8 человек</span>
                <span class="stat-label">на направление</span>
              </div>
              <div class="stat-box">
                <span class="stat-number highlight">2 - 3 человека</span>
                <span class="stat-label">на ментора</span>
              </div>
            </div>
          </div>
          <div class="methods-image">
            <img src="@/assets/methodsPng/Frame 1 1.png" alt="Чат ментора" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MethodsAsap",
};
</script>

<style scoped>
.methods {

  padding-top: 200px;
  box-sizing: border-box;
}

.methods-title {
  font-size: 80px;
  font-weight: 700;
  text-align: left;
  margin: 0;
  margin-bottom: 64px;
}

.methods-flex {
  display: flex;
  justify-content: space-between; /* Разделяем колонки */
  gap: 20px;
}

.methods-left,
.methods-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.methods-right {
    max-width: 750px;

}

.methods-item-right {
    display: flex;
    flex-direction: column;
height: 100%;
  background-color: white;
  border-radius: 32px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 32px;
  gap: 20px;
box-sizing: border-box;
justify-content: space-between;
}

.methods-item {
  position: relative;
  overflow: hidden;
    max-width: 1055px;
    height: 505px;
  background-color: white;
  border-radius: 32px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  box-sizing: border-box;
}

.methods-formats{
  position: relative;
  overflow: hidden;
    z-index: 3;

    flex-direction: column;
      background-color: white;
  padding: 32px;
  border-radius: 32px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 20px;
}


.methods-formats p{

margin: 0;
      font-size: 40px;
  font-weight: 600;
  text-align: left;
}

.methods-formats-bg1{
    z-index: 1;
    position: absolute;
    background-image: url(@/assets/methodsPng/Pattern.png);
    height: 100%;
    width: 400px;
    background-repeat: no-repeat;
    right: -45px;
    top: 0;
    margin-top: 10px;
}



.methods-text-right p {

    font-size: 400;
  font-size: 24px;
  color: #6c6c6c;
}

.methods-text-right h3 {

  font-size: 40px;
  font-weight: 600;
  margin:0 0 16px 0;
  
}

.methods-text{
    height: 176px;
    position: relative;
    z-index: 2;
    padding: 32px;
}

.methods-text h3 {
  max-width: 100%;
  font-size: 40px;
  font-weight: 600;
  margin:0 0 16px 0;
  
}

.methods-text p {
max-width: 67%;
  font-size: 24px;
  font-weight: 400;
  color: rgba(106, 106, 106, 1);
}

.graphics{
  position: relative;
  z-index: 1;
    background-image: url(@/assets/methodsPng/Graphics.svg);
  width: 100%; /* Растягиваем на всю ширину контейнера */
  height: 100%; /* Растягиваем на всю высоту контейнера */
  background-repeat: no-repeat; /* Убираем повторение фонового изображения */
   background-size: 100% 100%; /* Сохраняет пропорции и заполняет контейнер */
  object-position: center;
 transform: rotate(180deg) translateX(80px) translateY(10px);
  
}

.graphics1{
  position: relative;
  background-image: url(@/assets/methodsPng/Graphics1.png);
  background-repeat: no-repeat;
   z-index: 2;
   width: 100%;
   height: 100%;
   margin-top: 150px;
  transform: translateX(60px);
}


.graphics__cont {
    position: absolute;
    z-index: 1;
    height: 525px;
    width: 610px;
    margin-right: -90px;
right: 0;
    
}


.graphics__cont1{
      position: absolute;
    z-index: 1;
    height: 525px;
    width: 610px;
   margin-right: -90px;

    right: 0;
}

.methods-image{
  width: 100%;
}

.methods-image img {
    padding-top: 50px;
  width: 100%;
  border-radius: 12px;
}

.methods-stats {
  display: flex;
  
  gap: 8px;
  margin-top: 16px;
}

.stat-box {
    display: flex;
    flex-direction: column;
    width: 255px;
  padding: 10px;
  background-color: #f5f7fa;
  border-radius: 20px;
}

.stat-number {

    padding: 10px 24px 0 ;
  font-size: 28px;
  font-weight: 600;
  text-align: left;
}

.stat-label {
    padding: 0 24px 10px;
  font-size: 20px;
  text-align: left;
  color: rgba(24, 8, 14, 1);
}

.stat-number.highlight {
  color: rgba(224, 58, 123, 1);
}

/* Форматы */
.methods-formats {
  margin-top: auto; /* Прижимает блок форматов вниз */
}

.formats-list {
    
    position: relative;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 16px;
}

.item-txt{
    position: relative;
    font-size: 20px;
  font-weight: 400;
}


.format-item {
    z-index: 3;
    position: relative;
    align-items: center;
    display: flex;
    gap: 8px;
  padding: 10px 14px;
  background-color: rgba(240, 244, 249, 1);
  border-radius: 80px;
  font-size: 14px;
  font-weight: 600;
}




@media (max-width: 480px) {
  .methods {
    padding-top: 80px !important;
  }

  .methods-title {
    font-size: 32px; /* Уменьшаем размер заголовка */
    margin-bottom: 32px;
  }

  .methods-flex {
    flex-direction: column; /* Размещаем все элементы в колонку */
    gap: 20px;
  }

  .methods-left,
  .methods-right {
    flex: none; /* Убираем распределение по ширине */
    width: 100%; /* Ширина 100% */
  }

.methods-formats p{
  font-size: 24px;
  
}

.methods-formats{
  gap: 0;
  border-radius: 24px;
}
  .item-txt{
    font-size: 12px;
  }

  .methods-text-right h3 {
    font-size: 24px; /* Уменьшаем размер заголовка */
    margin: 0;
    padding-bottom: 16px;
  }

  .methods-text-right p {
    font-size: 14px;
    margin: 0; /* Уменьшаем размер текста */
  }
  .methods-item-right{
    padding: 24px;
    gap: 24px;
    border-radius: 24px;
  }
  .methods-text h3 {
    font-size: 32px; /* Уменьшаем размер заголовка */
    margin: 0;
    max-width: 100%;
    padding-bottom: 16px;
  }

  .methods-text p {
    font-size: 18px; /* Уменьшаем размер текста */
    margin: 0;
    max-width: 100%;
  }

  .methods-item{
    height: 440px;
    border-radius: 24px;
    box-sizing: border-box;
    position: relative;
  }

  .methods-image img {
    max-width: 100%;
    height: auto; 
    padding-top: 0px; 
  }

   .methods-text{
    position: relative;
   }

  .graphics__cont {
    max-width: 135px;
    height: 100%;
    margin-left: 0;
    left: 0;
     bottom: -165px !important;
     
  }
  .graphics__cont1 {
      position: absolute;
    z-index: 1;
    height: 100%;
    width: 610px;
   margin-right: -290px;
   margin-bottom: 0px;
bottom: 0;
    right: 0;
  }

  .graphics {
     width: 100% !important;
     height: 100% !important;
     transform: rotate(0deg) ;

  }

  .graphics1{
   width: 100%;
    height: 100%;
 transform: rotate(0deg) scale(0.48) ;
 bottom: 0;
 right: 0 !important;
  }

  .methods-stats {
    gap: 8px;
  }

  .stat-box {
    width: 100%; /* Устанавливаем ширину статистических блоков в 100% */
    padding: 16px;
  }

  .stat-number{
    font-size: 16px;
    text-align: center;
    padding: 0 0 8px 0;
    
  }

  .stat-label{
    font-size: 12px;
    text-align: center;
    padding: 0;
  }

  .formats-list {
    display: flex;
    gap: 8px;
  }

  .format-item img{
    width: 24px;
    height: 24px;
  }

  .methods-formats-bg1 {
    display: none; /* Скрываем фоновое изображение */
  }

}

</style>