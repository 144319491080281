<template>
  <div class="tracker-card">
    <div class="tracker-card__imgd">
      <img class="tracker-card__img" :src="tracker.image" :alt="tracker.name">
    </div>
    
    <div class="tracker-info">
      <h3>{{ tracker.name }}</h3>
      <p>{{ tracker.role }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackerCard",
  props: {
    tracker: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>

.tracker-card{
  
}

.tracker-info  {
    gap: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
padding-left: 24px;
padding-right: 14px;
}

.tracker-info h3 {
    margin: 0;
font-size: 28px;
font-weight: 600;
text-transform: uppercase;
}

.tracker-info p {
    margin: 0;
    
  color: rgba(106, 106, 106, 1);
font-size: 20px;
font-weight: 400;
}

.tracker-card__imgd{

  
}

.tracker-card__img{

}


@media (max-width: 480px) {
  /* Настройки для карточки трекера */
  .tracker-card {
    min-width: none;
    padding: 10px;
    flex-direction: column;
    align-items: center; /* Центрируем текст */
    text-align: center;
    border-radius: 24px; /* Меньшие отступы для мобильных */
  }

  /* Изображение */
  .tracker-card__img {
    width: 100%; /* Уменьшаем размер изображения */
    height: 100%; /* Уменьшаем высоту изображения */
    object-fit: cover; /* Заполнение изображения по размеру */
    border-radius: 16px!important; /* Скругляем углы */
  }

  .tracker-card__imgd{
  width: 100% !important; /* Ширина 100% от контейнера */
  max-height: 300px !important; /* Высота блока с изображением */
  border-radius: 16px !important;
  overflow: hidden !important; /* Обрезаем все, что выходит за пределы блока */
  }

  /* Информация о трекере */
  .tracker-info {
    display: flex;
    flex-direction: column; /* Ставим элементы в колонку */
    align-items: left; /* Центрируем текст */
    text-align: left; /* Центрируем текст */
    padding: 0 16px; /* Паддинги для контейнера текста */
    gap: 8px; /* Отступы между элементами текста */
  }

  .tracker-info h3 {
    font-size: 18px; /* Уменьшаем размер заголовка */
    font-weight: 600;
    padding-top: 24px;
    padding-bottom: 8px;
    margin: 0;
  }

  .tracker-info p {
    font-size: 12px; /* Уменьшаем размер текста */
    margin: 0;
  }
}



</style>