<template>
  <section class="mentors-section">
    <h4>Кто работает над обучением</h4>

    <!-- Менторы -->
    <div class="mentors-container">
      <!-- Левый блок - текст -->
      <div class="mentors-text">
        <div class="text-left">
          <h2>Менторы</h2>
        <p>
          Менторы — твои наставники по профессии. Ведут лекции, проверяют домашние задания, погружают в особенности работы. Разбирают с тобой твои ошибки, показывают сильные стороны и сопровождают на всем пути обучения.
        </p>
      </div>
        
        <div class="graph1"></div>
      </div>

      <!-- Правый блок - карточки -->
      <div class="mentor-list">
        <MentorCard
          v-for="mentor in data.mentors"
          :key="mentor.name"
          :mentor="mentor"
          :cardDirection="'row'"
          :textAlign="'left'"
        />
      </div>
    </div>

    <!-- Трекеры -->
    <div class="trackers-container">
      <!-- Левый блок - текст -->
      <div class="trackers-text">
      <div class="text-left">
        <h2>Трекеры</h2>
        <p>
          Трекеры — старшие наставники. Решают проблемы, спрашивают за результат, помогают и поддерживают. Каждую неделю вы будете встречаться на чек-поинтах, чтобы отслеживать твой прогресс обучения и на тренингах, чтобы развивать soft skills
        </p>
      </div>
        
        <div class="graph"></div>
      </div>

      <!-- Правый блок - карточки -->
      <div class="tracker-list">
        <TrackerCard
          v-for="tracker in data.trackers"
          :key="tracker.name"
          :tracker="tracker"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MentorCard from './MentorCard.vue';
import TrackerCard from './TrackerCard.vue';

export default {
  name: 'AcademicMentor',
  components: {
    MentorCard,
    TrackerCard,
  },
  data() {
    return {
      data: {
        mentors: [
          {
            name: "Илья Чирков",
            company: "Альфа-Банк",
            description:
              "Дизайнер <span>цифровых</span> продуктов в Альфа-Банке. Текущая специализация — дизайн интерфейсов для банковских B2B продуктов. Был опыт создания лендингов курсов по аналитике данных. Создаёт 3D-графику и анимацию.",
            image: require("@/assets/AcademicMentorPng/ilya.png"),
          },
          {
            name: "Илья Чирков",
            company: "Альфа-Банк",
            description:
              "Дизайнер цифровых продуктов в Альфа-Банке. Текущая специализация — дизайн интерфейсов для банковских B2B продуктов. Был опыт создания лендингов курсов по аналитике данных. Создаёт 3D-графику и анимацию.",
            image: require("@/assets/AcademicMentorPng/ilya.png"),
          },
          {
            name: "Илья Чирков",
            company: "Альфа-Банк",
            description:
              "Дизайнер цифровых продуктов в Альфа-Банке. Текущая специализация — дизайн интерфейсов для банковских B2B продуктов. Был опыт создания лендингов курсов по аналитике данных. Создаёт 3D-графику и анимацию.",
            image: require("@/assets/AcademicMentorPng/ilya.png"),
          },
          {
            name: "Илья Чирков",
            company: "Альфа-Банк",
            description:
              "Дизайнер цифровых продуктов в Альфа-Банке. Текущая специализация — дизайн интерфейсов для банковских B2B продуктов. Был опыт создания лендингов курсов по аналитике данных. Создаёт 3D-графику и анимацию.",
            image: require("@/assets/AcademicMentorPng/ilya.png"),
          },
        ],
        trackers: [
          {
            name: "Вероника Серовикова",
            role:
              "Со-основатель ASAP, Менеджер IT-проектов, Team lead IT-проектов",
            image: require("@/assets/AcademicMentorPng/veronika.png"),
          },
          {
            name: "Татьяна Юн",
            role:
              "Со-основатель ASAP, Менеджер IT-проектов, Team lead IT-проектов",
            image: require("@/assets/AcademicMentorPng/tatyana.png"),
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.mentors-section {
    
    padding-top: 200px;
}

.mentors-section h4 {
  text-align: left;
  font-weight: 700;
  font-size: 80px;
  padding-bottom: 64px;
  margin: 0;
}


span {
  color: red;
}


/* Общий стиль для контейнеров */
.mentors-container, .trackers-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 60px;
}

/* Левый блок (текст) */
.mentors-text, .trackers-text {
    background-color: rgba(240, 244, 249, 1);
    font-size: 40px;
    font-weight: 600;
max-width: 600px;
padding-right: 150px;
flex: 50%;
}


.graph{
    width: 1020px;
    height: 190px;
    margin-top: -130px;
    padding-top: 250px;
    background-repeat: no-repeat;
    position: absolute;
    background-image: url(@/assets/AcademicMentorPng/Grap.png);
}


.graph1{
     width: 820px;
    height: 190px;
    background-repeat: no-repeat;
    position: absolute;
    margin-top: 75px;
    padding-top: 500px;
    background-image: url(@/assets/AcademicMentorPng/Graph.png);
}

.mentors-text p, .trackers-text p {
    font-size: 24px;
    font-weight: 400;
  


}

/* Правый блок (карточки) */
.mentor-list, .tracker-list {
  padding-top: 65px;
  flex: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  z-index: 2;
}

.mentor-card, .tracker-card {
  min-width: 100%;
    display: flex;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 32px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.text-left{
  background-color: rgba(240, 244, 249, 1);
  position: sticky;
  top: 0;
  
}

.text-left h2{
  font-weight: 600;
  margin: 0;
  font-size: 40px;
  color: rgba(24, 8, 14, 1);
  padding-bottom: 16px;
}

.text-left p{
  font-weight: 400;
  line-height: 36px;
  font-size: 24px;
  color: rgba(24, 8, 14, 1);
  margin: 0;

}


@media (max-width: 480px) {
  /* Скрываем графики */
  .graph, .graph1 {
    display: none !important;
  }
  .mentors-section{
    padding-top: 80px;
  }

  .mentors-section h4 {
    font-size: 32px !important;
  }

  /* Контейнер для текста и карточек */
  .mentors-container, .trackers-container {
    flex-direction: column !important; /* Ставим элементы в колонку */
    align-items: center !important; /* Центрируем содержимое */
    gap: 0px !important;
  }

  .trackers-container{
    margin-bottom: 0;
  }

  /* Устанавливаем карточки друг под другом */
  .mentor-list, .tracker-list {
    display: flex !important; /* Убедимся, что flex включен */
    flex-direction: column !important; /* Располагаем карточки вертикально */
    align-items: center !important; /* Центрируем карточки */
    width: 100% !important; /* Занимаем всю ширину */
    gap: 8px !important;
    padding-top: 32px !important; 
  }

  /* Текстовый блок */  
  .mentors-text, .trackers-text {
    text-align: left;
    padding-right: 0px !important;
  }

  .mentors-text p, .trackers-text p {
    font-size: 14px !important;
    line-height: 21px !important;
    text-align:left;
    width: 100%;
    padding-right: 0px !important;

  }

  .text-left h2 {
    font-size: 24px !important;
    text-align: center !important; /* Центрируем заголовок */
    text-align: left !important;
  }

  /* Модификация карточки ментора */
  .mentor-card, .tracker-card  {
    min-width: 0;
    max-width: 100% !important; /* Обеспечиваем, что карточка не выходит за пределы */
    border-radius: 24px !important;
    display: flex !important;
    flex-direction: column !important; /* Размещаем элементы в колонку */
    align-items: center !important; /* Центрируем элементы карточки */
    margin-bottom: 0px !important; /* Добавляем отступ снизу для карточек */
  }

}



</style>