<template>
    <section class="partners">
      <h1>Партнеры</h1>
      <div class="main-content__partners">
        <h2>Студенты работают c реальными заказчиками и решают их бизнес-задачи</h2>
        <img src="@/assets/PartnersBlocksvg/Ornament(1).svg" class="background-image" alt="Background">
        <img src="@/assets/PartnersBlocksvg/Ornament(1).svg" class="background-image2" alt="Background">
        <div class="partners-massiv">
          <div v-for="partner in partners" :key="partner.id" class="partner-card">
            <img :src="getImagePath(partner.logo)" :alt="partner.name" />
            <span>{{ partner.name }}</span>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        partners: [
          { id: 1, name: "Газпром Трансгаз Томск", logo: "Logo.svg" },
          { id: 2, name: "Quua", logo: "Partner.svg" },
          { id: 3, name: "Eyesplan", logo: "Partner(1).svg" },
          { id: 4, name: "Кроссфит ChiLLi", logo: "Partner(2).svg" },
          { id: 5, name: "Студия танца Red", logo: "Partner(5).svg" },
          { id: 6, name: "Газпром трансгаз Томск", logo: "Logo.svg" },
          { id: 7, name: "Томсон", logo: "Partner(3).svg" },
          { id: 8, name: "Eyesplan", logo: "Partner(1).svg" },
          { id: 9, name: "", logo: "Partner(4).svg" },
          { id: 10, name: "Кроссфит ChiLLi", logo: "Partner(2).svg" },
          { id: 11, name: "Quua", logo: "Partner.svg" },
        ],
      };
    },
    methods: {
      getImagePath(logo) {
        try {
          return require(`@/assets/PartnersBlocksvg/${logo}`);
        } catch (error) {
          console.error(`Ошибка загрузки логотипа: ${logo}`, error);
          return "";
        }
      },
    },
  };
  </script>
  
  <style lang="scss">
  .partners {
    padding-top: 200px;
    h1{
      padding-bottom: 93px;
      margin: 0;
      font-size: 80px;
      font-weight: 700;
      line-height: 88px;
    }
    .main-content__partners {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      overflow: hidden;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 32px;
      padding-bottom: 32px;

      h2{
        font-size: 40px;
        font-weight: 600;
        line-height: 52px;
        margin: 0;
    padding: 32px 270px 64px 270px;
    text-align: center;
        z-index: 1;
      }
      .background-image{
        position: absolute;
        top: 0;
        right: 0;
        object-fit: cover;
      }
      .background-image2{
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        transform: rotate(-180deg);
        transform: scale(-1, 1);
      }

      .partners-massiv {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-size: 28px;
        font-weight: 600;
        line-height: 36.4px;
        gap:16px;
        padding: 0 104px;
        
      }
      .partner-card {
        display: flex;
        align-items: center;
        gap: 16px;
        border: 4px solid rgba(245, 162, 194, 1);
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 32px;
        padding: 16px 32px;
        z-index: 1;
       
      }
    }
  }

@media (max-width: 480px) {

  .partners{
    padding-top: 80px;
  }

.partners h1{
    font-size: 32px;
    line-height: 42px;
    padding-bottom: 32px;
    
}


.main-content__partners h2{
        font-size: 18px !important;
    line-height: 23px !important;
    padding: 24px 24px 16px 24px !important;
}

.partner-card{
    padding: 8px 16px !important;
    gap: 8px !important;
    border: 2px solid rgba(245, 162, 194, 1) !important;
}
.partner-card img{
  width: 32px;
  height: 32px;

}


.partners-massiv{
      font-size: 16px !important;
    line-height: 21px !important;
    gap: 4px !important;
    padding: 0 24px !important;
}

.background-image{
width: 98px !important;
height: 85px !important;
background-repeat: no-repeat;
}
.background-image2{
width: 98px !important;
height: 85px !important;
background-repeat: no-repeat;
}






}
  </style>