<template>
  <div class="articlesOverview">
    <!-- Другие элементы страницы -->
    <ArticlesOverview :articles="articles" />
  </div>
</template>

<script>
import ArticlesOverview from './ArticlesOverview.vue';

export default {
  components: { ArticlesOverview },
  data() {
    return {
      articles: [
        {
          id: 1,
          title: "Чем отличается графический дизайн и UX/UI дизайн",
          date: "02.03.2025",
          description: "Использование компонентов в Figma выведет ваш рабочий процесс на новый уровень. В этой статье я объясню, что такое компоненты, как они работают, и дам ряд рекомендаций по их использованию. Приступим. Компоненты – это элементы интерфейса, которые можно повторно использовать в наших дизайн-проектах. Компонентом может быть кнопка, иконка или что-то сложное, например, таблица данных.",
          image: require('@/assets/ArticlesSectionPng/prof1.png'),
          profession: "Frontend",
        },
        {
          id: 2,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Обмен опытом в комьюнити",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "Frontend",
        },
        {
          id: 3,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Рассказиваем, как сделать прототип интерфейса",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "Frontend",
            },
        {
          id: 4,
          title: "Чем отличается графический дизайн и UX/UI дизайн",
          date: "02.03.2025",
          description: "Описание статьи...",
          image: require('@/assets/ArticlesSectionPng/prof1.png'),
          profession: "Backend",
        },
        {
          id: 5,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Обмен опытом в комьюнити",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "Backend",
        },
        {
          id: 6,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Рассказиваем, как сделать прототип интерфейса",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "Backend",
            },
        {
          id: 7,
          title: "Чем отличается графический дизайн и UX/UI дизайн",
          date: "02.03.2025",
          description: "Описание статьи...",
          image: require('@/assets/ArticlesSectionPng/prof1.png'),
          profession: "designer",
        },
        {
          id: 8,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Обмен опытом в комьюнити",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "designer",
        },
        {
          id: 9,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Рассказиваем, как сделать прототип интерфейса",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "designer",
            },
        {
          id: 10,
          title: "Чем отличается графический дизайн и UX/UI дизайн",
          date: "02.03.2025",
          description: "Описание статьи...",
          image: require('@/assets/ArticlesSectionPng/prof1.png'),
          profession: "designer",
        },
        {
          id: 11,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Обмен опытом в комьюнити",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "designer",
        },
        {
          id: 12,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Рассказиваем, как сделать прототип интерфейса",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "designer",
            },
                {
          id: 13,
          title: "Чем отличается графический дизайн и UX/UI дизайн",
          date: "02.03.2025",
          description: "Использование компонентов в Figma выведет ваш рабочий процесс на новый уровень. В этой статье я объясню, что такое компоненты, как они работают, и дам ряд рекомендаций по их использованию. Приступим. Компоненты – это элементы интерфейса, которые можно повторно использовать в наших дизайн-проектах. Компонентом может быть кнопка, иконка или что-то сложное, например, таблица данных.",
          image: require('@/assets/ArticlesSectionPng/prof1.png'),
          profession: "Frontend",
        },
        {
          id: 14,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Обмен опытом в комьюнити",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "Frontend",
        },
        {
          id: 15,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Рассказиваем, как сделать прототип интерфейса",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "Frontend",
            },
        {
          id: 16,
          title: "Чем отличается графический дизайн и UX/UI дизайн",
          date: "02.03.2025",
          description: "Описание статьи...",
          image: require('@/assets/ArticlesSectionPng/prof1.png'),
          profession: "Frontend",
        },
        {
          id: 17,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Обмен опытом в комьюнити",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "Frontend",
        },
        {
          id: 18,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Рассказиваем, как сделать прототип интерфейса",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "Frontend",
            },
        {
          id: 19,
          title: "Чем отличается графический дизайн и UX/UI дизайн",
          date: "02.03.2025",
          description: "Описание статьи...",
          image: require('@/assets/ArticlesSectionPng/prof1.png'),
          profession: "Frontend",
        },
        {
          id: 20,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Обмен опытом в комьюнити",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "Frontend",
        },
        {
          id: 21,
          title: "Мыслить креативами — талант ли это?",
          date: "02.03.2025",
          description: "Рассказиваем, как сделать прототип интерфейса",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "Frontend",
            },
        // Добавьте больше статей по необходимости
      ],
    };
  },
};
</script>

<style scoped>
.articlesOverview{
    padding-top: 200px;
    
}
</style>
