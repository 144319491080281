<template>
  <div id="app">
    <div class="container">

      <HeaderAsap />
      <router-view />
      <CookieAsap />
      <PopUpMain />
      <FooterAsap/>
  </div>
  <div id="modal"></div>
</div>

</template>

<script>
import HeaderAsap from "./components/ProfessionsAsap/header/HeaderAsap.vue";
import CookieAsap from "./components/ProfessionsAsap/Cookie/CookieAsap.vue";
import PopUpMain from "./components/PopUp/PopUpMain.vue";
import FooterAsap from "./components/ProfessionsAsap/Footer/FooterAsap.vue";



export default {
  components: {
    HeaderAsap,
    CookieAsap,
    PopUpMain,
    FooterAsap,
  },
};
</script>

<style>
/* Глобальные стили */
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: rgba(240, 244, 249, 1); /* Цвет заливки */

}

#app {
  background-color: rgba(240, 244, 249, 1); /* Цвет заливки для корневого элемента */
  min-height: 100vh; /* Чтобы фон занимал всю высоту экрана */
}

/* Глобальный стиль для контейнеров */

.container{
  max-width: 1920px;
  padding-left: calc(50px + max((92vw - 1920px) / 2, 0px)); /* Минимум 50px + адаптивный отступ */
  padding-right: 0;
}





.btn.primary {
  background-color: rgba(224, 58, 123, 1);
  color: #fff;
  border: none;
  transition: background-color 0.3s ease;
  font-family: 'Montserrat', sans-serif;
}
.btn.primary:hover {
  background-color: rgba(202, 24, 93, 1);
}
.btn.primary:active {
  background-color: rgba(140, 8, 60, 1);
}

.btn.secondary {
  background-color: transparent;
  color: rgba(224, 58, 123, 1);
  border: 2px solid rgba(224, 58, 123, 1);
  transition: all 0.3s ease;
  font-family: 'Montserrat', sans-serif;
}
.btn.secondary:hover {
  color: rgba(202, 24, 93, 1);
  border-color: rgba(202, 24, 93, 1);
}
.btn.secondary:active {
  color: rgba(140, 8, 60, 1);
  border-color: rgba(140, 8, 60, 1);
}




.btn-blue {
  background-color: rgba(40, 49, 239, 1);
  color: #fff;
  border: none;
  transition: background-color 0.3s ease;
  font-family: 'Montserrat', sans-serif;
}
.btn-blue:hover {
  background-color: rgba(10, 19, 213, 1);
}
.btn-blue:active {
  background-color: rgba(6, 13, 158, 1);
}

.btn-blue {
  background-color: rgba(40, 49, 239, 1);
  color: #fff;
  border: none;
  transition: background-color 0.3s ease;
  font-family: 'Montserrat', sans-serif;
}
.btn-blue:hover {
  background-color: rgba(10, 19, 213, 1);
}
.btn-blue:active {
  background-color: rgba(6, 13, 158, 1);
}




@media (max-width: 2250px) {
  .container {
    padding-right: calc(50px + max((100vw - 1920px) / 2, 0px)); /* Паддинг появляется при ширине 1920px и меньше */
      padding-left: calc(50px + max((100vw - 1920px) / 2, 0px)); /* Минимум 50px + адаптивный отступ */
  }
}

@media (max-width: 480px) {
  .container {
    padding-left: 16px !important;
    padding-right: 16px !important;
    
  }
}
</style>