<template>
  <div class="article-content">
    <div v-for="(item, index) in articleContent" :key="index">
      <!-- Заголовки -->
      <h2 v-if="item.type === 'heading' && item.level === 2">{{ item.content }}</h2>
      <h3 v-if="item.type === 'heading' && item.level === 3">{{ item.content }}</h3>

      <!-- Параграфы -->
      <p v-if="item.type === 'paragraph'" v-html="item.content"></p>

      <!-- Розовый текст -->
      <h4 v-if="item.type === 'colortext'" v-html="item.content"></h4>

      <!-- Жирный шрифт -->
      <h5 v-if="item.type === 'boldtext'" class="boldtext" v-html="item.content"></h5>

      <!-- Цитаты -->
      <blockquote v-if="item.type === 'blockquote'">{{ item.content }}</blockquote>

      <!-- Стилизованные цитаты -->
      <div v-if="item.type === 'styledQuote'" class="сitation">
        <img class="quote-mark" src="@/assets/Articlesvg/Citationsvg/Quote_mark.svg" alt="quote-mark">
        <p class="citation-text">{{ item.content }}</p>
        <p class="citation-author">{{ item.author }}</p>
        <img class="quote-mark180" src="@/assets/Articlesvg/Citationsvg/Quote_mark180.svg" alt="quote-mark180">
      </div>

      <!-- Информационные блоки -->
      <div v-if="item.type === 'infoBox'" :class="['info-box', item.style]">
        {{ item.content }}
      </div>

      <!-- Маркированные списки -->
      <ul v-if="item.type === 'list' && item.style === 'unordered'">
        <li v-for="(listItem, listIndex) in item.items" :key="'ul-' + listIndex" v-html="listItem"></li>
      </ul>

      <!-- Нумерованные списки -->
      <ol v-if="item.type === 'list' && item.style === 'ordered'">
        <li v-for="(listItem, listIndex) in item.items" :key="'ol-' + listIndex">
          {{ listItem }}
        </li>
      </ol>

      <!-- Изображения -->
      <div v-if="item.type === 'image'" class="image-wrapper">
        <img :src="item.src" :alt="item.alt" :class="{ 'full-width': item.fullWidth }" />
        <div v-if="item.caption" class="image-caption">{{ item.caption }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      articleContent: [
        { type: 'heading', level: 2, content: 'Введение' },
        {
          type: 'paragraph',
          content:
            'Каждый из нас хотя бы раз в жизни сталкивался с ситуацией, когда руководитель или клиент предлагает идею, которая кажется не просто плохой, а откровенно провальной. Что делать в этом случае? Как правильно реагировать, чтобы не испортить отношения с заказчиком?',
        },
        { type: 'heading', level: 2, content: 'Как обсудить идею заказчика, прежде чем делать выводы' },
        {
          type: 'paragraph',
          content:
            'Исполнителю важно не спешить с оценками. Даже если идея кажется слабой, необходимо разобраться, почему заказчик предложил именно её.',
        },
        { type: 'paragraph', content: 'Для этого нужно получить ответы на два вопроса:' },
        {
          type: 'list',
          style: 'ordered',
          items: [
            'Какая цель у этой идеи?',
            'Каких показателей мы хотим достичь благодаря её реализации?',
          ],
        },
        {
          type: 'colortext',
          content:
            'Возможно, заказчик просто некорректно сформулировал свой запроc. Или вы не поняли логику, потому что не обладаете необходимой информацией. Например, вы видите, что реализация идеи увеличит стоимость лидов, но не замечаете, что заодно вырастет и их качество.',
        },
        {
          type: 'paragraph',
          content:
            'Поэтому не нужно бояться уточнить детали, причём даже те, что не относятся к вашей зоне ответственности. Это поможет лучше понять задачу.',
        },
        {
          type: 'image',
          src: require ('@/assets/Articlesvg/BigArticlesvg/Image.svg'),
          alt: 'Пример изображения',
          fullWidth: true,
        },
        { type: 'heading', level: 2, content: 'Как попробовать доказать заказчику, что его идея не очень' },
        {
          type: 'paragraph',
          content:
            'Если даже после тщательного анализа идея не стала выглядеть привлекательнее, попробуйте переубедить заказчика.',
        },
        { type: 'paragraph', content: 'Приведите <u>убедительные аргументы.</u> Вот несколько примеров.' },

        { type: 'heading', level: 3, content: 'Неудачный опыт из прошлого.' },
        {
          type: 'paragraph',
          content:
            'Это подходящее решение, если ранее вы уже делали что-то подобное в этом проекте. Можно также привести примеры похожих решений в других проектах, особенно в смежных областях. Но в таком случае клиент может посчитать пример нерелевантным и не принять ваш аргумент.',
        },
        { type: 'heading', level: 3, content: 'Исследования' },
        {
          type: 'paragraph',
          content:
            'Исследования, которые показывают, что предложенная идея не соответствует трендам или не приносит существенного результата.',
        },
        { type: 'heading', level: 3, content: 'Мнение эксперта.' },
        {
          type: 'paragraph',
          content:
            'Можно показать заказчику статью с актуальными данными или пост авторства авторитетного спикера, где тот разбирает похожую проблему. Либо обратиться за консультацией к более опытному коллеге.',
        },
        { type: 'heading', level: 3, content: 'Примеры конкурентов' },
        {
          type: 'paragraph',
          content:
            'Если конкуренты не сумели успешно реализовать подобную идею, это может заставить заказчика задуматься.',
        },
        {
          type: 'blockquote',
          content:
            'Однако возможно, что клиент решит: «У конкурента не получилось, но у нас-то точно получится». Тогда следует задать вопросы: «На чём основана ваша уверенность в успехе? Что мы можем сделать, чтобы не повторить ошибок?» Если заказчик готов пойти на риск и вложить дополнительные ресурсы — например выделить более высокий бюджет, чем был у конкурента, — исполнителю придётся эту идею воплощать.',
        },
        { type: 'heading', level: 2, content: 'Что делать, если придётся воплощать идею, в которую вы не верите' },
        {
          type: 'paragraph',
          content:
            'Бывают ситуации, когда не срабатывают даже самые убедительные аргументы. Как быть в этом случае?',
        },
        {
          type: 'paragraph',
          content:
            'К идее, даже самой бредовой, нужно относиться как к гипотезе: у неё должен быть измеримый результат. Поэтому важно договориться с заказчиком, за каким показателем и как долго вы будете наблюдать. Например, предложить измерять конверсию в течение месяца, а потом оценить результат.',
        },
        {
          type: 'paragraph',
          content:
            'Вот что ещё поможет проверить гипотезу:',
        },
        {
          type: 'list',
          style: 'unordered',
          items: ['<strong>A/B-тестирование.</strong> Суть этого метода в том, чтобы проверять идею только на части аудитории, а для остальных оставить всё как было.','<strong>Опрос аудитории.</strong> Например, клиент предлагает изменить дизайн сайта. Можно отправить самым лояльным клиентам опрос с несколькими вариантами, узнать их мнение, а затем уже принимать решение'],
        },
        {
          type: 'paragraph',
          content:
            'Если для реализации идеи нужен дополнительный бюджет, его тоже следует сделать ограниченным. Например, потратить на тестирование рекламной кампании 100 000 рублей и ни копейки больше.',
        },
        {
          type: 'paragraph',
          content: 'Иногда от реализации идеи может зависеть KPI сотрудника. Например, ему нужно привлечь 60 лидов в месяц, чтобы выполнить план. Если руководитель предложит внедрить новую идею в начале месяца, это может помешать достичь нужных показателей. Обсудите это ещё до начала выполнения задачи — например, предложите снизить KPI с учётом новых условий.',
        },
        {
          type: 'styledQuote',
          content: 'Самые недовольные клиенты — ваш лучший источник обучения',
          author: 'Билл Гейтс',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.article-content {
  box-sizing: border-box;
  padding: 32px;
  max-width: 900px;
  margin-top: 16px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 32px;
}

/* Заголовки */
h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 36.4px;
  margin: 20px 0;
}

h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin: 15px 0;
}

/* Параграфы */
p {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 20px;
}

/* Розовый текст */
h4 {
  color: rgba(202, 24, 93, 1);
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}

/* Жирный текст */
h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;
}

/* Подчёркнутый текст */
u {
  text-decoration: underline;
}

/* Цитаты */
blockquote {
  margin: 20px 0;
  padding: 10px 12px;
  border-left: 4px solid rgba(220, 118, 169, 1);
  background-color: #fdecef;
  border-radius: 0 16px 16px 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 54px;
}

/* Стилизованные цитаты */
.сitation {
  position: relative;
  background-color: rgba(217, 229, 255, 1);
  border-radius: 16px;
  padding: 20px;
  margin: 20px 0;
  overflow: visible;

  .quote-mark {
    position: absolute;
    z-index: 10;
    top: -16px;
    left: 10px;
    height: auto;
  }

  .quote-mark180 {
    position: absolute;
    z-index: 10;
    bottom: -16px;
    right: 10px;
    height: auto;
  }

  .citation-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
    padding: 3px 0 0 50px;
    color: #333;
  }

  .citation-author {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    color: rgba(40, 49, 239, 1);
    margin: 0;
    padding: 10px 0 0 50px;
  }
}

/* Info-box */
.info-box {
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  font-size: 16px;
}

.info-box.note {
  border-left: 5px solid #5bc0de;
  background-color: #e8f4fa;
}

.info-box.important {
  border-left: 5px solid #d9534f;
  background-color: #fdecec;
}

/* Списки */
ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

ol {
  padding-left: 20px;
  margin-bottom: 20px;
}

li {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 10px;
}

/* Изображения */
.image-wrapper {
  margin: 20px 0;
  text-align: center;
}

.image-wrapper img {
  max-width: 100%;
  height: auto;
}

.image-wrapper.full-width img {
  width: 100%;
}

.image-caption {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  text-align: center;
}
</style>
