<template>
  <div class="articles-overview">
    <div class="articles-title">Будьте в курсе текущих тенденций и технологий</div>
    <div class="filter-section">
      <button 
        v-for="filter in filters" 
        :key="filter" 
        @click="setFilter(filter)" 
        class="btn btnfil secondary" 
        :class="{ active: currentFilter === filter }"
      >
        {{ filter }}
      </button>
    </div>

    <!-- Список статей -->
    <div class="articles-list">
      <ArticleCard
        v-for="article in paginatedArticles"
        :key="article.id"
        :article="article"
        :isVertical="true"
        :imageWidth="'530px'"
        :imageHeight="'340px'"
        :buttonPaddingTop="'32px'"
        :buttonWidth="'100%'"
      />
    </div>

    <!-- Кнопка "Показать ещё" -->
    <button class="btn btnall primary" v-if="hasMoreArticles" @click="loadMore">Показать ещё</button>
  </div>
</template>

<script>
import ArticleCard from '@/components/ProfessionsAsap/ArticlesSection/ArticleCard.vue';

export default {
  name: "ArticlesOverview",
  components: { ArticleCard },
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentFilter: 'Показать все',  // Текущий выбранный фильтр
      filters: ['Показать все', 'Frontend', 'Backend', 'UX/UI Design', 'Проджект-менеджмент', 'Тестирование', 'Аналитика', 'Кейсы', 'Другое'], // Доступные фильтры
      itemsPerPage: 9,  // Количество статей на одной странице
      currentPage: 1,   // Текущая страница
      displayedArticles: [],  // Список для отображаемых статей
    };
  },
  computed: {
    // Фильтруем статьи по выбранному фильтру
    filteredArticles() {
      if (this.currentFilter === 'Показать все') {
        return this.articles;
      }
      return this.articles.filter(article => article.profession.toLowerCase() === this.currentFilter.toLowerCase());
    },
    // Пагинация: возвращаем только те статьи, которые нужно отобразить на текущей странице
    paginatedArticles() {
      return this.displayedArticles; // Показываем все уже загруженные статьи
    },
    // Проверяем, есть ли еще статьи для отображения
    hasMoreArticles() {
      return this.filteredArticles.length > this.displayedArticles.length;
    },
  },
  methods: {
    // Устанавливаем выбранный фильтр
    setFilter(filter) {
      this.currentFilter = filter;
      this.currentPage = 1;  // Сбросим на первую страницу при изменении фильтра
      this.displayedArticles = [];  // Очищаем текущие отображаемые статьи
      this.loadMore();  // Загружаем первые 9 статей после фильтрации
    },

    // При нажатии на кнопку "Показать ещё" увеличиваем текущую страницу и добавляем статьи
    loadMore() {
      const nextArticles = this.filteredArticles.slice(this.displayedArticles.length, this.displayedArticles.length + this.itemsPerPage);
      
      // Добавляем новые статьи в конец
      this.displayedArticles = [...this.displayedArticles, ...nextArticles];

      // Если добавилось меньше 9 статей, значит, это последние статьи, и кнопка должна исчезнуть
      if (nextArticles.length < this.itemsPerPage) {
        this.hasMoreArticles = false;
      }
      
      // Увеличиваем текущую страницу
      this.currentPage++;
    },
  },
  mounted() {
    // Загружаем первые 9 статей при монтировании компонента
    this.loadMore();
  }
};
</script>

<style scoped>

.articles-title{
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    padding-bottom: 32px;
}


.articles-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-section {
  display: flex;
  gap: 8px;
}

.articles-list {
    padding-top: 32px;
  display: flex;
  flex-wrap: wrap; /* Позволяет элементам переноситься на следующую строку */
  gap: 16px; /* Расстояние между блоками */
  justify-content: center; /* Центрирует строки */
}

.articles-list > * {
  flex: 1 1 calc(33.333% - 16px); /* Каждый компонент занимает 1/3 ширины контейнера */
  max-width: calc(33.333% - 16px); /* Ограничивает ширину блока */
  box-sizing: border-box; /* Учитывает отступы и границы */
}



/* Стиль для активной кнопки */
.btn.secondary.active {
  background-color: rgba(224, 58, 123, 1);
  color: rgba(255, 255, 255, 1);
}

/* Стиль для кнопки "Показать ещё" */
.btnall {
  margin-top: 32px;
  width: 600px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 20px 30px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Основной стиль для кнопок */
.btnfil {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 8px 16px;
  border-radius: 50px; /* Округление углов */
  cursor: pointer;
  transition: all 0.3s ease;
}
</style>
