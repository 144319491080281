 <template>
  <AsapTeleport to="#modal">
  <div v-if="isVisible" class="modal-overlay" @click.self="$emit('close')">
    <div class="form-section">
      <!-- Кнопка закрытия -->
      <button class="close-button" @click="$emit('close')">
        ✕
      </button>
      <div class="img-tanya">
        <img src="@/assets/PopUp's/FeedbackFrom/Tanya.svg" alt="Tanya" class="sub-Tanya" />
      </div>
      <h4>Привет! Меня зовут Таня</h4>
      <p>Оставь заявку, и я свяжусь с тобой</p>
      <form @submit.prevent="handleSubmit">
        <div class="inputs">
          <!-- Поле Имя и Фамилия -->
          <div class="input-container">
            <input
              id="name"
              v-model="name"
              type="text"
              class="input-field"
              @focus="isNameFocused = true"
              @blur="isNameFocused = false"
              required
            />
            <label
              for="name"
              class="input-label"
              :class="{ focused: name || isNameFocused }"
            >
              Имя и фамилия
            </label>
          </div>

          <!-- Поле Телефон -->
          <div class="input-container">
            <input
              id="phone"
              v-model="phone"
              type="tel"
              class="input-field"
              :placeholder="phone ? '' : '+7 (_ _ _) _ _ _-_ _-_ _'"
              @focus="handlePhoneFocus"
              @blur="handlePhoneBlur"
              @input="formatPhone"
              required
            />
          </div>
        </div>
        <!-- Чекбокс -->
        <div class="checkbox-container">
          <input
            id="privacy"
            type="checkbox"
            v-model="isChecked"
            class="checkbox-input"
          />
          <label for="privacy" class="checkbox-label">
            Я ознакомлен с политикой конфиденциальности
          </label>
        </div>
        <!-- Кнопка отправки -->
        <button :disabled="isLoading" type="submit" class="Btn Primary">
          {{ isLoading ? "Отправка..." : "Оставить заявку" }}
        </button>
      </form>
    </div>
  </div>
</AsapTeleport>
</template>

<script>
import AsapTeleport from "@/components/UI/AsapTeleport.vue";

export default {
  components: {
    AsapTeleport,
  },

  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      phone: "",
      isChecked: false,
      isNameFocused: false,
      isLoading: false,
    };
  },
  methods: {
    handlePhoneFocus() {
      if (!this.phone) {
        this.phone = "+7";
      }
    },
    handlePhoneBlur() {
      if (this.phone === "+7") {
        this.phone = "";
      }
    },
    formatPhone(event) {
      const input = event.target.value.replace(/[^\d]/g, "");
      if (input.startsWith("7")) {
        this.phone = `+${input}`;
      } else {
        this.phone = `+7${input}`;
      }
    },
    async handleSubmit() {
      if (!this.isChecked) {
        alert("Вы должны согласиться с политикой конфиденциальности.");
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.form-section {
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
  position: relative;
  padding: 56px;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.1);
  max-width: 700px;
  width: 90%;
  z-index: 9;

  h4 {
    font-size: 28px;
    font-weight: 600;
    line-height: 36.4px;
    color: #e03a7b;
    margin: 0;
    margin-bottom: 16px;
    text-align: center;
  }

  p {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    color: #6a6a6a;
    margin: 0;
    margin-bottom: 24px;
    text-align: center;
  }
}

.inputs {
  display:flex;
  gap: 8px;
}

.input-container {
  position: relative;
  margin-bottom: 1.5rem;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.input-field {
  padding: 1rem;
  border: 2px solid #9a9a9a;
  border-radius: 1rem;
  transition: all 0.3s ease;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  
}

.input-field:focus {
  border-color: #e03a7b;
  outline: none;
  background-color: rgba(255, 213, 238, 1);
}

.input-label {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  color: #9a9a9a;
  pointer-events: none;
  transition: all 0.3s ease;
}

.input-label.focused {
  top: -0.5rem;
  font-size: 0.75rem;
  color: #e03a7b;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.checkbox-input {
  width: 1.4rem;
  height: 1.4rem;
  accent-color: #e03a7b;
}

.checkbox-label {
  margin-left: 0.5rem;
  font-size: 16px;
}

.Btn {
  width: 100%;
  padding: 1rem;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background-color: #e03a7b;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Btn:disabled {
  background-color: #ccc;
}

.Btn:hover:not(:disabled) {
  background-color: #ca185d;
}

/* Кнопка закрытия */
.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #e03a7b;
  color: #fff;
}

/* Адаптивность */
@media (max-width: 768px) {
  .inputs {
    flex-direction: column; /* Инпуты в столбик */
    gap: 12px; /* Расстояние между инпутами */
  }

  h4 {
    font-size: 20px;
    line-height: 28px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
  }

  .input-field {
    font-size: 16px;
    line-height: 22px;
  }

  .checkbox-label {
    font-size: 14px;
  }

  .Btn {
    font-size: 14px;
    line-height: 20px;
  }

  .close-button {
    width: 36px;
    height: 36px;
    font-size: 1.2rem;
  }
}
</style>
