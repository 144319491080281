<template>
  <div class="employment-card">
    <div class="employment-content">
      <!-- Левая часть со списком услуг -->
      <div class="employment-left">
        <h3>Во время обучения ты:</h3>
        <ul class="services">
          <li v-for="(service, index) in services" :key="index">
            <span class="custom-marker">
              <img 
                :src="require('@/assets/star/Star.svg')" 
                alt="Marker" 
                width="44" 
                height="44" 
                class="marker-img"
              />
            </span>
            <span class="service-text">{{ service }}</span>
          </li>
        </ul>
      </div>

      <!-- Правая часть с изображением -->
      <div class="employment-right">
        <img :src="image" alt="Employment Image" class="employment-image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    services: Array,
    image: String,
  },
};
</script>

<style scoped>
.employment-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 32px;
}

.employment-content {
  display: flex;
  
}

.employment-left {
  display: flex;
  flex-direction: column;
  padding: 56px;
  flex: 1;
 
  border-radius: 10px;
}


.employment-left h3 {
  margin: 0;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 32px;
}

.employment-right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 32px;
  border: 2px solid rgba(245, 162, 194, 1);
  border-radius: 32px;
  
}

.employment-image {
  max-width:100%;
  height: auto;
  
  
}


/* Стили списка */
.services {
  list-style: none;
  padding: 0 ;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.services li {
  font-weight: 400;
    font-size: 24px;
  display: flex;
  align-items: start;
  gap: 8px;
  color: rgba(106, 106, 106, 1);
}

.custom-marker {
  flex-shrink: 0;
  
}

.service-text {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}





@media (max-width: 480px) {



  
.employment-content{
  flex-direction: column;
}

.employment-left{
  padding: 24px;
  padding-bottom: 32px;
  flex: 0;
}


.employment-left h3{
  font-size: 24px;
}

.service-text{
  font-size: 14px;
  line-height: 21px;
}



.employment-right{
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 16px !important;
}
.employment-image{
  width: 100%;
  height: 100%;
}

.marker-img{
  width: 22px;
  height: 22px;
  
}

.services{
  gap: 8px;
}


}


</style>
