<template>
  <div class="contain-main">
    <h1 class="contain-main__title">Курсы</h1>
    <div class="column-main">
      <CoursesCard
        v-for="(profession, index) in blueProfessions"
        :key="index"
        :title="profession.title"
        :price="profession.price"
        :fullPrice="profession.fullPrice"
        :description="profession.description"
        :image="profession.image"
        :details="profession.details"
        :link="profession.link"
        :imageStyle="blueImageStyle"
        blue
        :columnColor="'blue'"
        @click="navigate(profession)"
        
      />
    </div>
    <div class="column-main">
      <CoursesCard
        v-for="(profession, index) in pinkProfessions"
        :key="index"
        :title="profession.title"
        :price="profession.price"
        :fullPrice="profession.fullPrice"
        :description="profession.description"
        :image="profession.image"
        :details="profession.details"
        :link="profession.link"
        :imageStyle="pinkImageStyle"
        pink
        :columnColor="'pink'"
        @click="navigate(profession)"
      />
    </div>
  </div>
</template>

<script>
import CoursesCard from './CoursesCard.vue';

export default {
  components: { CoursesCard },
  data() {
    return {
      professions: [
        {
          title: 'Frontend-разработчик',
              price: 'От 5 200 ',
              fullPrice: 'Полная стоимость курса 160 000 ₽',
          description: "HTML и Java Script разложатся в твоей голове по полочкам. Ты на практике освоишь фреймворк Vue: от создания проекта до компонентов и форм ввода.",
          image: require('@/assets/ProfPng/Front_blue.png'),
          details: ['С нуля', '4 места', '5 месяцев'],
          link: '/frontend',
          color: 'blue',
            },
        {
          title: 'Тестировщик',
            price: 'От 4 800',
            fullPrice: 'Полная стоимость курса 160 000 ₽',
          description: "Разберёшься с основами разработки ПО и типами баз данных. На практике опробуешь инструменты тестирования ПО, API, Web- и мобильных приложений. Мы собрали то, что точно пригодится в работе, и сделали понятную систему даже для новичка в IT",
          image: require('@/assets/ProfPng/QA_blue.png'),
          details: ['С опытом', '0 мест', '5 месяцев'],
          link: '/test',
          color: 'blue',
            },
         {
          title: 'Backend-разработчик',
             price: 'От 4 800',
             fullPrice: 'Полная стоимость курса 160 000 ₽',
          description: "Научишься программировать на языке PHP, фреймворке Laravel, который лежит в основе большого количества известных IT-продуктов",
          image: require('@/assets/ProfPng/Back_blue.png'),
          details: ['С опытом', '1 место', '5 месяцев'],
          link: '/backend',
          color: 'blue',
            },
                    {
          title: 'UX/UI дизайнер',
                        price: 'От 5 200',
                fullPrice: 'Полная стоимость курса 160 000 ₽',
          description: "Научишься проектировать удобные интерфейсы с этапа исследования до передачи макетов разработчику. Узнаешь про принципы создания мобильных приложения, сайтов и других платформ",
          image: require('@/assets/ProfPng/UX-UI_Pink.png'),
          details: ['С нуля', '1 место', '5 месяцев'],
          link: '/professions',
          color: 'pink',
            },

        {
          title: 'Системный аналитик',
            price: 'От 4 800',
            fullPrice: 'Полная стоимость курса 160 000 ₽',
          description: "Научишься собирать запросы и требования бизнеса, адаптируя их для работы команды разработчиков. Получишь навыки системного мышления, анализа потребностей и постановки задач, а также получишь базовую техническую подготовку",
          image: require('@/assets/ProfPng/SA_Pink.png'),
          details: ['С нуля', '6 мест', '5 месяцев'],
          link: '/sa',
          color: 'pink',
            },
       {
          title: 'Менеджер IT-проектов',
           price: 'От 4 800',
           fullPrice: 'Полная стоимость курса 160 000 ₽',
          description: "С нуля научишься управлять командой разработки IT-проектов. Погрузишься в Agile и SCRUM методологии, узнаешь как выстраивать эффективную работу с командой, заказчиком и научишься планировать проект",
          image: require('@/assets/ProfPng/PM_pink.png'),
          details: ['С нуля', '3 места', '5 месяцев'],
          link: '/pm',
          color: 'pink',
        },

       
        
        ],
      hiddenProfession: null,
    };
  },
  computed: {
    // Фильтрация в зависимости от текущего пути
    filteredBlueProfessions() {
      if (this.shouldHideProfession()) {
        return this.blueProfessions.filter(prof => prof !== this.hiddenProfession);
      }
      return this.blueProfessions;
    },
    filteredPinkProfessions() {
      if (this.shouldHideProfession()) {
        return this.pinkProfessions.filter(prof => prof !== this.hiddenProfession);
      }
      return this.pinkProfessions;
    },
    blueProfessions() {
      return this.professions.filter(prof => prof.color === 'blue');
    },
    pinkProfessions() {
      return this.professions.filter(prof => prof.color === 'pink');
    },
  blueImageStyle() {
    return {
      filter: 'hue-rotate(0deg) brightness(2.5) saturate(0.9)',  // исходный фильтр для голубых картинок
    };
  },
  pinkImageStyle() {
    return {
      filter: 'hue-rotate(16deg) brightness(2.7) saturate(1.5) contrast(0.9)',  // исходный фильтр для розовых картинок
    };
  },
  },
  methods: {
    navigate(profession) {
      // Скрыть выбранную профессию только если мы переходим на ее страницу
      if (this.isProfessionPage(profession.link)) {
        this.hiddenProfession = profession;
      } else {
        this.hiddenProfession = null;
      }
      this.$router.push(profession.link);
    },
    shouldHideProfession() {
      // Определить, нужно ли скрывать профессию, основываясь на текущем пути
      return this.hiddenProfession && this.isProfessionPage(this.$route.path);
    },
    isProfessionPage(path) {
      // Проверить, являемся ли мы на странице профессии
      return path.includes('/frontend') || path.includes('/analitic'); // добавьте другие пути по необходимости
    }
  }
};
</script>

<style>
.contain-main {
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  gap: 16px; 
}

.contain-main__title{
  font-size: 80px;
  line-height: 88px;
  font-weight: 700;
  padding-bottom: 64px;
  margin: 0;
}

.column-main {
  display: flex;
  gap: 16px; 

}

.column-main .prof-cardMain {
  max-width: 600px; 
  height: 590px;
  box-sizing: border-box; 
}



@media (max-width: 480px) {


.contain-main {
  padding-top: 80px;
}
.contain-main__title{
  font-size: 32px;
  line-height: 41px;
  padding-bottom: 32px;
}

.column-main {
  flex-direction: column;
}

.column-main .prof-cardMain {
      max-height: 350px;
}


}

</style>