<template>
  <div class="sticky-wrapper">
    <div
      class="mailing-telegram__container"
      :style="{ margin: containerMargin, padding: containerPadding }"
    >
      <div class="block-with__text">
        <p>{{ text }}</p>
        <img src="@/assets/Articlesvg/Mailingimg/tg1.svg" />
      </div>
      <button :style="buttonStyles">
        <a
          class="subscribe-button"
          :href="telegramLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p :style="buttonTextStyles">Подписаться</p>
        </a>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MailingList",
  props: {
        text: {
      type: String,
      default:
        "У нас также есть телеграм-комьюнити, где появляются свежие новости и интересные посты",
    },
    // Пропсы для управления стилями кнопки
    buttonStyles: {
      type: Object,
      default: () => ({}),
    },
    buttonTextStyles: {
      type: Object,
      default: () => ({}),
    },
    // Пропсы для изменения margin и padding
    containerMargin: {
      type: String,
      default: "158px auto 0 auto",
    },
    containerPadding: {
      type: String,
      default: "0",
    },
    // Ссылка на Telegram
    telegramLink: {
      type: String,
      default: "https://t.me/asapeducation",
    },
  },
};
</script>

<style lang="scss">
.sticky-wrapper {
  position: relative; /* Родитель для абсолютного позиционирования */
  height: 80%; /* Высота для ограничения sticky-блока */
}

.mailing-telegram__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 20px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 32px;
  max-width: 434px;

  .block-with__text {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    margin: 24px;
    border-radius: 20px;
    background-color: rgba(240, 244, 249, 1);
    max-height: 208px;

    p {
      margin: 24px 24px 0 24px;
      font-size: 20px;
      line-height: 26px;
      padding-bottom: 80px;
      z-index: 2;
    }

    img {
      position: absolute;
      max-width: 137px;
      border-radius: 20px;
    }
  }

  .subscribe-button,
  button {
    background-color: rgba(224, 58, 123, 1);
    border: none;
    border-radius: 16px;
    width: 91%;
    padding: 0;
    margin-bottom: 24px;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    text-decoration: none;

    p {
      color: rgba(255, 255, 255, 1);
      font-size: 20px;
      line-height: 30px;
      margin: 20px;
      
    }

    &:hover {
      background-color: rgba(202, 24, 93, 1);
    }
  }
}

  @media (max-width: 480px) {

.mailing-telegram__container{
  border-radius: 24px;
}
.block-with__text{
  margin: 12px !important;
}
.block-with__text p{
  font-size: 12px !important;
  line-height: 16px !important;
  padding-bottom: 60px;

}

.block-with__text img{
  width: 100px;
  height: 100px;
}
.subscribe-button p {
  font-size: 14px !important;
  margin: 12px !important;
}


  }




</style>
