<template>
  <div class="mentor-card" :style="cardStyles">
    <div class="mentor-card__imgd" :style="imageStyles"><img :src="mentor.image" :alt="mentor.name" class="mentor-card__img" :style="imageStyles"></div>
    
    
    <!-- Иконка, которая будет отображаться только если showIcon == true -->
    <div v-if="showIcon" class="mentor-icon" :style="iconStyles">
      <img src="../../../assets/icon/OurMentors.png" alt="icon" />
    </div>

    <div class="mentor-info" :style="mentorInfoStyles">
      <h3>{{ mentor.name }}</h3>
      <h4>{{ mentor.company }}</h4>
      <p v-html="mentor.description"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MentorCard",
  props: {
    mentor: {
      type: Object,
      required: true
    },
    showIcon: {  // Новый пропс для управления отображением иконки
      type: Boolean,
      default: false,
    },
        imageWidth: {
      type: String,
    },
    imageHeight: {
      type: String,
    },

    cardPadding: {
      type: String,
      default: "16px", // Отступы вокруг карточки по умолчанию
    },
    mentorInfoDirection: {
      type: String,
      default: "column", // Направление флекса для .mentor-info по умолчанию (колонка)
    },
    cardWidth: {
      type: String,
      default: "100%" // Ширина карточки по умолчанию
    },

    cardMinHeight: {
      type: String,

    },
    cardDirection: {
      type: String,
      default: "column", // Направление для всей карточки по умолчанию
    },
    textAlign: {
      type: String,
      default: "center", // Выравнивание текста по умолчанию
    },
    padding: {
      type: String,
      default: "0 14px 0 24px", // Выравнивание текста по умолчанию
    },
  },
  computed: {
    // Стиль для самой карточки
    cardStyles() {
      return {
        maxWidth: this.cardWidth, // Ширина карточки
        minHeight: this.cardMinHeight, // Ширина карточки
        padding: this.cardPadding, // Отступы для карточки
        display: "flex",
        flexDirection: this.cardDirection, // Направление для всей карточки
        alignItems: "left", // Центрируем содержимое
      };
    },
    // Стиль для изображения
    imageStyles() {
      return {
        width: this.imageWidth,
        height: this.imageHeight,
        objectFit: "cover", // Заполнение изображения по размерам
        borderRadius: "16px", // Радиус скругления изображения
        zIndex: "2"// Отступ снизу от изображения
      };
    },
    // Стиль для блока mentor-info
    mentorInfoStyles() {
      return {
        display: "flex", // Оставляем display: flex по умолчанию
        flexDirection: this.mentorInfoDirection, // Направление флекса, передаваемое пропсом
        alignItems: "left", // Центрирование элементов по вертикали
        gap: "10px", // Расстояние между элементами
        textAlign: this.textAlign,
        padding: this.padding, // Выравнивание текста
      };
    },
    // Стиль для иконки
    iconStyles() {
      return {
        position: "absolute",
        right: "0",
        top: "280px",
        cursor: "default",
        zIndex: "1"
      };
    },
  },
};
</script>

<style scoped>
.mentor-card {
  position: relative; /* Это необходимо для правильного расположения иконки относительно карточки */
  background-color: white;
  border-radius: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  align-items: left;
}

.mentor-info {
  text-align: left;
}

.mentor-info h3 {
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  color: rgba(24, 8, 14, 1);
  text-transform: uppercase;
}

.mentor-info h4 {
  margin: 6px 0 8px 0;
  color: rgba(40, 49, 239, 1);
  font-size: 20px;
  font-weight: 400;
}

.mentor-info p {
  margin: 0;
  color: rgba(106, 106, 106, 1);
  font-size: 20px;
  font-weight: 400;
  
}

/* Стили для иконки */
.mentor-icon {
  z-index: 1;
  position: absolute;
  right: 0;
  top: 280px;
  cursor: default;
}



@media (max-width: 480px) {

  .mentor-card{
    padding: 16px !important;
    min-height: 500px !important;
  }

  .mentor-info {
    
    display: flex !important;
    gap: 0 !important;
    align-items: left !important; /* Центрируем текст */
    text-align: left !important; /* Центрируем текст */
    padding: 0!important; /* Паддинги для карточки */
  }

  .mentor-info h3 {
    font-size: 18px !important; /* Уменьшаем размер заголовка */
    line-height: 23px !important;
    text-align: left !important; /* Центрируем текст */
    padding-top: 24px !important;
    padding-bottom: 4px !important;
  }

  .mentor-info h4 {
    font-size: 12px !important; /* Уменьшаем размер подзаголовка */
    text-align: left !important;
     margin: 0 !important;
     padding-bottom: 8px !important;

  }

  .mentor-info p {
    font-size: 12px !important; /* Уменьшаем размер текста */
    text-align: left !important; /* Центрируем текст */
    line-height: 15.6px  !important;
  }


.mentor-card__imgd {
  width: 100% !important; /* Ширина 100% от контейнера */
  max-height: 300px !important; /* Высота блока с изображением */
  border-radius: 16px !important;
  overflow: hidden !important; /* Обрезаем все, что выходит за пределы блока */
}

/* Само изображение */
.mentor-card__img {
  width: 100% !important; /* Растягиваем по ширине контейнера */
  height: 100% !important; /* Растягиваем по высоте контейнера */

  margin-bottom: 0 !important; /* Убираем нежелательные отступы */
}


.mentor-icon {
  width: 100px;
  height: 100px;
  margin-right: 97px;
  top: 170px !important;
}

}



</style>
