<template>
  <section class="why-asap-section">
    <h2>Почему ASAP</h2>
    <div class="why-asap-content" ref="whyAsapContent">
      <!-- Левая часть с карточками -->
      <div class="cards">
        <div class="card">
          <h3>Портфолио</h3>
          <h4>ОФОРМЛЕНИЕ ПОРТФОЛИО</h4>
          <p>
            Во время обучения соберёшь 4 сильных проекта, которые сможешь
            положить в портфолио. Среди проектов будет и реальная задача от заказчика.
          </p>
         <div class="card-png-1"></div>
        </div>

        <div class="card">
          <h3>UX</h3>
          <h4>ПРОДУКТОВЫЙ ПОДХОД</h4>
          <p>
            Мы учим думающих дизайнеров, которые опираются на факты, а не
            ощущения. Поймёшь важность данных и метрик в дизайне.
          </p>
          <div class="card-png-2"></div>
        </div>

        <div class="card">
          <h3>Менторство</h3>
          <h4>АРТ-ДИРЕКШЕН ОТ МЕНТОРОВ</h4>
          <p>
            Менторы помогут улучшить качество твоих работ. Увидишь, как они
            создают качественный дизайн и сможешь почерпнуть их приёмы.
          </p>
          <div class="card-png-3"></div>
        </div>

        <div class="card">
          <h3>Работа в команде</h3>
          <h4>ПРИСТУПАЕШЬ К ПРОЕКТУ ПЕРВЫМ</h4>
          <p>
            Совместно с менеджером и аналитиком начнёшь коммуникацию с
            заказчиком для выявления требований по проекту.
          </p>
          <div class="card-png-4"></div>
        </div>
      </div>





      
      <!-- Правая часть с формой -->
      <div class="cards-two" ref="cardsTwo"><FormSection /></div>
      
    </div>
  </section>
</template>

<script>
import FormSection from "./FormSection.vue";

export default {
  name: "WhyAsapSection",
  components: {
    FormSection,
  },
  data() {
    return {
      offsetTop: 0, // Отступ сверху
    };
  },
  mounted() {
    // Привязываем обработчик скролла
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // Отключаем обработчик скролла перед уничтожением компонента
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const parent = this.$refs.whyAsapContent; // Родительский блок
      const right = this.$refs.cardsTwo; // Правый блок

      if (!parent || !right) return;

      const parentRect = parent.getBoundingClientRect();
      const rightRect = right.getBoundingClientRect();

      if (parentRect.top <= this.offsetTop && parentRect.bottom - this.offsetTop > rightRect.height) {
        // Прокрутка внутри родителя
        right.style.position = "sticky";
        right.style.top = `${this.offsetTop}px`;
      } else if (parentRect.bottom - this.offsetTop <= rightRect.height) {
        // Блок достиг нижней границы родителя
        right.style.position = "sticky";
        right.style.top = `${parentRect.bottom - rightRect.height}px`;
      } else {
        // Возвращение в нормальное состояние
        right.style.position = "static";
      }
    },
  },
};
</script>

<style scoped>
/* Основной стиль секции */
.why-asap-section {


    padding-top: 200px;

  background-color: rgba(240, 244, 249, 1); /* Цвет фона */
}

.why-asap-section h2 {
  margin: 0;
  font-size: 80px;
  font-weight: 700;
  text-align: left;
  padding-bottom: 64px;
}

.why-asap-content {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;
 
}

/* Карточки */
.cards {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Две колонки */
  gap: 16px;
  flex: 1; /* Занимает 50% ширины */
}
.cards-two{
  flex: 1;

}

.card {
  background: #fff;
  border-radius: 32px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 32px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.card h3 {

  font-size: 18px;
  font-weight: 400;
  color: rgba(154, 154, 154, 1);
  margin: 0;
  padding-top: 20px;
  padding-bottom: 8px;
}

.card h4 {

  font-size: 28px;
  font-weight: 600;
  color: rgba(24, 8, 14, 1);
  margin: 0;
}

.card p {

  font-size: 24px;
  font-weight: 400;
  color: rgba(106, 106, 106, 1);
  line-height: 1.5;
  margin: 0;
  padding:16px 0 150px;
}

.card-png-1{
  background-image: url(@/assets/advantagesPng/fav-folder-dynamic-clay.png);
  width: 185px;
  height: 170px;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  margin-bottom: -15px;
 margin-right: -10px;
 bottom: 0;
}


.card-png-2{
  background-image: url(@/assets/advantagesPng/Chart.png);
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
margin-bottom: -15px;
  bottom: 0;
}

.card-png-3{
  background-image: url(@/assets/advantagesPng/notify-heart-dynamic-clay.png);
  width: 175px;
  height: 170px;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
margin-bottom: -15px;
   bottom: 0;
}

.card-png-4{
  background-image: url(@/assets/advantagesPng/Chess.png);
  width: 165px;
  height: 175px;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
margin-bottom: -15px;
   bottom: 0;
}

/* Форма */
@media (max-width: 480px) {
  .why-asap-content {
    flex-direction: column; /* Колонки становятся одной колонкой */
    
  }
   .why-asap-section{
    padding-top: 80px;
   }

  .why-asap-section h2{
    font-weight: 700;
    font-size: 32px;
    padding-bottom: 32px;
  }

  .cards {
    display: block; /* Карточки в один столбик */
    width: 100%;
    
  }

  .card {
    margin-top: 16px;
  }
  .card p{
    font-size: 14px;
    font-weight: 400;
  }

  .card h3{
    font-weight: 400;
    font-size: 12px;
  }

  .card h4{
    font-weight: 600;
    font-size: 18px;
  }


  .cards-two {
    width: 100%;
    margin-top: 20px; /* Отступ сверху для формы */
  }
}




</style>