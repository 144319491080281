<template>
    <div class="cover_block-container" :style="{ backgroundImage: `url(${backgroundImage})` }">
      <h1>{{ title }}</h1>
      <div class="date">{{ date }}</div>
      <div class="tags">
        <p v-for="(tag, index) in tags" :key="index">{{ tag }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CoverBlock",
    data() {
      return {
        title: "Что делать, если клиент или руководитель предлагает фигню",
        date: "6 ноября 2023",
        tags: ["Аналитика", "Accessibility", "UX", "Usability", "UI Design"],
        backgroundImage: require("@/assets/Articlesvg/CoverBlockImg/image35.svg"), // Указываем путь к локальному изображению
      };
    },
  };
  </script>
  
  <style lang="scss">
  .cover_block-container {
    max-width: 902px;
    max-height: 406px;
    height: 100%;
  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  
    h1 {
      text-align: center;
  
      margin: 0;
      padding: 84px 74px 24px 93px;
  
      font-size: 40px;
      font-weight: 600;
      line-height: 52px;
    }
  
    .date {
      width: 21.8%;
      padding: 8px 0;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.4);
  
      text-align: center;
      font-size: 20px;
      line-height: 26px;
  
      margin-bottom: 8px;
    }
  
    .tags {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
  
      width: 85%;
      padding-bottom: 76px;
  
      p {
        padding: 8px 16px;
        background-color: rgba(240, 244, 249, 1);
        border-radius: 60px;
        border: 1px solid rgba(40, 49, 239, 1);
        color: rgba(40, 49, 239, 1);
      }
    }
  }
  </style>
  