<template>
  <div class="certificate-asap">
    <div class="certificate-asap__left-block">
      <div class="left-block__img"></div>
    </div>
    <div class="certificate-asap__right-block">
      <div class="certificate-asap__text-block">
        <!-- Два текста в отдельном div -->
        <p class="text-block__title">Выдаем сертификаты установленного образца</p>
        <p class="text-block__discription">Обучение в Asap Education одобрено на государственном уровне</p>
      </div>
      <!-- Третий текст внизу -->
      <p class="certificate-asap__bottom-text">Также вы можете получить налоговый вычет до 13% от стоимости курса</p>
            <div class="certificate-asap__img">
        <!-- Используем тег <img> вместо background-image -->
        <img class="certificate-img" src="@/assets/CertificateAsap/Graphics.png" alt="Graphics">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CertificateAsap",
};
</script>

<style scoped>
.certificate-asap {
  display: flex;
  padding-top: 200px;
  gap: 16px;
}

.certificate-asap__left-block {
    border-radius: 32px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 1);
width: 1050px;
height: 550px;
position: relative;
}

.left-block__img{
    width: 100%;
    height: 100%;
    background-image: url(@/assets/CertificateAsap/Img.png);
    background-repeat: no-repeat;
   background-size: contain;
}

.certificate-asap__right-block {
    max-width: 700px;
    position: relative; 
  overflow: hidden;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 64px 0 58px 68px;
}

.certificate-asap__text-block {
    display: flex;
    flex-direction: column;
    gap: 32px;
    z-index: 2;
}

.text-block__title{
    max-width: 580px;
    font-size: 40px;
    font-weight: 600;
    margin: 0;
}

.text-block__discription{
    max-width: 625px;
    font-size: 24px;
    font-weight: 400;
    margin: 0;
}

.certificate-asap__bottom-text {
  margin: 0;
  max-width: 485px;
    font-size: 20px;
    font-weight: 400;
    color: rgba(24, 8, 14, 1);
    opacity: 0.4;
    z-index: 2;
}


.certificate-asap__img{
  bottom: 0;
  right: 0;
  width: 600px;
  height: 345px; 
  position: absolute; 
}

.certificate-img{
  width: 100%;
  height: 100%;
  z-index: 1;

}


@media (max-width: 480px) {

.certificate-asap{
  padding-top: 80px;
  flex-direction: column;
}


.certificate-asap__left-block{
  max-width: 100%;
  height: 200px;
      border-radius: 24px;
}

.certificate-asap__right-block{
      max-width: 100%;
  width: unset;
    padding: 24px;
    border-radius: 24px;
}

.text-block__title{
  font-size: 18px;
  line-height: 23px;


}

.text-block__discription{
  font-size: 14px;
  line-height: 21px;
}


.certificate-asap__bottom-text{
  font-size: 12px;
  line-height: 16px;
}


.certificate-asap__img{
  width: 180px;
  height: 150px;
}



}



</style>
