<template>
  <router-link :to="link" >
    <div class="card-content prof-card" :class="{ pink, blue }" @mouseover="mouseOverHandler" @mouseleave="mouseLeaveHandler">
      <div class="text-section">
        <h2>{{ title }}</h2>
        <p :style="textStyle">{{ price }}₽/мес</p>
        <div class="details">
          <span v-for="detail in details" :key="detail" class="detail">{{ detail }}</span>
        </div>
      </div>
      <div class="image-section">
        <div
          class="image"
          :style="{
            backgroundImage: `url(${image})`,
            filter: hover ? 'none' : imageStyle.filter
          }"
        ></div></div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    title: String,
    price: String,
    image: String,
    details: Array,
    link: String,
    pink: Boolean,
    blue: Boolean,
    imageStyle: Object,
    columnColor: String,

  },
  data() {
    return {
      hover: false,
    };

  },

    computed: {
    textStyle() {
      // Динамически меняем цвет текста в зависимости от колонки
      return {
        color: this.columnColor === 'pink' ? 'rgba(224, 58, 123, 1)' : 'rgba(40, 49, 239, 1)', // розовый для pink, синий для blue
      };
    },
  },
  methods: {

    mouseOverHandler() {
      this.hover = true
    },
    mouseLeaveHandler() {
      this.hover = false
    }
    
  },


};
</script>

<style>
.prof-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  border-radius: 32px;
  transition: background-color 0.3s;
  overflow: hidden;
  
}

.card-content{
    display: flex;
    justify-content: space-between;
    width: 100%;
    
}




.prof-card.pink {
  background-color: rgba(255, 255, 255, 1);
}
.prof-card.blue {
  background-color: rgba(255, 255, 255, 1);
}

.prof-card.blue:hover  {
  background-color: rgba(190, 206, 255, 1);
}

.prof-card.pink:hover  {
  background-color: rgba(252, 201, 221, 1);
}


.text-section {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 0 32px 32px;
}
.text-section h2 {
  
  margin: 0;
  color: rgba(24, 8, 14, 1);
  font-weight: 600;
  font-size: 28px;
   text-transform: uppercase;
}
.text-section p{
    margin: 0;
    padding-top: 16px;
    padding-bottom: 20px;
font-weight: 600;
font-size: 40px;

}



.details {
  display: flex;
  gap: 12px;
  
  
}


.card-content.details:active {
color:rgba(24, 8, 14, 1) ;
  
}

.detail {
  background-color: rgba(240, 244, 249, 1);
  border-radius: 80px;
  padding: 12px 20px;
  font-weight: 400;
  font-size: 20px;
  
}

a, .prof-card, .prof-card * {
  text-decoration: none;
  color: inherit; 
}



.image-section{
  
  height: 234px;
  transition: filter 0.3s;
  position: relative;
  z-index: 1;
}



.image-section .image {
  width: 300px;
  height: 100%;
  background-repeat: no-repeat;
  right: 0;
  position: absolute;

  
}

.prof-card:hover .image{
  filter: none;
}



@media (max-width: 480px) {
.prof-card {
  width: 100%;
  max-height: 140px;
  border-radius: 24px;
  display: flex;
  position: relative;
  align-items: stretch;
}

 .text-section {
    padding: 24px; /* Убираем внутренние отступы */
    width: 100%; /* Занимаем всю ширину */
    /* Занимаем всю высоту */
    box-sizing: border-box;
    z-index: 2;
    display: flex; /* Flex для выравнивания контента */
    flex-direction: column; /* Сохраняем вертикальную ориентацию */
    justify-content: space-between; /* Равномерное распределение */
  }

.text-section h2 {
font-size: 18px;
white-space: nowrap;


}

.text-section p {
font-size: 14px;
padding: 16px 0;

}

.details{

  gap: 4px;
}

.detail{
  font-size: 12px;
  white-space: nowrap;
  padding: 4px 8px;
}

.image-section{
      width: 130px;
    height: 100px; 
    bottom: 0;
  right: 0;
  margin-right: -100px;
  margin-top: 40px;
}



.image {
  
  z-index: 1;

    background-size: contain; 
    background-position: center;
    
}






}

</style>