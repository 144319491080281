<template>
  <div class="form">
    <div v-if="!isSubmitted">
    <h3 class="form-title">Попробуй себя в профессии бесплатно</h3>
    <p class="form-discript">
      Запишись на занятие с ментором и почувствуй себя UX/UI дизайнером.
    </p>
    <form @submit.prevent="submitForm">
      <input 
        class="name" 
        type="text" 
        placeholder="Имя и фамилия" 
        :value="name"
        @input="validateInput"
        @blur="handleNameBlur"
         :class="{'input-error': nameError}" 
        />
      <input 
      class="namber" 
      type="tel"
      placeholder="+7 (___) ___-__-__"
      :value="phone"
      @focus="handlePhoneFocus"
      @input="validatePhoneInput"
      @blur="handlePhoneBlur"
      :class="{'input-error': phoneError}" 
      />

        <div class="form-group">
    <label for="policy" class="checkbox">
      <input 
        type="checkbox" 
        id="policy" 
        class="checkbox-input" 
        v-model="isPolicyChecked"
        
      />
      <span class="checkbox-custom" :class="{'checkbox-error': policyError}"></span>
      Я ознакомлен с политикой конфиденциальности
    </label>
  </div>
      <button type="submit" class="btn primary" @click="submitForm">Записаться</button>
    </form>
    </div>
        <div v-else class="submitted-message">
          <div class="message-text">
            <p class="message-text__title">Ваша заявка отправлена!</p>
            <p class="message-text__discript">Наши специалисты свяжутся с вами, в ближайшее возможное время.</p>
          </div>

      <img src="../../../assets/advantagesPng/Ok.png" alt="Image" class="submitted-image"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormSection",
  data() {
    return {
    name: "",
    phone: "",
    nameError: false, // Ошибка для поля имени
      phoneError: false, // Ошибка для поля телефона
      isPolicyChecked: false, // Чекбокс для политики конфиденциальности
      policyError: false, // Ошибка для чекбокса
    isSubmitted: false, // Флаг для отображения сообщения после отправки
    };
  },
  methods: {
    validateInput(event) {
      // Получаем текущее значение из события
      const input = event.target.value;
      // Фильтруем символы, оставляя только кириллицу, пробелы, тире и апостроф
      const filtered = input.replace(/[^А-Яа-яЁё\s\-']/g, "");
      // Обновляем значение
      this.name = filtered;
      this.nameError = this.name === ""; // Если имя пустое, ошибка
      // Программно изменяем значение input
      event.target.value = filtered;
    },
        // Обработчик потери фокуса для имени
    handleNameBlur() {
      this.nameError = this.name === ""; // Обновляем ошибку при потере фокуса
    },
    handlePhoneFocus() {
      if (this.phone === "") {
        this.phone = "+7 ";
      }
    },
    // Валидация телефона
    validatePhoneInput(event) {
      let input = event.target.value;

      // Удаляем всё, кроме цифр
      input = input.replace(/[^0-9]/g, "");

      // Убираем лишние цифры, ограничивая до 11 символов (1 для "+7" и 10 для номера)
      input = input.substring(0, 11);

      // Формируем вывод с "+7 " и оставшимися цифрами
      if (input.startsWith("7")) {
        this.phone = `+${input}`;
      } else {
        this.phone = `+7${input.slice(1)}`;
      }
      this.phone = input;
      this.phoneError = this.phone.length < 11; // Если меньше 11 цифр, ошибка
      
      event.target.value = `+7 ${this.phone}`;
      // Программно обновляем поле
      event.target.value = this.phone;
    },
    // Обработчик потери фокуса для телефона
    handlePhoneBlur() {
      if (this.phone === "+7 " || this.phone === "+7") {
        this.phone = "";
      }
       this.phoneError = this.phone.length < 11;
    },
        // Метод отправки формы
    submitForm(event) {
      event.preventDefault();
            this.nameError = this.name === "";
      this.phoneError = this.phone.length < 11;
      this.policyError = !this.isPolicyChecked; // Проверка на чекбокс
      if (this.name && this.phone.length === 11 && this.isPolicyChecked) {
        // Пример вывода в консоль (вместо этого можно отправить на сервер)
        console.log("Имя:", this.name);
        console.log("Телефон:", this.phone);
        // axios.post('/some-endpoint', { name: this.name, phone: this.phone });
        this.isSubmitted = true;
      }
    }
  },
};
</script>

<style scoped>


.submitted-message {

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
    align-items: center;
}

.message-text{
  gap: 16px;
  padding-bottom: 64px;
  padding-top: 40px;
}

.message-text__title{
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  text-align: left;
  margin: 0;
}

.message-text__discript{
    font-size: 24px;
  line-height: 36px;
  text-align: left;
  margin: 0;

}

.submitted-image {
  width: 340px;
  height: 340px;

}


.input-error {
  border-color: red !important; /* Красная граница при ошибке */
  background-color: #fdd; /* Легкий красный фон при ошибке */
}

.checkbox-error {
  border-color: red !important;
  background-color: #fdd;
}

.btn {

  font-size: 20px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  padding: 20px 30px;
  border-radius: 16px; /* Округление углов */
  cursor: pointer;
  transition: all 0.3s ease;
}

.form {
    padding: 80px;
    padding-right: 100px;
  flex: 1;
  background: #fff;
  border-radius: 32px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
 

}

.form-title {

  font-size: 40px;
  font-weight: 600;
  color: rgba(24, 8, 14, 1);

}

.form-discript {

  font-size: 24px;
  font-weight: 400;
  color: rgba(106, 106, 106, 1);
  padding-bottom: 64px;

}



.name{
  font-size: 24px;
    width: 100%;
    box-sizing: border-box;
  margin-bottom: 24px; 
  border: 2px solid rgba(154, 154, 154, 1); 
  border-radius: 16px; 
  line-height: 1.5;
  padding: 24px;
}

.name:hover {
  border-color: rgba(168, 194, 251, 1);
}

/* Фокус эффект */
.name:focus {
  border-color: rgba(168, 194, 251, 1);
  background-color: rgba(240, 244, 249, 1); /* Внутренний фон при фокусе */
  outline: none; /* Убираем стандартный outline */
}




.namber{
    width: 100%;
    box-sizing: border-box;
  margin-bottom: 24px; 
  border: 2px solid rgba(154, 154, 154, 1); 
  border-radius: 16px; 

  font-size: 24px; 
  line-height: 1.5;
  padding: 24px;
}


.namber:hover {
  border-color: rgba(168, 194, 251, 1);
}

/* Фокус эффект */
.namber:focus {
  border-color: rgba(168, 194, 251, 1);
  background-color: rgba(240, 244, 249, 1); /* Внутренний фон при фокусе */
  outline: none; /* Убираем стандартный outline */
}







.comment{
    width: 100%;
   box-sizing: border-box;
  margin-bottom: 24px; 
  border: 2px solid rgba(154, 154, 154, 1); 
  border-radius: 16px;

  font-size: 18px;
  line-height: 1.5;
  padding: 24px;
}



.comment:hover {
  border-color: rgba(202, 24, 93, 1);
}

/* Фокус эффект */
.comment:focus {
  border-color: rgba(168, 194, 251, 1);
  background-color: rgba(255, 213, 238, 1); /* Внутренний фон при фокусе */
  outline: none; /* Убираем стандартный outline */
}

/* Активное состояние */
.comment:active {
  border-color: rgba(140, 8, 60, 1);
}


.checkbox-input {
    
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Создаём кастомный чекбокс */
.checkbox-custom {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(24, 8, 14, 1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transition: all 0.3s ease;
  position: relative;
}



/* Галочка внутри чекбокса */
.checkbox-custom::after {
  content: '✔'; /* Символ галочки */
  font-size: 14px;
  color: transparent; /* Прозрачная галочка по умолчанию */
  transition: all 0.3s ease;
}

/* Состояние checked для чекбокса */
.checkbox-input:checked + .checkbox-custom {
  background-color: rgba(224, 58, 123, 1);
  border-color: rgba(224, 58, 123, 1);
}

/* Галочка становится видимой при checked */
.checkbox-input:checked + .checkbox-custom::after {
  color: #fff; /* Белая галочка на фоне */
}






/* Текст рядом с чекбоксом */
.checkbox {
    padding: 34px 0 34px 0;
  display: flex;
  
  align-items: center;

  font-size: 20px;
  color: rgba(24, 8, 14, 1);
}

.checkbox-custom:hover {
  color: rgba(202, 24, 93, 1);
}




@media (max-width: 480px) {
  .form {
    padding: 32px 24px; /* Уменьшаем паддинги */
  }

  .form h3 {
    font-size: 24px; /* Уменьшаем размер текста заголовка */
  }

  .form p {
    font-size: 14px; /* Уменьшаем размер текста описания */
    padding-bottom: 32px;
    margin: 0; /* Уменьшаем отступ снизу */
  }

  .name,
  .namber {
    font-size: 14px; /* Уменьшаем размер шрифта в полях ввода */
    padding: 16px; /* Уменьшаем внутренние отступы */
  }

  .checkbox {
    font-size: 12px; /* Уменьшаем размер текста рядом с чекбоксом */
  }

  .btn {
    font-size: 14px; /* Уменьшаем размер шрифта на кнопке */
    padding: 12px 24px; /* Уменьшаем паддинги на кнопке */
  }

  .checkbox-input {
    width: 16px; /* Уменьшаем размер чекбокса */
    height: 16px; /* Уменьшаем размер чекбокса */
  }

  .checkbox-custom {
    width: 16px; /* Уменьшаем размер кастомного чекбокса */
    height: 16px; /* Уменьшаем размер кастомного чекбокса */
  }


.submitted-message {

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
    align-items: center;
}

.message-text{
  gap: 16px;
  padding-bottom: 32px;
  padding-top: 0px;
}

.message-text__title{
  font-size: 24px !important;
  line-height: 31px;
  text-align: left;
  margin: 0;
}

.message-text__discript{
    font-size: 14px;
  line-height: 21px;
  text-align: left;
  margin: 0;

}

.submitted-image {
  width: 140px;
  height: 140px;

}




}



</style>