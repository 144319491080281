<template>
  <div class="articles-section">
    <h2>Полезная информация для профессии</h2>
    <div class="articles-container">
      <ArticlesList :articles="articles" :currentProfession="currentProfession" />
      <ArticleBlog/>
    </div>
    
  </div>
</template>

<script>
import ArticlesList from "./ArticlesList.vue";
import ArticleBlog from "./ArticleBlog.vue";

export default {
  name: "ArticlesSection",
  components: { ArticlesList,ArticleBlog },
  data() {
    return {
      articles: [
        {
          id: 1,
          title: "Чем отличается графический дизайн и UX/UI дизайн",
          date: "22.03.2025",
          description: "Использование компонентов в Figma выведет ваш рабочий процесс на новый уровень. В этой статье я объясню, что такое компоненты, как они работают, и дам ряд рекомендаций по их использованию. Приступим. Компоненты – это элементы интерфейса, которые можно повторно использовать в наших дизайн-проектах. Компонентом может быть кнопка, иконка или что-то сложное, например, таблица данных.",
          image: require('@/assets/ArticlesSectionPng/prof1.png'),
          profession: "designer",
        },
        {
          id: 2,
          title: "Мыслить креативами — талант ли это?",
          date: "12.03.2025",
          description: "Обмен опытом в комьюнити",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "designer",
            },
        {
          id: 3,
          title: "Мыслить креативами — талант ли это?",
          date: "03.03.2025",
          description: "Рассказиваем, как сделать прототип интерфейса ",
          image: require('@/assets/ArticlesSectionPng/prof2.png'),
          profession: "designer",
        },
      ],
      currentProfession: "designer",
    };
  },
};
</script>

<style scoped>
.articles-section {
  padding-top: 200px;
}

.articles-section h2 {
    font-weight: 700;
    font-size: 80px;
    margin: 0;
    padding-bottom: 64px;
}

.articles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 100px;
}



@media (max-width: 480px) {
.articles-section {
  padding-top: 80px;
}
.articles-section h2{
  font-size: 32px;
  padding-bottom: 32px;
}
.articles-container{
  flex-direction: column;
  padding-bottom: 0;
}
}

</style>