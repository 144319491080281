<template>
  <div class="withfriendscontainer">
    <h1 class="withfriendstitle">С друзьями выгоднее</h1>
    <div class="withfriendsblocks">
      <div class="withfriendsleftblock">
        <div class="withfriendsleftblockupperblock">
          <div class="invitefriend">
            <p>ПОЗОВИ ДРУГА</p>
            <img src="@/assets/WithFriends/oneandthirdline.svg" alt="Group 1" />
          </div>
          <div class="makemoney">
            <img src="@/assets/WithFriends/secondline.svg" alt="Group 2" />
            <p>И ПОЛУЧИ ЗА КАЖДОГО</p>
          </div>
          <div class="fivek">
            <p>5 000 ₽ </p>
            <img src="@/assets/WithFriends/oneandthirdline.svg" alt="Group 1" />
          </div>
        </div>
        <div class="withfriendsleftblocklowerbutton">
          <img
            class="ornamentimg"
            src="@/assets/WithFriends/Ornament.svg"
            alt="Ornament"
          />
          <button>
            <div class="textblock">
              <p>
                <span class="firstword">ПОЛУЧИТЬ</span><br>
                ОБРАТНЫЙ ЗВОНОК
              </p>
            </div>
            <div class="arrowblock">
              <img src="@/assets/WithFriends/Frame 9510.svg" alt="Frame9510" />
            </div>
          </button>
        </div>
      </div>
      <div class="withfriendsrightblock">
        <img src="@/assets/WithFriends/Friends.svg" alt="Friends" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.withfriendscontainer {
  padding-top: 200px;
  width: 100%;

  .withfriendstitle {
    font-size: 80px;
    font-weight: 700;
    line-height: 88px;
    color: rgba(24, 8, 14, 1);
  }

  .withfriendsblocks {
    display: flex;
    justify-content: space-between;

    .withfriendsleftblock {
      background-color: #fff;
      border-radius: 32px;
      max-width: 100%;

      .withfriendsleftblockupperblock {
        display: flex;
        flex-direction: column;
        padding-left: 48px;
        padding-right: 48px;

        .invitefriend {
          display: flex;
          align-items: center;
          padding-top: 48px;

          p {
            font-size: 38px;
            font-weight: 600;
            line-height: 49.4px;
            color: rgba(40, 49, 239, 1);
            margin: 0;
          }
          img {
            margin-left: 24px;
          }
        }

        .makemoney {
          display: flex;
          align-items: center;
          padding-top: 16px;
          padding-bottom: 16px;

          p {
            font-size: 38px;
            font-weight: 600;
            line-height: 49.4px;
            color: rgba(40, 49, 239, 1);
            margin: 0;
          }
          img {
            margin-right: 24px;
          }
        }

        .fivek {
          display: flex;
          align-items: center;
          padding-bottom: 142px;

          p {
            font-size: 80px;
            font-size: clamp(2rem, 5vw, 5rem);
            white-space: nowrap;
            font-weight: 700;
            line-height: 88px;
            color: rgba(224, 58, 123, 1);
            margin: 0;
          }
          img {
            margin-left: 24px;
          }
        }
      }

      .withfriendsleftblocklowerbutton {
        display: flex;
        justify-content: center;
        padding-bottom: 48px;
        position: relative;

        .ornamentimg {
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 1;
          opacity: 0.9;
          border-radius: 0 0 32px 0;
        }

        button {
          width: 83%;
          background-color: rgba(40, 49, 239, 1);
          border: none;
          border-radius: 16px;
          cursor: pointer;

          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;

          position: relative;
          z-index: 2;

          .textblock {
            margin-top: 109px;
            margin-bottom: 24px;
            margin-left: 24px;

            p {
              margin: 0;
              font-size: 32px;
              line-height: 41.6px;
              color: #fff;

              .firstword {
                display: inline-block;
                margin-left: -155px;
              }
            }
          }

          .arrowblock {
            margin-top: 24px;
            margin-right: 24px;

            img {
              width: 72px;
              height: auto;
              display: block;
            }
          }
        }
        & button:hover {
          background-color: rgba(10, 19, 213, 1);
        }
      }
    }
    .withfriendsrightblock {
      padding-left: 20px;

        img {
              max-width: 100%; /* Ограничим максимальную ширину */
              height: 100%; /* Сохраняем пропорции изображения */
              object-fit: cover; 
              border-radius: 32px;
      overflow: hidden;
      }
    }
  }
}




@media (max-width: 480px) {
  .withfriendscontainer{
    padding-top: 80px;
  }

.withfriendsblocks{
  flex-direction: column;
  
}

.withfriendsleftblock{
  width: 100% !important;
  box-sizing: border-box;
  overflow: hidden !important;
}


.withfriendsleftblockupperblock{
  margin-left: 0px !important;
  padding: 0 !important;
}

.invitefriend {
  padding: 0 24px ;
}

.invitefriend img {
  width: 100%; /* Картинка будет растягиваться на всю ширину контейнера */
  max-width: 100%; /* Ограничиваем максимальную ширину картинки */
  height: 4px; /* Сохраняем пропорции картинки */
  object-fit: cover; /* Обрезаем лишнее содержимое, чтобы вписаться в контейнер */
  overflow: hidden; /* Скрываем обрезанную часть изображения */
  box-sizing: border-box;
  
}

.invitefriend p {
  font-size: 18px !important;
  white-space: nowrap;
  line-height: 23px !important;
}
.makemoney{
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding: 0 24px  ;
}

.makemoney img {
  width: 100%; /* Картинка будет растягиваться на всю ширину контейнера */
  max-width: 100%; /* Ограничиваем максимальную ширину картинки */
  height: 3px; /* Сохраняем пропорции картинки */
  object-fit: cover; /* Обрезаем лишнее содержимое, чтобы вписаться в контейнер */
  overflow: hidden; /* Скрываем обрезанную часть изображения */
  box-sizing: border-box;
}

.makemoney p {
font-size: 18px !important;
white-space: nowrap;
  line-height: 23px !important;
}

.fivek{
  padding: 0 24px ;
}


.fivek img{
  width: 100%; /* Картинка будет растягиваться на всю ширину контейнера */
  max-width: 100%; /* Ограничиваем максимальную ширину картинки */
  height: 4px; /* Сохраняем пропорции картинки */
  object-fit: cover; /* Обрезаем лишнее содержимое, чтобы вписаться в контейнер */
  overflow: hidden; /* Скрываем обрезанную часть изображения */
  box-sizing: border-box;
     position: relative;
   z-index: 6;
  
}

.fivek p{
 font-size: 32px !important;
   line-height: 41px !important;
   position: relative;
   z-index: 6;
}


.withfriendstitle{
font-size: 32px !important;
margin: 0 !important;
line-height: 41px !important;
padding-bottom: 32px !important;
}



.withfriendsrightblock{
  padding-left: 0 !important;
}

.withfriendsrightblock img {
  width: 100%;
  height: 100%;
  padding-top: 8px;
}

.textblock{
  margin-top: 0px !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}

.textblock p{
  font-size: 18px !important;
  line-height: 23px !important;
  padding-top: 22px !important;
  text-align: left !important;
  padding-left: 12px !important;
  padding-bottom: 12px !important;

}

.firstword{
  margin-left: 0 !important;
  word-wrap: break-word;
}

.ornamentimg{
  margin-bottom: -68px;
  margin-right: -82px;
  transform: scale(0.68);
  padding-right: 8px !important;
  padding-top: 8px !important;
}

.arrowblock{
    padding-right: 8px !important;
  padding-top: 8px !important;
  margin-top: 0 !important;
  margin-right: 0 !important;
}
.arrowblock img{
  width: 30px  !important;
}



}
</style>
