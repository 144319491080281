<template>
  <router-link :to="link" >
    <div class="card-contentMain prof-cardMain" :class="{ pink, blue }" @mouseover="mouseOverHandler" @mouseleave="mouseLeaveHandler">
      <div class="text-sectionMain">
        <div class="section-cardMain">
            <div>
                <h2>{{ title }}</h2> 
        </div>
            <div class="description-cardMain">{{ description }}</div>
            <div class="price-iconcard">
              <p :style="textStyle">{{ price }}₽/мес</p>
              <div class="iconcard" @mouseover="showInfoBlock" @mouseleave="hideInfoBlock">
                <img 
                :src="iconSrc" 
                alt="icon"
                class="price-icon"
              /><InfoBlock v-show="isInfoBlockVisible" :color="columnColor"/>
            </div> 
            </div>
            
            <div class="full-price">{{ fullPrice }}</div>
        </div>
        
        <div class="detailsMain">
          <span v-for="detail in details" :key="detail" class="detailMain">{{ detail }}</span>
        </div>
      </div>
      <div class="image-sectionMain">
        <div
          class="imageMain"
          :style="{
            backgroundImage: `url(${image})`,
            filter: hover ? 'none' : imageStyle.filter
          }"
        ></div></div>
        
    </div>
  </router-link>
</template>

<script>
import blueIcons from '@/assets/icon/blue-icon.svg'; // Синяя иконка
import pinkIcons from '@/assets/icon/pink-icon.svg'; // Розовая иконка
import InfoBlock from './InfoBlock.vue';

export default {
  props: {
    title: String,
    price: String,
    fullPrice: String,
    description: String,
    image: String,
    details: Array,
    link: String,
    pink: Boolean,
    blue: Boolean,
    imageStyle: Object,
    columnColor: String,

  },
    components: {
    InfoBlock, // Регистрируем компонент InfoBlock
  },
  data() {
    return {
      hover: false,
      isInfoBlockVisible: false,
    };

  },

    computed: {
    textStyle() {
      // Динамически меняем цвет текста в зависимости от колонки
      return {
        color: this.columnColor === 'pink' ? 'rgba(224, 58, 123, 1)' : 'rgba(40, 49, 239, 1)', // розовый для pink, синий для blue
      };
      },
        iconSrc() {
      // Возвращаем ссылку на иконку в зависимости от цвета карточки
      return this.columnColor === 'pink' ? pinkIcons : blueIcons;
    }
  },
  methods: {

    mouseOverHandler() {
      this.hover = true
    },
    mouseLeaveHandler() {
      this.hover = false
    },
        showInfoBlock() {
      this.isInfoBlockVisible = true; // Показываем InfoBlock при наведении на иконку
    },
    hideInfoBlock() {
      this.isInfoBlockVisible = false; // Скрываем InfoBlock при уходе мыши с иконки
    }

  },


};
</script>

<style>
.prof-cardMain {
  display: flex;
  justify-content: space-between;
  align-items: start;
  text-decoration: none;
  border-radius: 32px;
  transition: background-color 0.3s;
  overflow: hidden;
  position: relative; /* Добавляем relative, чтобы дочерний элемент с position: absolute располагался относительно родителя */
  z-index: 1;
  
}

.card-contentMain {
  display: flex;
  justify-content: space-between;
  align-items: start;
  text-decoration: none;
  border-radius: 32px;
  transition: background-color 0.3s;
  overflow: hidden;
  position: relative;
}

.section-cardMain{
    display: flex;
    flex-direction: column;
    padding: 32px;
}




.prof-cardMain.pink {
  background-color: rgba(255, 255, 255, 1);
}
.prof-cardMain.blue {
  background-color: rgba(255, 255, 255, 1);
}

.prof-cardMain.blue:hover  {
  background-color: rgba(190, 206, 255, 1);
}

.prof-cardMain.pink:hover  {
  background-color: rgba(252, 201, 221, 1);
}


.text-sectionMain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative; /* Чтобы слои работали */
    z-index: 2;

}
.text-sectionMain h2 {
  
  margin: 0;
  color: rgba(24, 8, 14, 1);
  font-weight: 600;
  font-size: 28px;
   text-transform: uppercase;
}
.description-cardMain{
    padding-top: 16px;
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 20px;
    color: rgba(106, 106, 106, 1)!important;
}

.text-sectionMain p{
margin: 0; 
padding-bottom: 4px;
font-weight: 600;
font-size: 28px;

}

.full-price{
    text-align: center;
    max-width: 380px;
    background-color: rgba(240, 244, 249, 1);
    font-weight: 400;
    font-size: 20px;
    color: rgba(106, 106, 106, 1)!important;
}

.detailsMain {
  display: flex;
  gap: 12px;
  padding: 0 32px 32px; 
  
}


.card-contentMain.detailsMain:active {
color:rgba(24, 8, 14, 1) ;
  
}

.detailMain {
  background-color: rgba(240, 244, 249, 1);
  border-radius: 80px;
  padding: 12px 20px;
  font-weight: 400;
  font-size: 20px;
  
}

a, .prof-cardMain, .prof-cardMain * {
  text-decoration: none;
  color: inherit; 
}



.image-sectionMain{
    width: 355px;
  height: 275px;
  transition: filter 0.3s;
  position: absolute;

  bottom: 0; 
  right: 0;
    z-index: 1; /* Картинка ниже текста */
    overflow: hidden;
}



.image-sectionMain .imageMain {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: filter 0.3s;

  
}

.prof-cardMain:hover .imageMain{
  filter: none;
}

.price-iconcard{
  display: flex;
  gap: 16px;
  align-items: center;
}



.iconcard{
  position: relative;
  max-width: 28px;
  max-height: 28px;
  display: flex;
  z-index: 2;
}



@media (max-width: 480px) {

  .text-sectionMain{

  }


  .section-cardMain{
    padding: 24px;
  }

.text-sectionMain h2{
 font-size: 18px;
 line-height: 23px;
}

.description-cardMain{
 font-size: 12px;
 line-height: 16px;
 padding-top: 8px;
}

.text-sectionMain p {
 font-size: 14px;
 line-height: 14px;
}

.full-price{
 font-size: 12px;
 line-height: 16px;
 max-width: 215px;
}

.detailsMain{
  gap: 4px;
  padding: 0 24px 24px 24px;
}

.detailMain{
 font-size: 12px;
 line-height: 16px;
 padding: 4px 8px;
}

.price-iconcard{
  gap: 12px;
}

.image-sectionMain{
  width: 185px;
  height: 145px;
}

.iconcard{
  padding-bottom: 5px;
}

}





</style>