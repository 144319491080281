<template>
  <div class="future">
    <div class="future-container">
        <h1 class="title">Что вас ждет</h1>
        <p class="description">
      Вы изучите все необходимые инструменты для UX/UI дизайнера и будете учавствовать в реальном проекте. Вас и нескольких учеников других профессий с потока определят в реальный проект, в ходе которого вы разработаете продукт для реального заказчика.
    </p></div>
    
    <div class="topics">

            <p class="topic">UX-исследования</p>
            <p class="topic">Figma</p>
            <p class="topic">Композиция</p>
            <p class="topic">Дизайн-концепция</p>
            <p class="topic">Мобильные приложения</p>
            <p class="topic">Jobs to be Done</p>
            <p class="topic">User Flow</p>
            <p class="topic">Usability</p>
            <p class="topic">Прототипирование</p>

      
    </div>
    <div class="topic-bg"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.future {
gap: 16px;
        padding-top: 200px;
display: flex;
}

.future-container{
 flex: 1;   
 background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 32px;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.title {
    padding-bottom: 16px;
  font-size: 40px;
  font-weight: 600;  
  text-align: left;
  margin: 0;
}

.description {
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    text-align: left;
}

.topics {
  flex: 1;
  border: 2px solid rgba(252, 201, 221, 1);
  border-radius: 32px;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;

}

.topic {
    margin: 4px;
    background-color: rgba(224, 58, 123, 1);
  font-size: 28px;
  color: #fff;
    font-weight: 600;
    border-radius: 32px;
    padding: 10px 20px;
    text-transform: uppercase;
     white-space: nowrap;
    position: relative;
    z-index: 2;
      display: inline-flex
      
}

.topic-bg{
    background-image: url(@/assets/futurePng/Vector.png);
    width: 195px;
    height: 110px;
    margin-left: -210px;
}

.topic.break {

}



@media (max-width: 480px) {
  /* Общий контейнер для всего блока */
  .future {
    flex-direction: column; /* Располагаем блоки в колонку */
    padding-top: 80px; /* Уменьшаем отступ сверху */
  }

  /* Контейнер с заголовком и описанием */
  .future-container {
    width: 100%; /* Делаем контейнер шириной на весь экран */
    padding: 24px;
    box-sizing: border-box; /* Уменьшаем паддинги */
  }

  /* Заголовок */
  .title {
    font-size: 24px; /* Уменьшаем размер шрифта */
    padding-bottom: 16px; /* Уменьшаем отступ снизу */
    text-align: left;
    margin: 0; /* Центрируем текст */
  }

  /* Описание */
  .description {
    font-size: 14px; /* Уменьшаем размер шрифта */
    text-align: left; /* Центрируем текст */
    margin: 0; /* Добавляем паддинги */
  }

  /* Блок с темами */
  .topics {
    width: 100%; 
    padding: 24px;
    border: 1px solid rgba(252, 201, 221, 1);
    box-sizing: border-box;
    
  }



  .topic {
    font-size: 16px; /* Уменьшаем размер шрифта */
    font-weight: 600;
    padding: 8px 16px; /* Уменьшаем паддинги */
    background-color: rgba(224, 58, 123, 1);
    border-radius: 16px; /* Скругляем углы */
    text-transform: uppercase;
   
    
  }

  /* Бэкграунд для фона */
  .topic-bg {

  }
}



</style>