<template>
  <div class="contain"><h3 class="prof-title">Профессии</h3>
  <div class="prof-contain">
    <div class="column blue-column">
      <ProfCard
        v-for="(profession, index) in blueProfessions"
        :key="index"
        :title="profession.title"
        :price="profession.price"
        :image="profession.image"
        :details="profession.details"
        :link="profession.link"
        :imageStyle="blueImageStyle"
        blue
        :columnColor="'blue'"
        @click="navigate(profession)"
        
      />
    </div>
    <div class="column pink-column">
      <ProfCard
        v-for="(profession, index) in pinkProfessions"
        :key="index"
        :title="profession.title"
        :price="profession.price"
        :image="profession.image"
        :details="profession.details"
        :link="profession.link"
        :imageStyle="pinkImageStyle"
        pink
        :columnColor="'pink'"
        @click="navigate(profession)"
      />
    </div>
  </div>
  </div>
</template>

<script>
import ProfCard from './ProfCard.vue';

export default {
  components: { ProfCard },
  data() {
    return {
      professions: [
        {
          title: 'Frontend-разработчик',
          price: 'От 5 200 ',
          image: require('@/assets/ProfPng/Front_blue.png'),
          details: ['С нуля', '4 места', '5 месяцев'],
          link: '/frontend',
          color: 'blue',
        },
        {
          title: 'Тестировщик',
          price: 'От 4 800 ',
          image: require('@/assets/ProfPng/QA_blue.png'),
          details: ['С опытом', '0 мест', '5 месяцев'],
          link: '/frontend',
          color: 'blue',
        },
        {
          title: 'Backend-разработчик',
          price: 'От 4 800 ',
          image: require('@/assets/ProfPng/Back_blue.png'),
          details: ['С опытом', '1 место', '5 месяцев'],
          link: '/backend',
          color: 'blue',
        },
        {
          title: 'UX/UI дизайнер',
          price: 'От 5 200 ',
          image: require('@/assets/ProfPng/UX-UI_Pink.png'),
          details: ['С нуля', '1 место', '5 месяцев'],
          link: '/professions',
          color: 'pink',
        },

        {
          title: 'Системный аналитик',
          price: 'От 4 800 ',
          image: require('@/assets/ProfPng/SA_Pink.png'),
          details: ['С нуля', '6 мест', '5 месяцев'],
          link: '/sa',
          color: 'pink',
        },
        {
          title: 'Менеджер IT-проектов',
          price: 'От 4 800 ',
          image: require('@/assets/ProfPng/PM_pink.png'),
          details: ['С нуля', '3 места', '5 месяцев'],
          link: '/pm',
          color: 'pink',
        },

       
        
        ],
      hiddenProfession: null,
    };
  },
  computed: {
    // Фильтрация в зависимости от текущего пути
    filteredBlueProfessions() {
      if (this.shouldHideProfession()) {
        return this.blueProfessions.filter(prof => prof !== this.hiddenProfession);
      }
      return this.blueProfessions;
    },
    filteredPinkProfessions() {
      if (this.shouldHideProfession()) {
        return this.pinkProfessions.filter(prof => prof !== this.hiddenProfession);
      }
      return this.pinkProfessions;
    },
    blueProfessions() {
      return this.professions.filter(prof => prof.color === 'blue');
    },
    pinkProfessions() {
      return this.professions.filter(prof => prof.color === 'pink');
    },
  blueImageStyle() {
    return {
      filter: 'hue-rotate(0deg) brightness(2.5) saturate(0.9)',  // исходный фильтр для голубых картинок
    };
  },
  pinkImageStyle() {
    return {
      filter: 'hue-rotate(16deg) brightness(2.7) saturate(1.5) contrast(0.9)',  // исходный фильтр для розовых картинок
    };
  },
  },
  methods: {
    navigate(profession) {
      // Скрыть выбранную профессию только если мы переходим на ее страницу
      if (this.isProfessionPage(profession.link)) {
        this.hiddenProfession = profession;
      } else {
        this.hiddenProfession = null;
      }
      this.$router.push(profession.link);
    },
    shouldHideProfession() {
      // Определить, нужно ли скрывать профессию, основываясь на текущем пути
      return this.hiddenProfession && this.isProfessionPage(this.$route.path);
    },
    isProfessionPage(path) {
      // Проверить, являемся ли мы на странице профессии
      return path.includes('/blog') || path.includes('/professions'); // добавьте другие пути по необходимости
    }
  }
};
</script>

<style>
.contain{
  padding-top: 200px;
}
.prof-contain {
  display: flex;
   padding-top: 85px;

  
  gap: 16px; 
}

.prof-title {
  margin: 0;
  font-weight: 700;
  font-size: 80px;
  color: rgba(24, 8, 14, 1);
}

.column {
  display: flex;
  flex-direction: column;
  gap: 16px; 
  
}


.blue-column {
 flex: 1;
 
}

.blue-column img {
  width: 350px;
  height: 234px;
  transition: filter 0.3s;
  
}



.pink-column {
  flex: 1;
   
}


.pink-column img {
  width: 350px;
  height: 234px;
  transition: filter 0.3s;
}


@media (max-width: 480px) {

  .contain{
  padding-top: 80px;
}

.prof-contain {
  flex-direction: column;
  padding-top: 32px;
  
}

.prof-title{
  font-size: 32px;
  
}





}

</style>