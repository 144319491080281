<template>
  <div class="tariff-card">
    <div class="tariff-content">
      <!-- Левая часть -->
      <div class="tariff-left">
        <div>
          <h2>{{ title }}</h2>
          <p class="description">{{ description }}</p>
        </div>

        <div class="price-container">
          <div class="price-item">
            <p class="price" >{{ price }} ₽</p>
            <p class="price-label">Разовая оплата</p>
          </div>
          <div class="price-item">
            <p class="price2">От {{ installments }} ₽/мес</p>
            <p class="price-label">Рассрочка от 3 до 36 мес.</p>
          </div>
        </div>
        <div class="btn-price">
          <button class="btn primary">Оставить заявку</button>
        </div>
      </div>

      <!-- Правая часть с динамическим классом для синего цвета -->
      <div class="tariff-right">
        <h3>Услуги, входящие в тариф</h3>

        <ul class="services">
          <li v-for="(service, index) in services" :key="index">
            <span class="custom-marker">
              <!-- Используем синий SVG для звезд -->
              <img 
                :src="require('@/assets/star/Star.svg')" 
                alt="Marker" 
                width="36" 
                height="36" 
              />
            </span>
            <span class="service-text">{{ service }}</span>
          </li>
        </ul>        <div class="btn-price-mobil">
          <button class="btn primary">Оставить заявку</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    price: Number,
    installments: String,
    services: Array,
    isFull: Boolean,
  },
};
</script>

<style scoped>
/* Основной стиль карточки */
.tariff-card {
  gap: 32px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 32px;
}

/* Контейнер для содержимого */
.tariff-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Левая часть */
.tariff-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  flex: 1;
}

/* Правая часть с рамкой */
.tariff-right {
  display: flex;
  flex-direction: column;
  padding: 56px;
  flex: 1;
  border: 2px solid rgba(245, 162, 194, 1); /* Исходный цвет рамки */
  border-radius: 32px;
}








/* Заголовок и текст */
.tariff-left h2 {
  font-weight: 600;
  font-size: 40px;
  margin: 0;
}

.tariff-left .description {
  font-weight: 400;
  font-size: 24px;
  color: rgba(106, 106, 106, 1);
  margin: 0;
  padding-top: 16px;
}

/* Горизонтальная структура для цены */
.price-container {
  display: flex;
  gap: 40px;
}

.price-item {
  text-align: center;
}

.tariff-left .price {
  padding-top: 40px;
  font-size: 40px;
  font-weight: 600;
  margin: 0;
  text-align: left;
}

.price2 {
  padding-top: 40px;
  font-size: 40px;
  font-weight: 600;
  margin: 0;
  text-align: left;
  color: rgba(224, 58, 123, 1); /* Для второго блока цена с другим цветом */
}

.price-label {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  color: rgba(106, 106, 106, 1);
  margin: 0;
}

.btn-price {
  padding-top: 40px;
}

.btn-price-mobil{
  display: none;
}

.btn {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  padding: 20px 30px;
  border-radius: 16px; /* Округление углов */
  cursor: pointer;
  

}

/* Кнопка с синим фоном */


/* Заголовок услуг */
.tariff-right h3 {
  text-transform: uppercase;
  margin: 0;
  font-weight: 600;
  font-size: 28px;
}

/* Стилизация списка услуг */
.services {
  display: flex;
  flex-direction: column;
  padding: 32px 0 0 0;
  margin: 0;
  gap: 24px;
  position: relative;
}

.services li {
  display: flex;
  align-items: start;
  gap: 8px;
  color: rgba(106, 106, 106, 1);
font-weight: 400;
    font-size: 24px;
  position: relative;
}

/* Синие полоски между элементами списка */
.services li::after {
  content: '';
  position: absolute;
  left: 17.345px; /* Центр маркера */
  top: 18px;
  bottom: -50px; /* Длина линии до следующего элемента */
  width: 1px;
  background-color: rgba(252, 201, 221, 1); /* Синяя линия между элементами */
  z-index: 0;
}

.services li:last-child::after {
  display: none; /* Убираем линию для последнего элемента */
}

.custom-marker {
  flex-shrink: 0;
  position: relative; /* Для позиционирования внутри списка */
  z-index: 1; /* Чтобы маркер был поверх линии */
}

.service-text {
  font-weight: 400;
  font-size: 24px;
  flex-grow: 1;
}



@media (max-width: 480px) {

.tariff-content{
  flex-direction: column;
  gap: 0px;
}

  .tariff-left {
    border-radius: 24px;
    padding: 24px;
    display: flex;
    order: 1; /* Левая часть выше */
  }

  .tariff-right {
    border-radius: 24px;
    padding: 24px;
    display: flex;
    order: 2; /* Правая часть в середине */
  }

  .btn-price{
    display: none;
     
     order: 3;
  }
  .btn{
    font-size: 14px;
    padding: 12px;
    line-height: 32px;
  }

  .btn-price-mobil{
  display: flex;
  padding-top: 32px;
}

.tariff-left h2 {
  font-size: 24px;

}

.description{
  font-size: 14px !important;
  padding: 16px 0;
}

.price-container{
  flex-direction: column;
  gap: 16px;
}


.price-item{
  gap: 4px !important;
}

.price{
  padding: 0 !important;
  font-size: 24px !important;
}

.price2{
  padding: 0 !important;
  font-size: 24px !important;
}

.price-label{
padding: 0 !important;
font-size: 12px !important;
}

.tariff-right h3{
  font-size: 18px;
}

.service-text{
  font-size: 14px;
}

.custom-marker{
  width: 32px;
  height: 32px;
  align-items: center;
}

.custom-marker img {
  width: 100%;
  height: 100%;
}

.services{
  gap: 16px;
}

.services li{
  gap: 12px;
  align-items: center;
}

.services li::after{
  display: none;
}
  
}




</style>
