<template>
  <div class="slider-container">
    <h1 class="slider-title">Как проходит занятие</h1>
    <carousel
      ref="carousel"
      :per-page="isMobile ? 1 : 2"
      :loop="true"
      :autoplay="false"
      :pagination-enabled="false"
      @pageChange="onSlideChange"
    >
      <!-- Слайды -->
      <slide v-for="(slide, index) in slides" :key="index" class="slide-box">
        <div
          class="slide"
          :class="{ 'special-slide': slide.number === '5' }"
        >
          <div class="upper-info-slide">
            <div class="slide-number">{{ slide.number }}</div>
            <h2 class="slide-title">{{ slide.title }}</h2>
          </div>
          <p class="slide-description">{{ slide.description }}</p>
          <div
            v-if="slide.number === '5'"
            class="special-image-container"
          >
            <img
              :src="slide.image"
              alt="Slide Image"
              class="special-image"
            />
          </div>
          <img
            v-else
            :src="slide.image"
            alt="Slide Image"
            class="slide-image"
          />
        </div>
      </slide>
    </carousel>

    <!-- Кастомный pagination -->
    <div class="custom-pagination">
      <span
        v-for="(slide, index) in paginationCount"
        :key="index"
        class="custom-indicator"
        :class="{ active: currentPage === index }"
        @click="goToPage(index)"
      >
        {{ index + 1 }}
        <div class="progress-bar" v-if="currentPage === index">
          <div
            class="progress-line"
            :style="{ width: `${progress}%` }"
          ></div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      slides: [
        {
          number: "1",
          title: "Лекция",
          description:
            "Сначала вы проведете созвон, менторы дадут вам новые знания, которые ты сможешь использовать на практике прямо на созвоне. Затем ты получишь домашнее задание.",
          image: require("@/assets/HowLessonGoessvg/howlessongoes(1).svg"),
        },
        {
          number: "2",
          title: "Закрепление материала",
          description:
            "Ты сможешь выполнить домашнее задание на тему проведенного урока.",
          image: require("@/assets/HowLessonGoessvg/howlessongoes(2).svg"),
        },
        {
          number: "3",
          title: "Обратная связь",
          description:
            "Менторы проверят твою работу и оставят комментарии, которые помогут тебе лучше разобраться в теме.",
          image: require("@/assets/HowLessonGoessvg/howlessongoes(3).svg"),
        },
        {
          number: "4",
          title: "Исправление ошибок",
          description:
            "Тебе лишь необходимо доработать свой результат с учетом комментариев менторов и снова показать им.",
          image: require("@/assets/HowLessonGoessvg/howlessongoes(4).svg"),
        },
        {
          number: "5",
          title: "Ты молодец!",
          description:
            "Поздравляем! Ты выполнил свою первую домашку! Следующее занятие состоится через неделю!",
          image: require("@/assets/HowLessonGoessvg/howlessongoes(5).svg"),
        },
      ],
      progress: 0,
      intervalId: null,
      currentPage: 0,
      isMobile: false,
    };
  },
  computed: {
    paginationCount() {
      return Math.ceil(this.slides.length / (this.isMobile ? 1 : 2));
    },
  },
  methods: {
    startProgress() {
      this.stopProgress();
      this.progress = 0;

      this.intervalId = setInterval(() => {
        this.progress += 100 / 30;
        if (this.progress >= 100) {
          this.progress = 0;
          const nextPage = (this.currentPage + 1) % this.paginationCount;
          this.goToPage(nextPage);
        }
      }, 100);
    },
    stopProgress() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    goToPage(index) {
      this.$refs.carousel.goToPage(index);
      this.currentPage = index;
      this.startProgress();
    },
    onSlideChange(newPage) {
      this.currentPage = newPage;
      this.startProgress();
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  mounted() {
    this.startProgress();
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },
  beforeDestroy() {
    this.stopProgress();
    window.removeEventListener("resize", this.checkMobile);
  },
};
</script>

<style lang="scss" scoped>
.slider-container {
  padding-top: 200px;

  .slider-title {
    font-size: 80px;
    font-weight: 700;
    line-height: 88px;
    padding-bottom: 64px;
  }

  .slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 32px;
    padding: 32px;
    max-width: 838px;
    min-height: 567px;
    margin-right: 16px;

    .upper-info-slide {
      display: flex;
      align-items: center;
      gap: 16px;

      .slide-number {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: rgba(224, 58, 123, 1);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        font-size: 28px;
        font-weight: 600;
        line-height: 36.4px;
      }

      .slide-title {
        font-size: 40px;
        line-height: 52px;
        font-weight: 600;
        margin: 0;
        text-align: left;
      }
    }

    .slide-description {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      color: rgba(100, 100, 100, 1);
      margin: 16px 0 32px 0;
      text-align: left;

      height: 108px;
      overflow: hidden;
    }

    .slide-image {
      width: 100%;
      height: auto;
      max-height: 359px;
      border-radius: 16px;
    }

    &.special-slide {
      display: flex;
      flex-direction: column;

      .special-image-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .special-image {
          width: auto;
          max-height: 351px;
        }
      }
    }
  }

  .custom-pagination {
    display: flex;
    margin-top: 64px;
    gap: 32px;

    .custom-indicator {
      display: flex;
      align-items: center;

      font-size: 28px;
      font-weight: 600;
      line-height: 36.4px;

      color: rgba(154, 154, 154, 1);
      cursor: pointer;

      &.active {
        color: rgba(224, 58, 123, 1);
      }

      .progress-bar {
        width: 80px;
        height: 4px;
        background-color: rgba(252, 201, 221, 1);
        margin-top: 4px;
        border-radius: 4px;
        overflow: hidden;
        margin-left: 10px;

        .progress-line {
          height: 100%;
          background-color: rgba(224, 58, 123, 1);
          transition: width 0.1s linear;
        }
      }
    }
  }
}







@media (max-width: 480px) {

.slider-container{
  padding-top: 80px;
}

  
.slide-box{
  min-height: 350px !important;
}



.slide{
min-height: 350px !important;
border-radius: 24px !important;
padding: 24px !important;
margin-right: 0 !important;
}


.slider-title{
  font-size: 32px !important;
  line-height: 41px !important;
  margin: 0;
  padding-bottom: 32px !important;
}


.upper-info-slide{
  gap: 8px !important;
}


.slide-number{
  font-size: 16px !important;
  width: 32px !important;
  height: 32px !important;
  
}

.slide-title{
  font-size: 24px !important;
  line-height: 31px !important;
}


.slide-description{
  font-size: 14px !important;
  line-height: 21px !important;
  margin-bottom: 16px !important;
}

.slide-image{
  border-radius: 12px !important;
  width: 100% !important;
}
 
.special-image{
  width: 100% !important;
  border-radius: 12px !important;
  max-height: 163px !important;
}





.carousel-slide{
  padding: 12px !important;
}

.custom-pagination{
  gap: 8px !important;
  justify-content: start !important;
  margin-top: 32px !important;
}

.custom-indicator{
  font-size: 18px !important;
  height: 100% !important;
}

}


</style>
