<template>
  <div>
    <SlideBlogs />
    <ArticlesOverviewBlogs />
    <ProfessionCard />
  </div>
</template>

<script>
import ArticlesOverviewBlogs from "../components/BlogAsap/ArticlesOverview/ArticlesOverviewBlogs.vue";
import SlideBlogs from "../components/BlogAsap/SlideBlogs/SlideBlogs.vue";
import ProfessionCard from "@/components/ProfessionCard/ProfessionCard.vue";

export default {
  name: "BlogAsap",
  components: {
    ArticlesOverviewBlogs,
    SlideBlogs,
    ProfessionCard,
  },
  data() {
    return {
      pageTitle: "Asap Blog - Блоги и Статьи",
      pageDescription: "Этот блог посвящен последним статьям и новостям из мира IT.",
      pageKeywords: "Vue.js, SEO, vue-meta, Blog, Articles, Frontend Development",
      ogTitle: "Блог Asap - Интересные статьи о разработке",
      ogDescription: "Читай самые интересные и полезные статьи о фронтенд-разработке в нашем блоге.",
      canonicalUrl: "https://example.com/blog",
    };
  },
  metaInfo() {
    return {
      title: this.pageTitle, // Динамический заголовок страницы
      meta: [
        {
          name: "description",
          content: this.pageDescription, // Динамическое описание
        },
        {
          name: "keywords",
          content: this.pageKeywords, // Динамические ключевые слова
        },
        {
          property: "og:title",
          content: this.ogTitle, // Динамический OG тайтл
        },
        {
          property: "og:description",
          content: this.ogDescription, // Динамическое описание для OG
        },
        {
          property: "og:type",
          content: "website", // Указание типа контента
        },
      ],
      link: [
        {
          rel: "canonical",
          href: this.canonicalUrl, // Динамический канонический URL
        },
      ],
    };
  },
};
</script>