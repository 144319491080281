<template>
  <section class="designer-section">
    <div class="designer-container">
      <div class="content">
        <!-- Заголовок -->
        <h1>UX/UI дизайнер</h1>
        <!-- Описание -->
        <p>
          Научишься проектировать удобные интерфейсы с этапа исследования до передачи макетов разработчику.<br />
          Узнаешь про принципы создания мобильных приложений, сайтов и других платформ.
        </p>
        <!-- Кнопки -->
        <div class="buttons">
          <button class="btn primary">Оставить заявку</button>
          <button class="btn secondary">К программе</button>
        </div>
      </div>
      <div class="animation">
        <img src="@/assets/png/Img.png"/>
      </div>
    </div>

    <!-- Нижние блоки -->
    <div class="info-blocks">
      <div class="info-block">
        <div class="info-content">
          <h3>Срок обучения</h3>
          <p>5 месяцев</p>
        </div>
        <div class="info-image-1"></div>
        <div class="info-image-1-mobil"></div>
      </div>
      <div class="info-block">
        <div class="info-content">
          <h3>Стоимость</h3>
          <div>
            <p>от 5 200 ₽/мес</p>
            <p class="secondary-text">Рассрочка от 3 до 36 мес.</p>
          </div>
        </div>
        <div class="info-image-2"></div>
        <div class="info-image-2-mobil"></div>
      </div>
      <div class="info-block">
        <div class="info-content">
          <h3>Старт обучения</h3>
          <div>
            <p>Февраль 2025</p>
            <p class="secondary-text">Набор 6 человек</p>
          </div>
        </div>
        <div class="info-image-3"></div>
        <div class="info-image-3-mobil"></div>
       
      </div> <div class="buttons-mobil">
          <button class="btn primary">Оставить заявку</button>
          <button class="btn secondary">К программе</button>
        </div>
    </div>
  </section>
</template>

<script>

export default {

};
</script>

<style scoped>
/* Уникальные стили компонента */
.designer-section{
  padding-top: 140px;
}


.designer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 40px;
  
}

/* Заголовок "UX/UI дизайнер" */
.content h1 {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 80px;
  font-weight: 700;
  line-height: 88px;
  text-align: left;
  color: rgba(24, 8, 14, 1);
  margin: 0;
  padding-bottom: 32px;
}

.content {
  flex: 1;
  padding-top: 75px;
  max-width: 750px;
}
/* Текст под заголовком */
.content p {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: rgba(24, 8, 14, 1);
  margin: 0;
padding-bottom: 64px;
}

/* Кнопки */
.buttons {
  display: flex;
  gap: 16px;
}


.buttons-mobil {
  display: flex;
  gap: 16px;
  display: none;
}

.btn {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  width: 70%;
  padding: 20px 30px;
  border-radius: 16px; /* Округление углов */
  cursor: pointer;
  transition: all 0.3s ease;
}








/* Нижние блоки */
.info-blocks {
  
  min-height: 200px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  
}

.info-block {
  
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 32px;
  position: relative;

}

.info-content {
  
  z-index: 2;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-block h3 {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(24, 8, 14, 1);
  text-transform: uppercase;
}

.info-block p {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  color: rgba(24, 8, 14, 1);
}

.info-blocks p.secondary-text {
  color: rgba(106, 106, 106, 1); /* Цвет для уточняющего текста */
}

.info-image-1 {
  background-image: url(@/assets/png/Time.png);
  width: 210px;
  height: calc(100% + 70px);
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  margin-top: 50px;
  margin-right: -10px;
 
}
.info-image-1-mobil{
  display: none;
}

.info-image-2 {
  background-image: url(@/assets/png/Money.png);
  width: 200px;
  height: calc(100% + 70px);
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  margin-top: 46px;
}
.info-image-2-mobil{
  display: none;
}

.info-image-3 {
  background-image: url(@/assets/png/Fire.png);
  width: 200px;
  height: calc(100% + 70px);
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  margin-top: 54px;
}
.info-image-3-mobil{
  display: none;
}

.animation {
  display: flex;
  justify-content: center;
 flex: 1;
  width: 100%;
  height: 100%;
}

.animation img {
  width: 100%;
  height: 100%;
}

@media (max-width: 480px) {
.designer-section{
  padding-top: 100px;
}
.designer-container{
  flex-direction: column;
  padding-bottom: 16px;
  gap: 0;
}
.content{
  padding-top: 0;
}

.content h1{
font-size: 32px;
line-height: 42px;
}

.content p{
font-size: 14px;
line-height: 21px;
}


.info-blocks{
  flex-direction: column;
  gap: 16px;
}

.info-block{
  height: 150px;
  padding: 16px;
  justify-content: space-between;
}

.info-block h3{
font-size: 14px;
line-height: 23px;
}
.secondary-text{
  padding-top: 4px;
  font-size: 12px;
line-height: 23px;
}



.info-block p{
font-size: 14px;
line-height: 21px;

}


.info-content{
  height: 118px;
}



.info-image-1{
display: none;
}

.info-image-1-mobil{
  
   /* Уменьшенный размер для мобильных устройств */
    background-size: contain; /* Убедитесь, что картинка пропорционально уменьшается */
    width: 155px;
       height: 160px;
        position: absolute;
    background-image: url(../../../assets/png/Time-mobil.svg);
    background-repeat: no-repeat;
        display: block;
         right: 0;
         top: 0;
}

.info-image-2{
 display: none;
}

.info-image-2-mobil{
  
      background-size: contain; /* Убедитесь, что картинка пропорционально уменьшается */
    position: absolute;
    width: 155px;
       height: 160px;
    background-image: url(../../../assets/png/Money-mobil.svg);
    background-repeat: no-repeat;
        display: block;
         right: 0;
    bottom: 0;
    margin-right: -1px;
}

.info-image-3{
display: none;
}

.info-image-3-mobil{
    width: 155px;
       height: 160px;
    background-size: contain;
        position: absolute;
        right: 0;
        top: 0;
    display: block;
    background-image: url(../../../assets/png/Fire-mobil.svg);
    background-repeat: no-repeat;
}


.buttons{
  flex-direction: column;
  display: none;
}

.btn{
  width: 100%;
  font-size: 14px;
  line-height: 32px;
  padding: 8.5px;
}

.buttons-mobil{
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}



.animation{
    width: 343px;
  height: 100%;
}
  
}


</style>