<template>
  <div :class="['info-block', borderClass]">
    <p>Рассрочка/кредит до 36 месяцев</p>
  </div>
</template>

<script>
export default {
  name: 'InfoBlock',
  props: {
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    borderClass() {

      return this.color === 'pink' ? 'pink-border' : 'blue-border';
    },
  },
};
</script>

<style scoped>
.info-block {
  width: 250px;
  position: absolute;
  top: 0%;
  left: 150%;
  background-color: rgba(240, 244, 249, 1);
  color: rgba(24, 8, 14, 1);
  padding: 10px;
  z-index: 10;
  border-radius: 16px;
}

.info-block p {
  font-weight: 400;
  font-size: 20px;
  margin: 0;
}

.pink-border {
  border: 2px solid rgb(224, 58, 123);
}

.blue-border {
  border: 2px solid rgb(40, 49, 239);
}


@media (max-width: 480px) {
  .info-block{
      width: 170px;
      padding: 16px 24px;
  }
  .info-block p {
  font-size: 12px;

}


}

</style>