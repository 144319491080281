<template>
  <div class="RealProject-container">
    <!-- Левая часть -->
    <div class="RealProject-left-section">
      <!-- Первый блок с текстами -->
      <div class="RealProject-text-block-1">
        <p class="RealProject-text-1">Стань частью команды на <span class="RealProject-span">реальном</span> IT проекте</p>
        <div class="RealProject-text__2">
            <p class="RealProject-text-2">Научишься проектировать удобные интерфейсы с этапа исследования до передачи макетов разработчику. Узнаешь про принципы создания мобильных приложения, сайтов и других платформ</p>
            <div class="RealProject-btn">
                <button class="btn primary">Кто ты в IT<img class="btn-icon" src="@/assets/RealProject/Right_Icon.svg"/></button>
            <button class="btn secondary">Попробуй бесплатно<img src="@/assets/RealProject/Right_Icon2.svg"/></button>
        </div>
            
        </div>
      </div>

      <!-- Второй блок с текстами -->
      <div class="RealProject-text-block-2">
        <div class="RealProject-stats">
          <div class="RealProject-stat">
            <p class="RealProject-stat-number">16K+</p>
            <p class="RealProject-stat-label">Вакансий на рынке</p>
          </div>
          <div class="RealProject-stat">
            <p class="RealProject-stat-number">70K+</p>
            <p class="RealProject-stat-label">Заказов на фрилансе</p>
          </div>
          <div class="RealProject-stat">
            <p class="RealProject-stat-number">500K+</p>
            <p class="RealProject-stat-label">Дефицит IT-кадров</p>
          </div>
        </div>
      </div>
    </div>
    <div class="RealProject-image-container">
      <img src="@/assets/RealProject/Img.png" alt="Картинка" />
                  <div class="RealProject-btn-mobil">
                <button class="btn primary">Кто ты в IT<img class="btn-icon" src="@/assets/RealProject/Right_Icon.svg"/></button>
            <button class="btn secondary">Попробуй бесплатно<img class="btn-icon" src="@/assets/RealProject/Right_Icon2.svg"/></button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RealProject"
};
</script>

<style scoped>
.RealProject-container {
  padding-top: 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

/* Левая часть */
.RealProject-left-section {
  display: flex;
  flex-direction: column;
  gap: 112px;
  z-index: 2;
  position: relative; /* Установим relative для левой части */
}

.RealProject-text-block-1 {
  display: flex;
  flex-direction: column;
}

.RealProject-text-1 {
  max-width: 1025px;
  font-size: 80px;
  font-weight: 700;
  position: relative;
  margin: 0;
}

.RealProject-span {
  background-color: rgba(224, 58, 123, 1);
  padding: 0 52px;
  color: rgba(255, 255, 255, 1);
  border-radius: 100px;
  align-items: center;
  text-align: center;
  
}

.RealProject-text__2 {
    max-width: 750px;
}

.RealProject-text-2 {
    padding: 32px 0;
    font-weight: 400;
  font-size: 24px;
  margin: 0;
}

.RealProject-btn{
    display: flex;
    gap: 16px;
}

.RealProject-btn-mobil{
  display: none;
}

.btn{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    gap: 8px;
    width: 388px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 20px 30px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-icon{
    

}

.RealProject-text-block-2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.RealProject-text-block-2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 750px;
}

.RealProject-stats {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  border-top: 2px solid rgba(228, 228, 228, 1);
}

.RealProject-stat {
  text-align: center;

  gap: 8px;
  max-width: 175px;
}

.RealProject-stat-number {
  font-size: 40px;
  font-weight: 600;
  margin: 0;
}

.RealProject-stat-label {
  font-size: 20px;
  font-weight: 400;
  color: rgba(154, 154, 154, 1);
  margin: 0;
}

/* Правая часть */
.RealProject-image-container {
  position: absolute;
  bottom: 0; /* Ставим картинку внизу */
  right: 0;
  width: 975px;
  height: 670px;
  z-index: 1;
}

.RealProject-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}




@media (max-width: 480px) {

.RealProject-container{
  flex-direction: column;
  padding-top: 80px;
}

.RealProject-left-section{
  gap: 0;
}

.RealProject-text-1{
  font-size: 32px;
  line-height: 41px;
}

.RealProject-span{
  padding: 0 16px;
}

.RealProject-text-2{
  font-size: 14px;
  line-height: 21px;
  padding: 32px 0;
}

.RealProject-stats{
  padding-top: 16px;
}

.RealProject-stat-number{
  font-size: 24px;
}

.RealProject-stat-label{
  font-size: 12px;
}

.RealProject-image-container{
  position: relative;
  width: 100%;
  height: auto;
  padding: 32px 0;
}

.RealProject-image-container img{
}

.RealProject-btn{
    display: none;
    gap: 16px;
}

.RealProject-btn-mobil{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.btn {
  width: 100%;
  font-size: 14px;
  padding: 8.5px 0;
  gap: 16px;
}

.btn-icon {
  width: 32px !important;
  height: 32px !important;
  
}

}

</style>
