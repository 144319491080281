<template>
    <div class="ourarticles-section">
      <h2>Наши статьи</h2>
      <div class="ourarticles-container">
        <!-- Список статей -->
        <div class="articles-wrapper">
          <ArticlesList :articles="articles" :currentProfession="currentProfession" />
        </div>
  
        <!-- Липкий MailingList -->
        <div class="mailing-wrapper">
          <MailingList
          text="Подписывайтесь на наш телеграм-канал “Как войти в IT”, где появляются свежие новости и интересные посты"
            :buttonStyles="{ backgroundColor: 'rgba(40, 49, 239, 1)', width: '91%', borderRadius: '16px' }"
            :buttonTextStyles="{ color: 'rgba(255, 255, 255, 1)', fontSize: '20px' }"
            :containerMargin="'0'"
            :containerPadding="'0'"
            telegramLink="https://t.me/yourCustomLink"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ArticlesList from "@/components/ProfessionsAsap/ArticlesSection/ArticlesList.vue";
  import MailingList from "@/components/ArticleAsap/CoverBlock/MailingList.vue";
  
  export default {
    name: "OurArticles",
    components: { ArticlesList, MailingList },
    data() {
      return {
        articles: [
          {
            id: 1,
            title: "Чем отличается графический дизайн и UX/UI дизайн",
            date: "02.03.2025",
            description: "Использование компонентов в Figma выведет ваш рабочий процесс на новый уровень.",
            image: require('@/assets/ArticlesSectionPng/prof1.png'),
            profession: "designer",
          },
          {
            id: 2,
            title: "Мыслить креативами — талант ли это?",
            date: "02.03.2025",
            description: "Обмен опытом в комьюнити.",
            image: require('@/assets/ArticlesSectionPng/prof2.png'),
            profession: "designer",
          },
          {
            id: 3,
            title: "Мыслить креативами — талант ли это?",
            date: "02.03.2025",
            description: "Рассказиваем, как сделать прототип интерфейса.",
            image: require('@/assets/ArticlesSectionPng/prof2.png'),
            profession: "designer",
          },
        ],
        currentProfession: "designer",
      };
    },
  };
  </script>
  
  <style scoped>
  .ourarticles-section {
    padding-top: 200px;
  }
  
  .ourarticles-section h2 {
    font-weight: 700;
    font-size: 80px;
    margin: 0;
    padding-bottom: 64px;
  }
  
  .ourarticles-container {
    display: flex; /* Рядом друг с другом */
    flex-wrap: nowrap;
    gap: 16px;
    justify-content: space-between;
  }
  
  .articles-wrapper {
    flex: 3; /* Занимает больше места */
  }
  
  .mailing-wrapper {
    flex: 1; /* Занимает меньше места */
    position: sticky; /* Липкое позиционирование */
    top: 20px; /* Расстояние от верха экрана */
    height: fit-content; /* Устанавливает высоту под контент */
  }


  @media (max-width: 480px) {

.ourarticles-section{
  padding-top: 80px;
}

.ourarticles-section h2{
  font-size: 32px;
  line-height: 41px;
  padding-bottom: 32px;

}

.ourarticles-container{
  flex-direction: column;
}






  }

  </style>
  