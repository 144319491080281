<template>
    <div class="new-article-section">
      <h2>Это интересно</h2>
      <div class="articles-container">
        <!-- Переиспользуем ArticleCard -->
        <ArticleCard
          v-for="article in articles"
          :key="article.id"
          :article="article"
          :isVertical="true"
          :imageWidth="'532px'"
          :imageHeight="'337px'"
          :buttonPaddingTop="'20px'"
          :buttonWidth="'100%'"
        >
          <!-- Переопределяем заголовок через slot -->
          <template #title="{ title }">
            <h3 class="article-title">{{ title }}</h3>
          </template>
        </ArticleCard>
      </div>
    </div>
  </template>
  
  <script>
  import ArticleCard from "@/components/ProfessionsAsap/ArticlesSection/ArticleCard.vue";
  
  export default {
    name: "NewArticleSection",
    components: {
      ArticleCard,
    },
    data() {
      return {
        articles: [
          {
            id: 1,
            title: "Чем отличается графический дизайн и UX/UI дизайн",
            date: "02.03.2025",
            description:
              "Использование компонентов в Figma выведет ваш рабочий процесс на новый уровень. В этой статье я объясню, что такое компоненты, как они...",
            image: require("@/assets/ArticlesSectionPng/prof1.png"),
            profession: "designer",
          },
          {
            id: 2,
            title: "Мыслить креативами — талант ли это?",
            date: "02.03.2025",
            description: "Обмен опытом в комьюнити",
            image: require("@/assets/ArticlesSectionPng/prof2.png"),
            profession: "designer",
          },
          {
            id: 3,
            title: "Мыслить креативами — талант ли это?",
            date: "02.03.2025",
            description: "Рассказиваем, как сделать прототип интерфейса ",
            image: require("@/assets/ArticlesSectionPng/prof2.png"),
            profession: "designer",
          },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .new-article-section {
    margin-top: 200px;
  }
  
  h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 52px;
  }
  
  .articles-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .article-card {
    flex: 1 1 calc(29.333% - 16px);
    max-width: calc(31.333% - 16px);
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  </style>
  