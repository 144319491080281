<template>
        <div class="articles-blog">

          <div class="blog-titl">Почитать больше интересных статей можно у нас в блоге</div>
            
            
            <img class="articles-bgtop" src="@/assets/ArticlesSectionPng/grap1.png" alt="grap1"/>
            <img class="articles-bgbottom" src="@/assets/ArticlesSectionPng/grap2.png" alt="grap2"/>
            <div class="btnpos">
              <router-link to="/blog">
                <button class="btn primary">Перейти в Блог</button>
              </router-link>
              
            </div>
          
            
        </div>
</template>

<script>
export default {
  name: "ArticleBlog",
};
</script>


<style scoped>
.articles-blog {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  background-color: rgba(255, 255, 255, 1);
  border-radius: 32px;
  padding: 32px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 386px;
  max-height: 335px;
  position: sticky;
  top: 0;
}


.articles-conteiner {
  width: 100%;
  height: 100%;
  position: relative;

}

.blog-titl{
    max-width: 290px;
    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    z-index: 2;
}


.btnpos{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.btn{
 font-size: 20px;
  font-weight: 600;
  text-align: center;
  width: 270px;
  padding: 20px 30px;
  border-radius: 16px; /* Округление углов */
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
}

.articles-bgtop{
    width: 170px;
    height: 260px;
   background-repeat: no-repeat;
   position: absolute ;
   bottom: 0;
   left: 0;
   z-index: 1;
   overflow: hidden;
}


.articles-bgbottom{

    width: 125px;
    height: 79px;
    background-repeat: no-repeat;
    position: absolute ;
    top: 0;
    right: 0;
    
}


@media (max-width: 480px) {
  .articles-blog{
    padding: 24px;
    min-height: 210px;
    overflow: hidden;
    border-radius: 24px;
    max-width: 100%;
  }
.articles-bgbottom{
  display: none;
}

.articles-bgtop{
  margin-bottom: -65px;
  opacity: 0.5;
  
}

.blog-titl{
  font-size: 20px;
  line-height: 20px;
  max-width: 100%;
}

.btnpos{
  width: 100%;
}

.btnpos a{
  width: 100%;
}

.btn{
 font-size: 14px;
 line-height: 32px;
 padding: 12px;
 width: 100%;
}
  
}

</style>