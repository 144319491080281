<template>
  <div class="market-demand">
    <!-- Первый ряд -->
     <h1 class="market-demand__title">Востребованность на рынке</h1>
    <div class="market-demand__row">
      <!-- Первая часть -->
      <div class="market-demand__col">
        <div class="market-demand__text">
          <p class="market-demand__amount">₽200 тыс.</p>
          <p class="market-demand__description">медианная зарплата IT-специалиста в Москве</p>  
        </div>
        <div class="market-demand__img"><div class="demand-img"></div></div>
      </div>
      <!-- Вторая часть -->
      <div class="market-demand__col">
        <div class="market-demand__text">
          <p class="market-demand__amount">₽151 тыс.</p>
          <p class="market-demand__description">медианная зарплата IT-специалиста в России</p>

        </div>
        <div class="market-demand__img"><div class="demand-img2"></div></div>
      </div>
    </div>

    <!-- Второй ряд -->
    <div class="market-demand__row">
      <!-- Первая часть -->
      <div class="market-demand__col1">
        <p class="market-demand__percent">16%</p>
        <p class="market-demand__growth-text">рост числа IT-вакансий 
          <span class="market-demand__highlighted-text">*в III квартале 2024 года по данным HH.ru</span>
        </p>
        <div class="market-demand__img1"><div class="demand-img3"></div></div>
      </div>
      <!-- Вторая часть -->
      <div class="market-demand__col1">
        <p class="market-demand__percent">25%</p>
        <p class="market-demand__growth-text">рост зарплат в IT за I полугодие 2024</p>
        <div class="market-demand__img2"><div class="demand-img4"></div></div>
      </div>
      <!-- Третья часть -->
      <div class="market-demand__col1 market-demand__center">
        <p class="market-demand__career-text">Узнай какая из востребованных IT профессий тебе подходит </p>
        <button class="btn primary">Пройти тест</button>
        <div class="market-demand__img3"><div class="demand-img5"></div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarketDemand',


};
</script>

<style scoped>
.market-demand {
    padding-top: 200px;
    display: flex;
    flex-direction: column;
  gap: 16px;
}

.market-demand__title{
  font-size: 80px;
  line-height: 88px;
  font-weight: 700;
  padding-bottom: 48px;
  margin: 0;
}

.market-demand__row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.market-demand__col {
  position: relative; 
  flex: 1;
  max-width: 900px;
  height: 560px;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  display: flex; 
  flex-direction: column; 
  border-radius: 32px;
  overflow: hidden;
  display: flex; /* Используем Flexbox */
  flex-direction: column; /* Располагаем элементы вертикально */
  justify-content: center; /* Центруем содержимое по вертикали */
  align-items: center;

}

.market-demand__col1 {
      position: relative; 
  flex: 1;
  height: 370px;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  display: flex; 
  flex-direction: column; 
  border-radius: 32px;
  overflow: hidden;
  display: flex; /* Используем Flexbox */
  flex-direction: column; /* Располагаем элементы вертикально */
  justify-content: center; /* Центруем содержимое по вертикали */
  align-items: center;

}


.market-demand__text {
    display: flex;
    flex-direction: column;
  text-align: left;
  position: relative;
  box-sizing: border-box; /* Учитываем padding в размерах элемента */
  overflow: hidden;
      width: 100%;
    height: 100%;
    gap: 32px;
    padding: 56px;
    z-index: 2;
}


.market-demand__img {
  right: 0;
  bottom: 0;
  width: 640px;
  height:100%; 
  overflow: hidden; 
  position: absolute; 
}

.market-demand__img1 {
  
  top: 0;
  width: 596px;
  height: 163px; 
  overflow: hidden; 
  position: absolute; 
}

.market-demand__img2 {
  
  
  top: 0;
  width: 596px;
  height: 110px; 
  overflow: hidden; 
  position: absolute; 
}

.market-demand__img3 {
  
  bottom: 0;
  width: 596px;
  height: 310px; 
  overflow: hidden; 
  position: absolute; 
}

.demand-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Масштабируем изображение, заполняя весь контейнер */
  background-image: url(@/assets/MarketDemand/Img.png);
  background-size: cover; /* Масштабируем фоновое изображение */
  background-position: center; /* Центруем изображение */
  z-index: 1;
}

.demand-img2 {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Масштабируем изображение, заполняя весь контейнер */
  background-image: url(@/assets/MarketDemand/Img2.png);
  background-size: cover; /* Масштабируем фоновое изображение */
  background-position: center; /* Центруем изображение */
  z-index: 1;
}

.demand-img3 {
      width: 100%;
  height: 100%;
  object-fit: contain; /* Масштабируем изображение, заполняя весь контейнер */
  background-image: url(@/assets/MarketDemand/Graphics1.png);
   background-repeat: no-repeat;
  background-size: contain;
  background-position: center; /* Центруем изображение */
  z-index: 1;
}

.demand-img4 {
      width: 100%;
  height: 100%;
  object-fit: contain; /* Масштабируем изображение, заполняя весь контейнер */
  background-image: url(@/assets/MarketDemand/Graphics2.png);
    background-repeat: no-repeat;
     background-size: contain;
  background-position: center; /* Центруем изображение */
  z-index: 1;
}


.demand-img5 {
      max-width: 100%;
  height: 100%;
  object-fit: contain; /* Масштабируем изображение, заполняя весь контейнер */
  background-image: url(@/assets/MarketDemand/Graphics3.png);
   background-repeat: no-repeat;
    background-size: contain;
  background-position: center; /* Центруем изображение */
  z-index: 1;
}



.market-demand__amount {
  color: rgba(224, 58, 123, 1);
  font-weight: 700;
  font-size: 80px;
  margin: 0;
  z-index: 2;
}

.market-demand__description {
    line-height: 36.4px;
    max-width: 375px;
    color: rgba(106, 106, 106, 1);
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  z-index: 2;
}

.market-demand__percent {
    color: rgba(224, 58, 123, 1);
  font-size: 80px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  z-index: 2;
}

.market-demand__growth-text {
    max-width: 330px;
    font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: rgba(106, 106, 106, 1);
  margin: 0;
  z-index: 2;
}

.market-demand__highlighted-text {
  color: rgba(245, 162, 194, 1);
}

.market-demand__center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.market-demand__career-text {
    font-weight: 600;
  font-size: 28px;
  line-height: 36.4px;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  margin: 0;
  max-width: 372px;
  padding-bottom: 32px;
  z-index: 2;
}

.btn{
      width: 70%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 20px 30px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 2;
}


@media (max-width: 480px) {

.market-demand{
  padding-top: 80px;
}

.market-demand__title{
  font-size: 32px;
  line-height: 41px;
  padding-bottom: 16px;
}

.market-demand__row{
  flex-direction: column;
}

.market-demand__col{
  min-height: 235px;
}

.market-demand__col1{
  min-height: 235px;
}

.market-demand__text{
  padding: 24px;
  gap: 16px;
  
}

.market-demand__amount{
  font-size: 32px;
  line-height: 41px;
}

.market-demand__description{
    font-size: 16px;
  line-height: 21px;
  max-width: 160px;
}


.market-demand__img{
  width: 245px;
}

.market-demand__img1{
  width: 100%;
  object-fit: contain;  
}
.demand-img3{
  object-fit: contain;
  background-size: contain;
  margin-top: -30px;
}



.market-demand__percent{
    font-size: 32px;
  line-height: 41px;
}

.market-demand__growth-text{
    font-size: 14px;
  line-height: 21px;
  padding: 0 75px;
}




.market-demand__career-text{
  font-size: 18px;
  line-height: 23px;
  padding: 0 58px;
  padding-bottom: 16px;
}


.btn{
  font-size: 14px;
}

















}

</style>
