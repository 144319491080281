<template>
    <div class="cookie-banner" v-if="!cookiesAccepted">
        <p>
            Чтобы сайт хорошо работал, мы используем файлы cookies и сервисы аналитики. Оставаясь на странице, вы соглашаетесь c
            <span>Порядком обработки файлов cookie (Политика конфиденциальности, раздел 7).</span>
        </p>
        <button @click="acceptCookies"> Хорошо </button>
    </div>
</template>

<script>
export default{
    data(){
        return{
            cookiesAccepted: false,
        };
    },
    methods: {
        acceptCookies() {
            this.cookiesAccepted = true;
            localStorage.setItem('cookiesAccepted','true');
        },
    },
    mounted(){
        this.cookiesAccepted = localStorage.getItem('cookiesAccepted') ==='true';
    },
};
</script>

<style lang="scss" scoped>
.cookie-banner {
    position: fixed; 
    bottom: 16px;    
    left: 110px;      
    z-index: 100;

    width: 43%;
    height: auto;
    padding: 32px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 3px 7px 20px 2px rgba(188, 35, 190, 0.15);
    border-radius: 32px;

    display: flex;
    flex-direction: column;

    p {
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;

        span {
            text-decoration: underline;
        }
    }

    button {
        cursor: pointer;
        border-radius: 16px;
        border: none;
        background-color: rgba(224, 58, 123, 1);
        color: white;
        padding: 20px;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;

        &:hover {
            background-color: rgba(202, 24, 93, 1);
        }
    }
}
</style>