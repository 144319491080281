<template>
  <div>
    <RealProject/>
    <CoursesMain/>
    <MarketDemand/>
    <WhyAsapSection/>
    <OurMentors/>
    <MethodsAsap/>
    <CommentsGraduates/>
    <CertificateAsap/>
    <CommunitySection/>
    <PartnersBlock/>
    <OurArticles/>
    <withFriends />
  </div>
</template>

<script>
 import CoursesMain from '@/components/HomeAsap/CoursesMain/CoursesMain.vue';
 import MarketDemand from '@/components/HomeAsap/MarketDemand/MarketDemand.vue';
 import CertificateAsap from '@/components/HomeAsap/CertificateAsap/CertificateAsap.vue';
 import RealProject from '@/components/HomeAsap/RealProject/RealProject.vue';
 import WhyAsapSection from '@/components/ProfessionsAsap/advantages/WhyAsapSection.vue';
 import OurMentors from '@/components/HomeAsap/OurMentors/OurMentors.vue';
 import MethodsAsap from '@/components/ProfessionsAsap/methods/MethodsAsap.vue';
 import CommentsGraduates from '@/components/ProfessionsAsap/CommentsGraduates/CommentsGraduates.vue';
 import CommunitySection from '@/components/ProfessionsAsap/Community/CommunitySection.vue';
 import PartnersBlock from '@/components/HomeAsap/PartnersBlock/PartnersBlock.vue';
  import OurArticles from '@/components/HomeAsap/OurArticles/OurArticles.vue';
  import withFriends from '@/components/ProfessionsAsap/withFriends/withFriends.vue';

export default {
  name: "HomeVue",
  components: {
    CoursesMain,
    MarketDemand,
    CertificateAsap,
    RealProject,
    WhyAsapSection,
     OurMentors,
    MethodsAsap,
    CommentsGraduates,
    CommunitySection,
    PartnersBlock,
     OurArticles,
     withFriends,
  },
  data() {
    return {
      pageTitle: "Home - Asap Eduacation",
      pageDescription: "Добро пожаловать на наш сайт! Узнайте больше о курсах, статьях и партнерах.",
      pageKeywords: "Vue.js, Курсы, Партнеры, Образование, Веб-разработка",
      ogTitle: "Asap Eduacation - Лучшие курсы и материалы",
      ogDescription: "Получите знания в сфере IT, веб-разработки и многого другого с помощью наших курсов.",
      canonicalUrl: "https://example.com/home",
    };
  },
  metaInfo() {
    return {
      title: this.pageTitle, // Динамический заголовок страницы
      meta: [
        {
          name: "description",
          content: this.pageDescription, // Динамическое описание
        },
        {
          name: "keywords",
          content: this.pageKeywords, // Динамические ключевые слова
        },
        {
          property: "og:title",
          content: this.ogTitle, // Динамический OG тайтл
        },
        {
          property: "og:description",
          content: this.ogDescription, // Динамическое описание для OG
        },
        {
          property: "og:type",
          content: "website", // Указание типа контента
        },
      ],
      link: [
        {
          rel: "canonical",
          href: this.canonicalUrl, // Динамический канонический URL
        },
      ],
    };
  },
};
</script>