<template>
  <div class="our-mentors-container">
    <!-- Верхняя часть с заголовком и описанием -->
    <div class="our-mentors-header">
      <h2 class="our-mentors-title">Наши менторы</h2>
      <p class="our-mentors-description">
        Менторы — твои наставники по профессии. Ведут лекции, проверяют домашние задания, погружают в особенности работы.
        Менторы ASAP'a работают в крупнейших компаниях России и Томска, поэтому знают все тонкости профессии.
      </p>
    </div>

    <!-- Слайдер с карточками менторов -->
    <carousel
      ref="carousel"
      :per-page="isMobile ? 1 : 1"
      :loop="true"
      :autoplay="false"
      :pagination-enabled="false"
      @pageChange="onSlideChange"
    >
  <slide v-for="(page, index) in paginatedMentors" :key="index">
        <div class="our-mentors-cards">
          <MentorCard
            v-for="(mentor, index) in page"
            :key="index"
            :mentor="mentor"
            :imageWidth="'100%'"
            :imageHeight="'405px'"
            :cardPadding="'24px'"
            :mentorInfoDirection="'column'"
            :cardDirection="'column'"
            :cardWidth="'390px'"
            :cardMinHeight="'700px'"
            :textAlign="'left'"
            :padding="'0'"
            :showIcon="true"
          />
        </div>
      </slide>
    </carousel>


<!-- Пагинация -->
<div class="custom-pagination">
  <span
    v-for="(page, index) in paginatedMentors"
    :key="index"
    class="custom-indicator"
    :class="{ active: currentPage === index }"
    @click="goToPage(index)"
  >
    {{ index + 1 }}
    <div v-if="currentPage === index" class="progress-bar">
      <div class="progress-line"></div>
    </div>
  </span>
</div>


  </div>
</template>

<script>
import MentorCard from "@/components/ProfessionsAsap/AcademicMentor/MentorCard.vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "OurMentors",
  components: {
    MentorCard,
    Carousel,
    Slide,
  },
  data() {
    return {
      mentors: [
        { name: "Илья Чирков", company: "Альфа-Банк", description: "Дизайнер цифровых продуктов в Альфа‑Банке. Текущая специализация — дизайн интерфейсов для банковских B2Bпродуктов. Был опыт создания лендингов курсов по аналитикеданных. Создаёт 3D-графику и анимацию", image: require("@/assets/AcademicMentorPng/ilya.png") },
        { name: "Илья Чирков", company: "Альфа-Банк", description: "Дизайнер цифровых продуктов", image: require("@/assets/AcademicMentorPng/ilya.png") },
        { name: "Илья Чирков", company: "Альфа-Банк", description: "Дизайнер цифровых продуктов", image: require("@/assets/AcademicMentorPng/ilya.png") },
        { name: "Илья Чирков", company: "Альфа-Банк", description: "Дизайнер цифровых продуктов", image: require("@/assets/AcademicMentorPng/ilya.png") },
        { name: "Илья Чирков", company: "Альфа-Банк", description: "Дизайнер цифровых продуктов", image: require("@/assets/AcademicMentorPng/ilya.png") },
        { name: "Илья Чирков", company: "Альфа-Банк", description: "Дизайнер цифровых продуктов в Альфа‑Банке. Текущая специализация — дизайн интерфейсов для банковских B2Bпродуктов. Был опыт создания лендингов курсов по аналитикеданных. Создаёт 3D-графику и анимацию", image: require("@/assets/AcademicMentorPng/ilya.png") },
        { name: "Илья Чирков", company: "Альфа-Банк", description: "Дизайнер цифровых продуктов в Альфа‑Банке. Текущая специализация — дизайн интерфейсов для банковских B2Bпродуктов. Был опыт создания лендингов курсов по аналитикеданных. Создаёт 3D-графику и анимацию", image: require("@/assets/AcademicMentorPng/ilya.png") },
        { name: "Илья Чирков", company: "Альфа-Банк", description: "Дизайнер цифровых продуктов", image: require("@/assets/AcademicMentorPng/ilya.png") },
        { name: "Илья Чирков", company: "Альфа-Банк", description: "Дизайнер цифровых продуктов", image: require("@/assets/AcademicMentorPng/ilya.png") },
        { name: "Илья Чирков", company: "Альфа-Банк", description: "Дизайнер цифровых продуктов", image: require("@/assets/AcademicMentorPng/ilya.png") },

      ],
      progress: 0,
      intervalId: null,
      currentPage: 0,
      isMobile: false,
    };
  },
  computed: {
  paginatedMentors() {
    const perPage = this.isMobile ? 1 : 4; // Элементов на странице
    const pages = [];
    
    for (let i = 0; i < this.mentors.length; i += perPage) {
      pages.push(this.mentors.slice(i, i + perPage));
    }
    return pages;
  },
  },
  methods: {
  goToPage(index) {
    this.$refs.carousel.goToPage(index);
    this.currentPage = index;
    this.startProgress();  // Это все еще для контроля прогресса, если нужно
  },
  onSlideChange(newPage) {
    this.currentPage = newPage;
    this.startProgress();  // Это все еще для контроля прогресса, если нужно
  },
},
  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
    this.startProgress();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile);
    this.stopProgress();
  },
};
</script>

<style scoped>
/* Контейнер основного компонента */
.our-mentors-container {
  padding-top: 200px;
}

/* Слайдер */
.VueCarousel-wrapper {
  padding-top: 75px;
  padding-bottom: 10px;
}

.our-mentors-cards {
  display: flex;
  gap: 16px;
  padding-bottom: 10px;
  justify-content: center;
}

/* Прогресс-бар будет показываться только для активного индикатора */
.progress-bar {
  height: 4px;
  width: 80px;
  margin-left: 10px;
  position: relative;
}

.progress-line {
  height: 100%;
  background-color: rgba(224, 58, 123, 1);
  border-radius: 4px;
  width: 100%;
}

.custom-pagination {
  padding-top: 32px;
  display: flex;
  gap: 16px;
  justify-content: left;
}

.custom-indicator {
  display: flex;
  align-items: center;
  font-size: 28px;
  line-height: 36.4px;
  cursor: pointer;
  color: rgba(154, 154, 154, 1);
}

.custom-indicator.active {
  color: rgba(224, 58, 123, 1); /* Цвет активной цифры */
}

  .our-mentors-header {
    display: flex;
    justify-content: space-between;
    gap: 32px;
  }
  .our-mentors-title {
    font-size: 80px;
    line-height: 88px;
    font-weight: 700;
    margin: 0;
    padding-bottom: 86px;
  }
  .our-mentors-description {
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    max-width: 900px;
  }



@media (max-width: 480px) {
  .our-mentors-container {
    padding-top: 80px;
  }
  .our-mentors-cards {
    flex-direction: column;
  }
  .our-mentors-header {
    flex-direction: column;
    gap: 32px;
  }
  .our-mentors-title {
    font-size: 32px;
    line-height: 21px;
  }
  .our-mentors-description {
    font-size: 14px;
    line-height: 21px;
  }
}
</style>
