<template>
  <div>
    <DesignerSection />
    <WhyAsapSection />
    <AcademicMentor />
    <FutureAsap />
    <CareerAsap />
    <StudyProgram />
    <CourseProgramSection />
    <MethodsAsap />
    <YourMentor />
    <ExampleCase />
    <HowLessonGoes />
    <EmploymentAsap />
    <CommentsGraduates />
    <CommunitySection />
    <TariffsAsap />
    <ArticlesSection />
    <ProfessionCard />
    <withFriends />
  </div>
</template>

<script>
import YourMentor from "../components/ProfessionsAsap/YourMentor/YourMentor.vue";
import HowLessonGoes from "../components/ProfessionsAsap/HowLessonGoes/HowLessonGoes.vue";
import CommentsGraduates from "../components/ProfessionsAsap/CommentsGraduates/CommentsGraduates.vue";
import withFriends from "../components/ProfessionsAsap/withFriends/withFriends.vue";
import CourseProgramSection from "../components/ProfessionsAsap/courseprogram/CourseProgramSection.vue";
import StudyProgram from "../components/ProfessionsAsap/StudyProgram/StudyProgram.vue";
import CommunitySection from "../components/ProfessionsAsap/Community/CommunitySection.vue";
import DesignerSection from "../components/ProfessionsAsap/professions/DesignerSection.vue";
import WhyAsapSection from "../components/ProfessionsAsap/advantages/WhyAsapSection.vue";
import MethodsAsap from "../components/ProfessionsAsap/methods/MethodsAsap.vue";
import AcademicMentor from "../components/ProfessionsAsap/AcademicMentor/AcademicMentor.vue";
import FutureAsap from "../components/ProfessionsAsap/future/FutureAsap.vue";
import CareerAsap from "../components/ProfessionsAsap/career/CareerAsap.vue";
import ExampleCase from "../components/ProfessionsAsap/ExampleCase/ExampleCase.vue";
import TariffsAsap from "../components/ProfessionsAsap/Tariffs/TariffsAsap.vue";
import EmploymentAsap from "../components/ProfessionsAsap/Employment/EmploymentAsap.vue";
import ArticlesSection from "../components/ProfessionsAsap/ArticlesSection/ArticlesSection.vue";
import ProfessionCard from "../components/ProfessionCard/ProfessionCard.vue";

export default {
  name: "ProfessionsAsap",
  components: {
    withFriends,
    CourseProgramSection,
    HowLessonGoes,
    YourMentor,
    CommentsGraduates,
    CommunitySection,
    StudyProgram,
    DesignerSection,
    WhyAsapSection,
    MethodsAsap,
    AcademicMentor,
    FutureAsap,
    CareerAsap,
    ExampleCase,
    TariffsAsap,
    EmploymentAsap,
    ArticlesSection,
    ProfessionCard,
  },
  data() {
    return {
      pageTitle: "Профессия дизайнер - Asap Eduacation",
      pageDescription: "Узнайте все о профессии дизайнера, программах обучения и преимуществах с Asap Eduacation.",
      pageKeywords: "Дизайнер, курсы дизайна, профессия дизайнер, обучение дизайну, Asap Eduacation",
      ogTitle: "Asap Eduacation - Профессия дизайнер",
      ogDescription: "Получите знания и навыки для профессии дизайнера с нашими образовательными программами.",
      canonicalUrl: "https://example.com/professions/designer",
    };
  },
  metaInfo() {
    return {
      title: this.pageTitle, // Динамический заголовок страницы
      meta: [
        {
          name: "description",
          content: this.pageDescription, // Динамическое описание
        },
        {
          name: "keywords",
          content: this.pageKeywords, // Динамические ключевые слова
        },
        {
          property: "og:title",
          content: this.ogTitle, // Динамический OG тайтл
        },
        {
          property: "og:description",
          content: this.ogDescription, // Динамическое описание для OG
        },
        {
          property: "og:type",
          content: "website", // Указание типа контента
        },
      ],
      link: [
        {
          rel: "canonical",
          href: this.canonicalUrl, // Динамический канонический URL
        },
      ],
    };
  },
};
</script>